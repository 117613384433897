import React, { useState, useEffect } from "react";
// components
import Layout from "../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import {
  MuiDataGrid,
  ModalCloseBtn,
  PopUpModel,
  MuiDataGridLeadership,
  MuiTooltip
} from "../../components";
import Typography from "@mui/material/Typography";
import PreviewIcon from "@mui/icons-material/Preview";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { FormSubmitButton } from "../../components";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { GridCellExpand } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, Grid, FormControl, InputLabel, FormHelperText, Select, MenuItem } from "@mui/material";
import ViewPdf from '../../components/common/pdf_view/ViewPdf';
import { BASE_URL } from "../../utils/config";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  getClientFocus,
  updateClientFocusApprove,
  resetErrMessage,
  resetMessage,
  coachingShareWithHr,
  coachingShareWithManager,
  updatePrivateCoachingFocus,
  getCoachingManagerButton,
  getCoachingHrButton,
  getClientFocusPurpose,
  getClientCoachingDocuments,
  updateCoachingFocus,
  rowSelect,
  coahcingRowFunc,
  updateCoachingMultiSelectApprove,
  updateCoachingMultiSelectPrivate,
  updateCoachingMultiSelectPublic,
} from "../../features/coaching_focus/focusSlice";
import { getToogleData } from "../../features/ratings/ratingSlice";

import HowToUse from "../../components/common/HowToUse";

import { Tooltip } from "@material-ui/core";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { ReactComponent as Eyedashboard } from "../../assets/images/Chm.svg";
import { Height } from "@mui/icons-material";

const steps = ["Na", "Matching", "Chemistry", "Preparation", "Mentoring"];

const Assesment = () => {
  const [showCoachingEdit, setShowCoachingEdit] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [isPrivateCoching, setIsPrivateCoaching] = useState(true);
  const [approveStateLoading, setApproveStateLoading] = useState(false)

  const {
    loading,
    message,
    errMessage,
    clientFocusData,
    coachingHrButtonData,
    coachingManagerButtonData,
    clientFocusDocumentsData,
    clientFocusPurposeData,
    selectedData,
    focusAreaMultiData,
    privatePublicLoading,
    approvedLoading
  } = useSelector((state) => ({ ...state.ClientFocus }));

  const {

    clientToggleData,
  } = useSelector((state) => ({ ...state.clientRating }));



  const dispatch = useDispatch();

  const validationCoachingSchema = Yup.object({
    // coachingFocus: showAdd
    //   ?
    //   toggleMentorData?.length > 0 &&
    //     toggleMentorData[0]?.subCategory ? Yup.object().required("Sub-Category is required") : Yup.object()
    //   : toggleMentorData?.length > 0 &&
    //     toggleMentorData[0]?.subCategory && !selectedData?.isClientApproved ? Yup.string().nullable().required("Sub-Category is required") : Yup.string().nullable(),


    description: !selectedData?.isClientApproved ? Yup.string().required("Expected Outcomes is required") : Yup.string(),
    devArea:
      Yup.string().required("Leadership Dimension is required"),
    // anticipatedStartDate: Yup.string().required(
    //   "Anticipated Start date is required"
    // ),
    focusAreaSecondary: Yup.string().required("Focus Area  Name is required"),

    focusAreaStatus: Yup.string().required("Focus Area  Status is required"),
  });

  const handleFocusApproveClick = (data) => {
    const body = {
      coachingFocusId: data,
    };
    dispatch(updateClientFocusApprove(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getClientFocus());
          dispatch(getCoachingManagerButton());
          dispatch(getCoachingHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const onCoachingUpdate = (e) => {
    const isTrue = isPrivateCoching === "true";
    e.preventDefault();
    dispatch(
      updatePrivateCoachingFocus({
        isPrivate: isPrivateCoching === "true" ? true : false,
        coachingFocusId: rowId,
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          closeCoachingModel();
          dispatch(getClientFocus());
          dispatch(getCoachingManagerButton());
          dispatch(getCoachingHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const dynamiLoading = () => {
    return true

  }
  const useStylesTwo = makeStyles((theme) => ({
    customTooltip: {
      backgroundColor: "white",
      color: "black",
      fontWeight: "bold",
    },
    customArrow: {},
    responsive_width: {
      [theme.breakpoints.down("md")]: {
        width: "19.875rem !important",
        overflowX: "scroll !important",
      },
    },
    reponsive_textbox: {
      [theme.breakpoints.down("md")]: {
        width: "17rem !important",
      },
    },
  }));

  const classesTwo = useStylesTwo();

  const infoItems = [
    "Public items will be shareable with your Manager and HR once you have approved and submitted them.",
    "Private items will only be visible by you and your coach and will not be shared with any other stakeholders.",
  ];

  const tip = infoItems.join("\n");


  function getValueFocus(params) {
    return (
      <div>
        <LoadingButton
          disabled={params.row.isClientApproved ? "disabled" : null}
          loadingIndicator={<CircularProgress size={20} />}
          loading={focusAreaMultiData?.length > 0 && focusAreaMultiData.some((data) => data === params.row.id) && approvedLoading}
          // loading={approvedLoading}
          type="submit"
          variant="contained"
          onClick={() => handleFocusApproveClick(params.row.id)}
        //   className={classes.btnSubmit}
        >
          {params.row.isClientApproved ? "Approved" : "Approve"}
        </LoadingButton>


        {/* <Button
          sx={{ width: "6rem" }}
          disabled={params.row.isClientApproved ? "disabled" : null}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleFocusApproveClick(params.row.id)}
        >
          {params.row.isClientApproved ? "Approved" : "Approve"}
        </Button> */}
      </div>
    );
  }

  const handleShareHrClick = () => {
    dispatch(coachingShareWithHr()).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getCoachingManagerButton());
          dispatch(getCoachingHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };

  const handleShareManagerClick = () => {
    dispatch(coachingShareWithManager()).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getCoachingManagerButton());
          dispatch(getCoachingHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getClientFocus());
    dispatch(getClientFocusPurpose());
    dispatch(getCoachingManagerButton());
    dispatch(getCoachingHrButton());
    dispatch(getClientCoachingDocuments())
  }, []);

  const openPublicModel = (data) => {
    setIsPrivateCoaching(data.isPrivate ? "true" : "false");
    setShowCoachingEdit(true);
    setRowId(data.id);
  };

  const showUpdateModel = () => {
    setShowUpdate(!showUpdate);
  };

  const closeCoachingModel = () => {
    setShowCoachingEdit(false);
  };

  const closeUpdate = () => {
    setShowUpdate(false);

  };


  function getPublicPrivateButton(params) {

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {params.row.isPrivate ? (
          <LockIcon color="primary" style={{ marginRight: 8, }} />
        ) : (
          <VisibilityIcon color="primary" style={{ marginRight: 8, }} />
        )}
        <Button
          sx={{ width: "6rem" }}
          disabled={params.row.isClientApproved ? "disabled" : null}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => openPublicModel(params.row)}
        >
          {!params.row.isPrivate ? "Public" : "Private"}
        </Button>
      </div>
    );
  }

  const handleCheckChange = (event) => {
    setIsPrivateCoaching(event.target.value);
  };

  function renderCellExpand(params) {

    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const mentorGetDevValue = (params) => {

    return params?.row?.developmentAreas
      ?.developmentAreaName;
  };

  const mentorGetCoachingValue = (params) => {
    return params?.row?.developmentAreasCoachingFocus?.
      developmentAreaCoachingFocusName
  };


  const getCreatedOnDate = (params) => {
    return params.row.createdOn
      ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
      : "";
  };

  const getUpdatedOnDate = (params) => {
    return params.row.updatedOn
      ? format(new Date(params.row.updatedOn), "MM-dd-yyyy")
      : ""

  };

  useEffect(() => {
    dispatch(getToogleData());
  }, []);


  const data = {
    rows: clientFocusData,
    columns: [
      {
        field: "developmentArea",
        headerName: "Leadership Dimension",
        width: 180,
        renderCell: renderCellExpand,
        valueGetter: mentorGetDevValue,
      },

      {
        field: "focus",
        headerName: "Sub-Category",
        width: 130,
        renderCell: renderCellExpand,
        valueGetter: mentorGetCoachingValue,
        hide: clientToggleData?.length > 0 &&
          clientToggleData[0]?.subCategory ? false : true


      },
      {
        field: "otherDevelopmentAreaCoachingFocus",
        headerName: "Focus Area",
        width: 130,
        // valueGetter: mentorGetCoachingValue,
        renderCell: renderCellExpand,
      },

      {
        field: "expectedOutcome",
        headerName: "Expected Outcome",
        width: 160,
        renderCell: renderCellExpand,
        hide: clientToggleData?.length > 0 &&
          clientToggleData[0]?.expectedOutcome ? false : true
      },
      {
        field: "anticipatedStartDate",
        headerName: "Anticipated Start Date",
        width: 200,
        renderCell: renderCellExpand,
        hide: clientToggleData?.length > 0 &&
          clientToggleData[0]?.anticipatedStartDate ? false : true
      },

      {
        field: "focusAreaStatus",

        headerName: "Active/Inactive",
        width: 130,
        renderCell: renderCellExpand,
      },
      // {
      //   field: "ui",
      //   headerName: "Private/Public",
      //   renderCell: getPublicPrivateButton,
      //   valueGetter: (params) => (params.row.isPrivate ? "Private" : "Public"),
      //   width: 130,
      // },

      {
        field: "ui",
        headerName: "Private/Public",
        fontWeight: "bold",
        width: 150,

        valueGetter: (params) => (params.row.isPrivate ? "Private" : "Public"),

        renderCell: getPublicPrivateButton,

        renderHeader: (params) => (
          <Tooltip
            classes={{
              tooltip: classesTwo.customTooltip,
              arrow: classesTwo.customArrow,
            }}
            title={<div style={{ whiteSpace: "pre-line" }}>{tip}</div>}
          >
            <div style={{ fontWeight: 500 }}>
              Private/Public
              {/* {console.log("params", params)}  */}
            </div>
          </Tooltip>
        ),
      },

      {
        field: "id",
        headerName: "Approval Status",
        renderCell: getValueFocus,
        width: 150,
        valueGetter: (params) =>
          params.row.isClientApproved ? "Approved" : "Approve",
      },
      {
        field: "date",
        headerName: "Date Added",
        width: 150,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,

        valueGetter: getUpdatedOnDate,
      },
    ],
  };

  const dataTwo = {
    rows: clientFocusPurposeData,
    columns: [
      {
        field: "purposeOfEngagment",
        headerName: "Engagement Purpose Description",
        width: 850,
        renderCell: renderCellExpand,
      },
      {
        field: "date",
        headerName: "Date Added",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
      },
    ],
  };

  const SubmitByHr = () => {
    return (
      <Button
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleShareHrClick}
        disabled={
          coachingHrButtonData?.name === "Share To HR" ? null : "disabled"
        }
      >
        {coachingHrButtonData?.name === "Share To HR"
          ? "Share With HR"
          : "Share With HR"}
      </Button>
    );
  };

  const SubmitByManager = () => {
    return (
      <Button
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleShareManagerClick}
        disabled={
          coachingManagerButtonData?.name === "Share To Manager"
            ? null
            : "disabled"
        }
      >
        {coachingManagerButtonData?.name === "Share To Manager"
          ? "Share With Manager"
          : "Share With Manager"}
      </Button>
    );
  };

  const onFocusAreaMultiSelectPrivate = (e) => {
    e.preventDefault();
    dispatch(
      updateCoachingMultiSelectPrivate({
        coachingFocusId: focusAreaMultiData,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());

          dispatch(getClientFocus());
          dispatch(getCoachingManagerButton());
          dispatch(getCoachingHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const onFocusAreaMultiSelectPublic = (e) => {
    e.preventDefault();
    dispatch(
      updateCoachingMultiSelectPublic({
        coachingFocusId: focusAreaMultiData,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getClientFocus());
          dispatch(getCoachingManagerButton());
          dispatch(getCoachingHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const onFocusAreaMultiSelectApprove = (e) => {
    e.preventDefault();
    dispatch(
      updateCoachingMultiSelectApprove({
        coachingFocusId: focusAreaMultiData,
        // detailedDesc:selectedData?.detailedDesc
      })
    ).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getClientFocus());
          dispatch(getCoachingManagerButton());
          dispatch(getCoachingHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const HowToUseContent = `Your Mentor/Coach can use the table below to summarize the primary goals and 
  purpose of the engagement based on input from your key stakeholders. Upon each contract renewal or completion 
  of a major milestone, your Mentor/Coach can return to tis table and add a new entry with the refreshed purpose of engagement.`;

  const HowToUseContent2 = `Your Mentor/Coach can use the table below to document the agreed upon focus areas for the engagement.
   When your Mentor/Coach sends you new focus areas for review, you will be able to categorize each as either "Private" or "Public"
    and approve them to be added to your profile.`;

  const HowToUseDescription = `If your organization requires regular leadership coaching impact updates,
   please ensure there are at least three "Public" focus areas in the table that you and your Mentor/Coach can
    provide updates against during the engagement and share with your Manager / HR Partner(s).`;

  const PrivateAndPublicContent = [
    { title: 'Private', description: ' Visible only to you and your client: remains confidential.' },
    { title: 'Public', description: 'Data can be shared with your Manager and HR partner(s) upon your approval.' },
  ];


  return (
    <Layout isClient={true}>

      <Box
        sx={{
          border: '1px solid #ccc', // Light gray border
          borderRadius: 2, // Rounded corners
          padding: 2, // Inner padding
          marginTop: 2, // Spacing from top
        }}
      >
        <Grid container>
          <Grid item md={9}>
            <Box >
              <Typography
                variant="h5" // Makes the text larger
                fontWeight="bold" // Makes the text bold
                gutterBottom
              >
                Action Plan (Confidential)
              </Typography>

              <Stack spacing={2}>
                <Typography
                  sx={{ fontSize: "1.1rem" }}// Adjusts the text size inside the stack
                >

                  Any document attached here will only be visible to you and your Mentor/Coach.
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={8}
            sm={8}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "1rem", // Spacing between icon groups
              marginTop: "0.4rem",
            }}
          >

            <>


              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <MuiTooltip title="View Action Plan">
                  <Eyedashboard
                    onClick={() => setOpenFile(true)}
                    style={{
                      cursor: "pointer",
                      width: "2.5rem", // Adjust width
                      height: "2.5rem", // Adjust height
                    }}
                    fontSize="12px"
                    color="primary"
                  />
                </MuiTooltip>
                <Typography variant="caption" color="textSecondary">
                  View Action Plan
                </Typography>
              </Box>
            </>

          </Grid>
        </Grid>
      </Box>

      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          fontFamily: "arial",
          paddingTop: "1rem",
        }}
      >
        <PageHeader variant="pageHeader">
          {" "}
          Purpose of Engagement
        </PageHeader>
      </Stack>

      <Box sx={{ marginBottom: '50px' }}>
        <HowToUse
          HowToUseContent={HowToUseContent}
        />
      </Box>

      <Formik
        enableReinitialize={true}
        initialValues={{
          coachingFocus: selectedData?.developmentAreasCoachingFocus?.id
          ,
          description: selectedData?.expectedOutcome,
          devArea: selectedData?.developmentAreas?.id,
          anticipatedStartDate: selectedData?.anticipatedStartDate,
          focusAreaStatus: selectedData?.focusAreaStatus,
          focusAreaSecondary: selectedData?.otherDevelopmentAreaCoachingFocus,
        }}
        validationSchema={validationCoachingSchema}
        onSubmit={(values, { resetForm }) => {
          console.log(values);
          let data = {
            id: selectedData?.id,
            focusAreaStatus: values?.focusAreaStatus,
            developmentAreasCoachingFocusId: values.coachingFocus,
            otherDevelopmentAreaCoachingFocus: values?.focusAreaSecondary,
            expectedOutcome: values.description,
            anticipatedStartDate: values?.anticipatedStartDate,
            developmentAreasId: values.devArea,
          };
          dispatch(updateCoachingFocus(data)).then((res) => {
            if (res?.payload?.data?.ok) {
              setTimeout(() => {
                dispatch(resetMessage());
                closeUpdate();
                dispatch(getClientFocus())
              }, 1000);
            }
            if (res?.payload?.error) {
              setTimeout(() => {
                dispatch(resetErrMessage());
              }, 3000);
            }
          });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          resetForm,
        }) => (
          <PopUpModel
            open={showUpdate}
            handleClose={() => {
              closeUpdate();
              resetForm({ values: "" });
            }}
            closeModel={() => {
              closeUpdate();
              resetForm({ values: "" });
            }}
          >
            <Box
              sx={{ ...style, width: 700 }}
              className={classesTwo.responsive_width}
              component="form"
              noValidate
              onSubmit={handleSubmit}
            >
              <ModalCloseBtn
                close={() => {
                  closeUpdate();
                  resetForm({ values: "" });
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  margin: "1rem"
                }}
              >
                <Grid columnSpacing={2} container>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl sx={{ width: "100%", marginTop: "12px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Leadership Dimension *
                      </InputLabel>
                      <Select
                        disabled={selectedData?.isClientApproved}
                        type="select"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="devArea"
                        value={values.devArea}
                        label=" Development Area"
                        onChange={(e) => {
                          console.log(e);
                          handleChange(e);
                          // dispatch(fetchCoachingFocusById(e.target.value));
                        }}
                      >
                        {/* {coachingdevelopmentAreaData?.length > 0 &&
                          coachingdevelopmentAreaData?.map((itemValue) => {
                            return (
                              <MenuItem
                                key={itemValue.id}
                                value={itemValue.developmentAreas?.id}
                              >
                                {
                                  itemValue.developmentAreas
                                    ?.developmentAreaName
                                }
                              </MenuItem>
                            );
                          })} */}
                      </Select>
                      {errors.devArea && (
                        <FormHelperText error={true} sx={{ color: "red" }}>
                          {touched.devArea && errors.devArea}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>


                  {/* {toggleMentorData?.length > 0 &&
                    toggleMentorData[0]?.subCategory
                    && ( */}

                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl sx={{ width: "100%", marginTop: "12px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Sub-Category*
                      </InputLabel>
                      <Select
                        disabled={selectedData?.isClientApproved}
                        type="select"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="coachingFocus"
                        value={values.coachingFocus}
                        label="Development Area"
                        onChange={handleChange}
                      >
                        {/* {fetchCoachingFocusData?.length > 0 &&
                            fetchCoachingFocusData.map((itemValue) => {
                              return (
                                <MenuItem key={itemValue.id} value={itemValue.
                                  id
                                }>
                                  {itemValue.developmentAreaCoachingFocusName}
                                </MenuItem>
                              );
                            })} */}
                      </Select>
                      {errors.coachingFocus && (
                        <FormHelperText error={true} sx={{ color: "red" }}>
                          {touched.coachingFocus && errors.coachingFocus}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {/* )} */}

                  {/* {fetchCoachingFocusData?.find(
                    (data) => data.id === values?.coachingFocus
                  )?.developmentAreaCoachingFocusName === "Others" && ( */}
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      disabled={selectedData?.isClientApproved}
                      // inputProps={{
                      //   maxlength: CHARACTER_LIMIT
                      // }}

                      sx={{ width: "100%", height: "5rem" }}
                      className={classesTwo.reponsive_textbox}
                      label="Focus Area"
                      type="text"
                      name="focusAreaSecondary"
                      required={true}
                      margin="normal"
                      variant="filled"
                      id="focusAreaSecondary"
                      value={values.focusAreaSecondary}
                      onChange={handleChange("focusAreaSecondary")}
                      error={
                        touched.focusAreaSecondary &&
                        Boolean(errors.focusAreaSecondary)
                      }
                      helperText={
                        touched.focusAreaSecondary &&
                        errors.focusAreaSecondary
                      }
                    // helperText={`${formik.values.anticipatedStartDate.length}/${CHARACTER_LIMIT}`}
                    />
                  </Grid>
                  {/* )} */}

                  {/* {toggleMentorData?.length > 0 &&
                    toggleMentorData[0]?.anticipatedStartDate
                    && ( */}

                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      disabled={selectedData?.isClientApproved}
                      // inputProps={{
                      //   maxlength: CHARACTER_LIMIT
                      // }}

                      sx={{ width: "100%", height: "5rem" }}
                      className={classesTwo.reponsive_textbox}
                      label="Anticipated Start Date"
                      type="text"
                      name="anticipatedStartDate"
                      // required={true}
                      margin="normal"
                      id="anticipatedStartDate"
                      value={values.anticipatedStartDate}
                      onChange={handleChange("anticipatedStartDate")}
                      error={
                        touched.anticipatedStartDate &&
                        Boolean(errors.anticipatedStartDate)
                      }
                      helperText={
                        touched.anticipatedStartDate &&
                        errors.anticipatedStartDate
                      }
                    // helperText={`${formik.values.anticipatedStartDate.length}/${CHARACTER_LIMIT}`}
                    />
                  </Grid>

                  {/* )} */}

                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl sx={{ width: "100%", marginTop: "12px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Focus Area Status *
                      </InputLabel>
                      <Select
                        type="select"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="focusAreaStatus"
                        value={values.focusAreaStatus}
                        label="Focus Area Status"
                        onChange={handleChange}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                      </Select>
                      {errors.focusAreaStatus && (
                        <FormHelperText error={true} sx={{ color: "red" }}>
                          {touched.focusAreaStatus && errors.focusAreaStatus}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              {/* {toggleMentorData?.length > 0 &&
                toggleMentorData[0]?.expectedOutcome
                && ( */}

              <Stack sx={{ width: "100%" }}>
                <Stack
                  sx={{
                    fontSize: "0.8rem",
                    alignSelf: "flex-end",
                  }}
                >
                  {" "}
                  {values?.description?.length
                    ? 500 - values?.description?.length
                    : 500}{" "}
                  Characters remaining
                </Stack>
                <TextField
                  sx={{
                    height:
                      values?.description?.length > 250 ? "12rem" : "7rem",
                  }}
                  disabled={selectedData?.isClientApproved}
                  multiline
                  label="Expected Outcomes"
                  className="customTextField"
                  type="text"
                  name="description"
                  required={true}
                  margin="normal"
                  id="description"
                  value={values.description}
                  onChange={handleChange("description")}
                  error={touched.description && Boolean(errors.description)}
                  helperText={
                    (touched.description && errors.description) ||
                    (values?.description?.length > 500 && (
                      <FormHelperText error={true} sx={{ color: "red" }}>
                        Description should have less than 500 characters
                      </FormHelperText>
                    ))
                  }
                />
              </Stack>

              {/* )} */}
              <LoadingButton
                loadingIndicator={<CircularProgress size={20} />}
                loading={loading}
                type="submit"
                variant="contained"
              //   className={classes.btnSubmit}
              >
                Update
              </LoadingButton>
            </Box>
          </PopUpModel>
        )}
      </Formik>

      <PopUpModel
        open={showCoachingEdit}
        handleClose={closeCoachingModel}
        closeModel={closeCoachingModel}
      >
        <Box
          sx={{ ...style, width: 700 }}
          component="form"
          noValidate
        // onSubmit={onStrengthSubmit}
        >
          <ModalCloseBtn close={closeCoachingModel} />
          {/* <div  style={{width:"100%",display:"flex",justifyContent:"center"}}> */}
          {/* <div style={{width:"70%"}}>  */}
          <Stack sx={{ width: "100%" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={isPrivateCoching}
              onChange={handleCheckChange}
            >
              <FormControlLabel
                style={{ color: "black" }}
                value={false}
                control={<Radio />}
                label="Public"
              />
              <FormControlLabel
                style={{ color: "black", marginLeft: "13.5rem" }}
                value={true}
                control={<Radio />}
                label="Private"
              />
            </RadioGroup>
          </Stack>

          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Stack>
              <Typography
                sx={{ fontSize: "0.75rem" }}
                gutterBottom
                variant="h9"
                component="div"
              >
                Public items will be shareable with your Manager and HR once you
                have approved and submitted them.
              </Typography>
            </Stack>
            <Stack sx={{ marginLeft: "1rem" }}>
              <Typography
                sx={{ fontSize: "0.75rem" }}
                gutterBottom
                variant="h9"
                component="div"
              >
                Private items will only be visible by you and your coach and
                will not be shared with any other stakeholders.
              </Typography>
            </Stack>
          </Stack>

          {/* </div> */}

          <FormSubmitButton
            type="submit"
            variant="contained"
            onClick={onCoachingUpdate}
          //   className={classes.btnSubmit}
          >
            Submit
          </FormSubmitButton>
        </Box>
      </PopUpModel>
      <div>
        <MuiDataGrid
          toolbarColumn={true}
          toolbarFilter={true}
          toolbarDensity={true}
          toolbarExport={true}
          isRowHeight={true}
          showButton={true}
          viewAdd={false}
          data={dataTwo}
        />
      </div>

      <div style={{ marginTop: "2rem" }}>
        <PageHeader variant="pageHeader"> Focus Areas</PageHeader>

        <Box sx={{ marginBottom: '50px' }}>
          <HowToUse
            HowToUseContent={HowToUseContent2}
            PrivateAndPublicContent={PrivateAndPublicContent}
            HowToUseDescription={HowToUseDescription}
          />
        </Box>

        <MuiDataGridLeadership
          onMultiPublicClick={onFocusAreaMultiSelectPublic}
          onMultiPrivateClick={onFocusAreaMultiSelectPrivate}
          onMultiApproveClick={onFocusAreaMultiSelectApprove}
          selectArr={(data) => dispatch(coahcingRowFunc(data))}
          showButton={true}
          viewAdd={false}
          data={data}
          loading={privatePublicLoading}
          // select={(data) => dispatch(rowSelect(data))}
          setShowEdit={false}
        // setShowEdit={showUpdateModel}

        />
      </div>

      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "arial",
          marginTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <SubmitByHr />
        <SubmitByManager />
      </Stack>
      {openFile && (
        <ViewPdf
          showAttachment={openFile}
          setShowAttachment={setOpenFile}
          havingViewUrl={`${BASE_URL}/v1/api/coaching-focus/static/${clientFocusDocumentsData[0]?.fileName &&
            clientFocusDocumentsData[0]?.fileName
            }`}
        // variableDownload={`${HCM_EMP_VIEW_DOWNLOAD_DOCUMENTS}/${url.id}`}
        />
        // downloadUrl={VIEW_BACKLOG_ATTACHMENTS_DOWNLOAD}
      )}
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Assesment;
