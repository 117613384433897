import React, { useEffect, useState } from "react";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";

// material ui
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Grid,
  ListItem,
  ListItemText,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
// components
import {
  MuiDataGrid,
  ModalCloseBtn,
  GridCellExpand,
  PopUpModel,
} from "../../../components";
import Layout from "../../../layout/Layout";
import { CommonGridData } from "../../../components/common/CommonGridData";

import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

import { getEnagagementScopeDetails } from "../../../features/hr/engagement/engagementSlice";
import Attachments from "../../../components/common/upload_documents/Attachments";
import { getEngagementDocuments, createEngagementDocuments } from '../../../features/dashboard/engagementDocumentSlice';

// import { getAllKeyClientEngagementForClient } from "../../features/engagement/engagementSlice";

const useStyles = makeStyles((theme) => ({
  muiList: {
    padding: "0 !important",
  },
  muiListHeadText: {
    width: "3.4rem",
  },
  muiListDataText: {
    width: "3rem",
  },
  rootbox: {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: "40.25rem !important",
    [theme.breakpoints.down("md")]: {
      width: "20rem !important",
      height: "60% !important",
      display: "block !important",
      overflow: "scroll !important",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "33.375rem !important",
      height: "49% !important",
      display: "block !important",
      overflow: "scroll !important",
      // margin: '-3.188rem',
      marginTop: "-3.75rem  !important",
      marginLeft: "-3.563rem",
      padding: "2.125rem !important",
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: "1.5rem !important",
    paddingTop: "1rem !important",
    paddingLeft: "2rem !important",
    paddingRight: "2rem !important",
    paddingBottom: "1.5rem",
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
}));

// -----------------------------------------------------------------------------

const EngagementDetails = (props) => {
  const [edit, setEdit] = useState(false);
  const [accOpen, setAccOpen] = useState(false);
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );

  const classes = useStyles();
  const location = useLocation();

  const dispatch = useDispatch();
  const keyEngResp = useSelector((state) => ({ ...state.keyEngagement }));
  const netScoreResp = useSelector((state) => ({ ...state.netPromoterScore }));
  const { engStageData } = useSelector((state) => ({ ...state.engStage }));

  const { keyClientAllEngagementData, message, errMessage } = useSelector(
    (state) => ({ ...state.hrEngagement })
  );

  function getEngDate(params) {
    return params?.row?.
      engagementStartDate ? format(new Date(params?.row?.engagementStartDate), "MM-dd-yyyy")
      : "";
  }

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const keyEngagementData = {
    rows:
      keyClientAllEngagementData?.length > 0
        ? keyClientAllEngagementData[0]?.clientEngagement ? keyClientAllEngagementData[0]?.clientEngagement?.keyClientEngagements : []
        : [],
    columns: [
      {
        field: "engagementStageName",
        headerName: "Engagement Stage Name",
        width: 300,
      },
      {
        field: "engagementDate",
        headerName: "Date",
        width: 150,
        valueGetter: getEngDate,
      },
      { field: "status", headerName: "Status", width: 100 },
      {
        field: "comments",
        headerName: "Comments",
        width: 250,
        renderCell: renderCellExpand,
      },
    ],
  };

  const handleDeleteKeyEng = () => {
    let data = {
      id: keyEngResp.selectedData?.id,
    };
    dispatch(deleteKeyEngagement(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          setKeyEngConfirmDialog({
            ...keyEngConfirmDialog,
            isOpen: false,
          });
          dispatch(keyEngResetMessage());
          dispatch(getKeyEngagement(props?.values?.id));
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(keyEngResetErrMessage());
        }, 3000);
      }
    });
  };



  useEffect(() => {
    dispatch(getEnagagementScopeDetails(clientName?.id));
  }, []);



  let deliverables =
    keyClientAllEngagementData?.length > 0 &&
    keyClientAllEngagementData[0]?.clientEngagement
      ?.clientEngagementDeliverableMapping?.length &&
    keyClientAllEngagementData[0]?.clientEngagement?.clientEngagementDeliverableMapping?.reduce(
      (acc, data) => {
        acc += `${data?.engagementDeliverable?.deliverableName}, `;
        return acc;
      },
      ""
    );

  let engagementData =
    keyClientAllEngagementData?.length > 0 && keyClientAllEngagementData[0];



  return (
    <>
      <Layout showDetails={true}>
        {!edit && (
          <Card sx={{ marginTop: "0.5rem" }}>
            <CardHeader
              title={
                <PageHeader variant="pageHeader">
                  Engagement Details for{" "}
                  {engagementData?.excoCustomerUserDetail?.firstName}{" "}
                  {engagementData?.excoCustomerUserDetail?.lastName}
                </PageHeader>
              }
            />
            <CardContent>
              <Stack flexDirection="row" justifyContent="space-between">
                <PageHeader variant="pageSecHeader">
                  {engagementData?.clientEngagement?.engagementStatus}
                </PageHeader>
                {location?.state?.permissions?.find(
                  (e) =>
                    e.applicationService?.name?.split(".")[
                    e?.applicationService?.name?.split(".")?.length - 1
                    ] === "update"
                ) && (
                    <IconButton onClick={() => setEdit(true)}>
                      <EditIcon color="#00527d" />
                    </IconButton>
                  )}
              </Stack>
              <Grid container spacing={2}>
                <Grid container item xs={12} sm={12} md={6}>
                  <CommonGridData
                    heading="Account Name"
                    data={engagementData?.excoCustomerAccount?.accountName}
                  />

                  {/* <Grid item md={4}>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          // style={{ fontWeight: "bold" }}
                        >
                          Exco Account Team
                        </Typography>
                      }
                    ></ListItemText>
                  </Grid>

                  <Grid item md={8}>
                    <ListItemText
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => setAccOpen(true)}
                      className={classes.muiListDataText}
                    >
                      :Click here
                    </ListItemText>
                  </Grid> */}

                  {/* <ListItem className={classes.muiList}>
                    <ListItemText className={classes.muiListHeadText}>
                      Exco Account Team
                    </ListItemText>
                    &nbsp; :&nbsp;{" "}
                    <ListItemText
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => setAccOpen(true)}
                      className={classes.muiListDataText}
                    >
                      Click here
                    </ListItemText>
                    &nbsp; :&nbsp;{" "}
                  </ListItem> */}

                  <CommonGridData
                    heading="Client Name"
                    data={`${engagementData?.excoCustomerUserDetail?.firstName
                      }${" "}${engagementData?.excoCustomerUserDetail?.lastName}`}
                  />
                  <CommonGridData
                    heading="Mentor/Coach Name"
                    data={`${engagementData?.clientEngagement?.clientMentorMapping
                      ?.excoMentor?.excoMentorDetail?.firstName
                      }${" "}${engagementData?.clientEngagement?.clientMentorMapping
                        ?.excoMentor?.excoMentorDetail?.lastName
                      }`}
                  />
                  <CommonGridData
                    heading="Mentor/Coach Email"
                    data={
                      engagementData?.clientEngagement?.clientMentorMapping
                        ?.excoMentor?.email
                    }
                  />
                  <CommonGridData
                    heading="Offering Tier"
                    data={engagementData?.clientEngagement?.engTierName}
                  />
                  <CommonGridData
                    heading="Deliverables"
                    data={deliverables && deliverables}
                  />

                  <CommonGridData
                    heading="Manager Name"
                    data={engagementData?.clientEngagement?.mangerName}
                  />
                  <CommonGridData
                    heading="Manager's Email"
                    data={engagementData?.clientEngagement?.mangerEmail}
                  />

                  <CommonGridData
                    heading="HR Partner Name"
                    data={engagementData?.clientEngagement?.contactName}
                  />
                  <CommonGridData
                    heading="HR Partner's Email"
                    data={engagementData?.clientEngagement?.contactEmail}
                  />

                  <CommonGridData
                    heading="Eng Start Date"
                    data={
                      engagementData?.clientEngagement?.engagementStartDate
                        ? format(
                          new Date(
                            engagementData?.clientEngagement?.engagementStartDate
                          ),
                          "MM-dd-yyyy"
                        )
                        : ""
                    }
                  />

                  <CommonGridData
                    heading="Eng End Date"
                    data={
                      engagementData?.clientEngagement?.engagementEndDate
                        ? format(
                          new Date(
                            engagementData?.clientEngagement?.engagementEndDate
                          ),
                          "MM-dd-yyyy"
                        )
                        : ""
                    }
                  />
                  <CommonGridData
                    heading="Next Update Due"
                    data={
                      engagementData?.clientEngagement?.nextUpdateDue
                        ? format(
                          new Date(
                            engagementData?.clientEngagement?.nextUpdateDue
                          ),
                          "MM-dd-yyyy"
                        )
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <PageHeader variant="pageSecHeader">
                    Key Engagement Dates
                  </PageHeader>
                  <MuiDataGrid
                    setShowDelete={() =>
                      setKeyEngConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => handleDeleteKeyEng(),
                      })
                    }
                    toolbarColumn={true}
                    toolbarFilter={true}
                    toolbarDensity={true}
                    toolbarExport={true}
                    data={keyEngagementData}
                    select={(data) => dispatch(keyEngRowSelect(data))}
                    height={300}
                  />
                  {/* <Box sx={{ marginTop: "0.5rem" }}>
                  <PageHeader variant="pageSecHeader">
                    Net Promoter Score
                  </PageHeader>
                </Box>
                <MuiDataGrid
                  setShowAdd={() => setShowNetScoreAdd(true)}
                  setShowEdit={() => setShowNetScoreEdit(true)}
                  setShowDelete={() =>
                    setNetScoreConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => handleDeleteNetScore(),
                    })
                  }
                  select={(data) => dispatch(netScoreRowSelect(data))}
                  toolbarColumn = {true}
                  toolbarFilter = {true}
                  toolbarDensity = {true}
                  toolbarExport = {true}
                  data={netScoreData}
                  height={300}
                /> */}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Stack
                    sx={{
                      paddingTop: "1rem",
                    }}
                  >
                    <PageHeader variant="pageHeader">
                      {" "}
                      Upload Documents {" "}

                    </PageHeader>
                  </Stack>
                  <Attachments  createDocument={createEngagementDocuments} getDocuments={getEngagementDocuments} />

                </Grid>
              </Grid>
              <PopUpModel
                closeModel={() => setAccOpen(false)}
                open={accOpen}
                handleClose={() => setAccOpen(false)}
              >
                <Box
                  // sx={{ ...style, width: 700 }}
                  className={classes.rootbox}
                >
                  <h3 style={{ textAlign: "center" }}>
                    ExCo Account Team for{" "}
                    {engagementData?.excoCustomerAccount?.accountName}
                  </h3>

                  <ModalCloseBtn close={() => setAccOpen(false)} />
                  <ListItem className={classes.muiList}>
                    <ListItemText className={classes.muiListHeadText}>
                      Executive Sponsor
                    </ListItemText>
                    &nbsp; :&nbsp;
                    <ListItemText className={classes.muiListDataText}>
                      {
                        engagementData?.excoCustomerAccount?.executiveSponsor
                      }
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.muiList}>
                    <ListItemText className={classes.muiListHeadText}>
                      Lead Mentor
                    </ListItemText>
                    &nbsp; :&nbsp;
                    <ListItemText className={classes.muiListDataText}>
                      {
                        engagementData?.excoCustomerAccount?.leadMentor
                      }
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.muiList}>
                    <ListItemText className={classes.muiListHeadText}>

                      Professional Team Member 1
                    </ListItemText>
                    &nbsp; :&nbsp;
                    <ListItemText className={classes.muiListDataText}>
                      {
                        engagementData?.excoCustomerAccount?.professionalTeamMember1
                      }
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.muiList}>
                    <ListItemText className={classes.muiListHeadText}>
                      Professional Team Member 2
                    </ListItemText>
                    &nbsp; :&nbsp;
                    <ListItemText className={classes.muiListDataText}>
                      {
                        engagementData?.excoCustomerAccount?.professionalTeamMember2
                      }
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.muiList}>
                    <ListItemText className={classes.muiListHeadText}>
                      Professional Team Member 3
                    </ListItemText>
                    &nbsp; :&nbsp;
                    <ListItemText className={classes.muiListDataText}>
                      {
                        engagementData?.excoCustomerAccount?.professionalTeamMember3
                      }
                    </ListItemText>
                  </ListItem>
                </Box>
              </PopUpModel>
            </CardContent>


          </Card>
        )}

        {keyEngResp?.errMessage && (
          <ErrorNotifier message={keyEngResp?.errMessage} />
        )}
        {keyEngResp?.message && <Notifier message={keyEngResp?.message} />}
      </Layout>
    </>
  );
};

export default EngagementDetails;
