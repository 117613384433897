import React, { useState, useEffect } from "react";
// 
import PrivateFeedback from "../../private_feedback/PrivateFeedback"

import { useSelector, useDispatch } from "react-redux";
import { getAllFeedbackByHr, createHrPrivateFeedback,updateHrPrivateFeedback,deleteHrPrivateFeedback,rowSelect } from "../../../features/privateFeedbackSlice";

const HrFeedback = () => {

    return (
        <>
            <PrivateFeedback 
             getAllFeedback={ getAllFeedbackByHr}
             createFeedback={createHrPrivateFeedback}
             updateFeedback={updateHrPrivateFeedback}
             deleteFeedback={deleteHrPrivateFeedback}
             rowSelect={rowSelect}
            />
        </>
    );
};

export default HrFeedback;
