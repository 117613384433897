import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  GET_REPORTS_AREA_FOCUS,
  GET_REPORTS_ENGAGEMENT_STATUS,
  GET_ENGAGEMENT_DETAIL,
  CLIENT_ENGAGEMENT_REPORTS,
  CLIENT_LATEST_SUMMARY,
  SHARE_WITH_HR_SUMMARY,
  SHARE_WITH_MANAGER_SUMMARY,
  GET_ALL_PREVIOUS_SUMMARY_DATA,
  CLIENT_PREVIOUS_SUMMARY_DATA_BY_ID,
  ACCEPT_BY_CLIENT_SUMMARY,
  CLIENT_GENERATE_SUMMARY,
  GET_SUMMARY_DOCUMENTS_API
} from "../../utils/routes";

// ---------------------------------------------------------------------

export const getAreaOfFocus = createAsyncThunk(
  "reports/getAreaOfFocus",
  async () => {
    return axios.get(GET_REPORTS_AREA_FOCUS, { withCredentials: true });
  }
);

export const getLatestClientSummary = createAsyncThunk(
  "reports/getLatestSummary",
  async (data) => {
    return axios.post(CLIENT_LATEST_SUMMARY, data, { withCredentials: true });
  }
);

export const getLatestSummaryDocuments = createAsyncThunk(
  "reports/getSummaryDocuments",
  async (data) => {
    return axios.get(GET_SUMMARY_DOCUMENTS_API, { withCredentials: true });
  }
);

export const getGenerateClientSummary = createAsyncThunk(
  "reports/geGenerateSummary",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CLIENT_GENERATE_SUMMARY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagementNotes = createAsyncThunk(
  "reports/getEngagement",
  async () => {
    return axios.get(GET_REPORTS_ENGAGEMENT_STATUS, { withCredentials: true });
  }
);

export const getFullEnagegementsReports = createAsyncThunk(
  "reports/getFullReports",
  async () => {
    let result = axios.get(CLIENT_ENGAGEMENT_REPORTS, {
      withCredentials: true,
    });
    return result;
  }
);

export const getPreviousSummaryData = createAsyncThunk(
  "summary/summaryData",
  async (data) => {
    return axios.post(GET_ALL_PREVIOUS_SUMMARY_DATA, data, {
      withCredentials: true,
    });
  }
);

export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const shareWithHrSummary = createAsyncThunk(
  "clientSummary/shareWithHr",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const response = await axios.post(SHARE_WITH_HR_SUMMARY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const shareWithManagerSummary = createAsyncThunk(
  "clientSummary/shareWithManager",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const response = await axios.post(SHARE_WITH_MANAGER_SUMMARY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const shareByClientSummary = createAsyncThunk(
  "clientSummary/shareByClient",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(ACCEPT_BY_CLIENT_SUMMARY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitPreviousSummaryForClients = createAsyncThunk(
  "summary/clientSummaryDateSubmit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        CLIENT_PREVIOUS_SUMMARY_DATA_BY_ID,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    areaFocusData: [],
    engagementFocusData: [],
    summaryDocumentsData:[],
    engagementFullReportsDataRating: [],
    previousSummaryData: [],
    generateSummaryData: {},
    getSummaryDateReports: [],
    selectedData: {},
    dateErrorMessageData: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    dateErrorMessage: (state, action) => {
      state.dateErrorMessageData = action.payload;
    },
    emptyingArry: (state, action) => {
      state.generateSummaryData = {};
    },
  },
  extraReducers: {
    [getLatestSummaryDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [getLatestSummaryDocuments.fulfilled]: (state, action) => {
      state.loading = false;
      state.summaryDocumentsData = action?.payload?.data;
    },
    [getLatestSummaryDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.generateSummaryData = {};
      state.errMessage = action?.payload?.message;
    },


    [getGenerateClientSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [getGenerateClientSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.generateSummaryData = action?.payload?.data;
    },
    [getGenerateClientSummary.rejected]: (state, action) => {
      state.loading = false;
      state.generateSummaryData = {};
      state.errMessage = action?.payload?.message;
    },

    [getAreaOfFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [getAreaOfFocus.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.areaFocusData = action?.payload?.data;
    },
    [getAreaOfFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [submitPreviousSummaryForClients.pending]: (state, action) => {
      state.loading = true;
    },
    [submitPreviousSummaryForClients.fulfilled]: (state, action) => {
      console.log(action);
      state.loading = false;
      state.generateSummaryData = action?.payload?.data;
    },

    [submitPreviousSummaryForClients.rejected]: (state, action) => {
      console.log(action);
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [getPreviousSummaryData.pending]: (state, action) => {
      state.loading = true;
    },
    [getPreviousSummaryData.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.previousSummaryData = action?.payload?.data;
    },
    [getPreviousSummaryData.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getLatestClientSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [getLatestClientSummary.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.generateSummaryData = action?.payload?.data;
    },
    [getLatestClientSummary.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
      state.generateSummaryData = [];
    },
    [getFullEnagegementsReports.pending]: (state, action) => {
      state.loading = true;
    },
    [getFullEnagegementsReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagementFullReportsDataRating = action?.payload?.data;
    },
    [getFullEnagegementsReports.rejected]: (state, action) => {
     
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getEngagementNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementNotes.fulfilled]: (state, action) => {
      
      state.loading = false;
      state.engagementFocusData = action?.payload?.data;
    },
    [getEngagementNotes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [shareWithHrSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [shareWithHrSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [shareWithHrSummary.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [shareWithManagerSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [shareWithManagerSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [shareWithManagerSummary.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [shareByClientSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [shareByClientSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [shareByClientSummary.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const {
  resetMessage,
  emptyingArry,
  resetErrMessage,
  dateErrorMessage,
  rowSelect,
} = engagementSlice.actions;
export default engagementSlice.reducer;
