export const BASE_URL =
  process.env.REACT_APP_BASE_URL ||
  "https://test.ourai.in/96b5c1d8-8295-4386-85c3-aaefa84aba63/v1/api";
export const BASE_HR_URL =
  process.env.REACT_APP_BASE_URL || "https://www.ourai.in";
export const ROLE_URL =
  process.env.REACT_APP_ROLE_URL || "https://www.ourai.in/excoadmin";

export const MICRO_SITE_URL =
  process.env.REACT_APP_MICRO_SITE_URL || "https://www.ourai.in/microsite";

export const MICRO_SITE_MOCK_URL = process.env.REACT_APP_MICRO_SITE_MOCK_URL;
export const MICRO_SITE_CUSTOM_TEXT =
  process.env.REACT_APP_MICRO_SITE_CUSTOM_TEXT || "";
export const MICRO_SITE_VERSION =
  process.env.REACT_APP_MICRO_SITE_VERSION || "";

export const CUSTOMER_LOGO =
  process.env.REACT_APP_CUSTOMERID || "d6b2ab45-d504-42c2-8ffc-b2912aae041d";
export const CUSTOMER_LOGO_URL = `${MICRO_SITE_URL}/static/${CUSTOMER_LOGO}`;

export const CLIENT_SERVER_URL =
  process.env.REACT_APP_CLIENT_SERVER_URL || "https://test.ourai.in";
