import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Grid, Stack, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

// components

import Layout from "../../../layout/Layout";
import { MuiDataGrid, GridCellExpand } from "../../../components";
import { PageHeader } from "@excoleadershipui/pionweblib";
import EngagementDetails from "./keyEngagementDetails";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

//redux
import {
  getClientForHr,
  rowSelect,
} from "../../../features/hr/assesment/hrAssesmentSlice";
// import {
//   getEngagementAccount,
//   getMentorMappedAccount,
//   getMentorMappedLatestClients,
//   rowSelect,
// } from "../../features/dashboard/dashBoardSlice";
// import { getAllKeyClientEngagementStages } from "../../features/engagement/engagementSlice";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  subChildOne: {
    width: "100%",
  },

  subChildTwo: {
    width: "100%",
    height: "25rem",
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "25rem",
      margin: "1rem 0 1rem 0",
    },
  },
}));
export default function Dashboard() {
  const [showDetails, setShowDetails] = useState(false);
  const [showTopBar, setShowTopBar] = useState(true);
  const [clientId, setClientId] = useState(localStorage.getItem("clientid"));
  const {
    loading,
    message,
    errMessage,
    mentorEngagementData,
    mentorAccountData,
    latestTopClients,
  } = useSelector((state) => ({
    ...state.dashboardMappedMentor,
  }));

  const { getAllClientData, selectedData } = useSelector((state) => ({
    ...state.HrClientLeadership,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function getCustomerAccount(params) {
    return `${params.row.excoCustomerUser.excoAccount.accountName}`;
  }

  function getClientAccount(params) {
    return `${params.row.clientMentorMapping?.excoCustomerUser?.excoCustomerUserDetail?.firstName}
    ${params.row.clientMentorMapping?.excoCustomerUser?.excoCustomerUserDetail?.lastName}
    `;
  }

  const getEngagementDate = (params) => {
    return params.row.engagementStartDate
      ? format(new Date(params.row.engagementStartDate), "MM-dd-yyyy")
      : "";
  };

  const getEngagementEndDate = (params) => {
    return params.row.engagementEndDate
      ? format(new Date(params.row.engagementEndDate), "MM-dd-yyyy")
      : "";
  };

  const getEngagementUpdatedDue = (params) => {
    return params.row.nextUpdateDue
      ? format(new Date(params.row.nextUpdateDue), "MM-dd-yyyy")
      : "";
  };

  const getMentorCoach =(params) => {
    return `${params.row.clientMentorMapping?.excoMentor?.excoMentorDetail?.firstName}
    ${params.row.clientMentorMapping?.excoMentor?.excoMentorDetail?.lastName}`;
  }

const getOfferingType =(params)=> {
  return `${params.row.engTierName}`;
}

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const dataTwo = {
    rows: getAllClientData,
    columns: [
      {
        field: "client",
        headerName: "Leader Name",
        width: 200,
        valueGetter: getClientAccount,
        renderCell: (params) => {
          return (
            <>
              <span
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem(
                    "clientid",
                    JSON.stringify({
                      id: params.row.clientMentorMapping?.excoCustomerUser?.id,
                      mentorId :  params.row.clientMentorMapping?.excoMentor?.id,
                      mentorName :  `${params.row.clientMentorMapping?.excoMentor?.excoMentorDetail?.firstName}${" "}${params.row.clientMentorMapping?.excoMentor?.excoMentorDetail?.lastName}`,
                      firstName:
                        params.row.clientMentorMapping?.excoCustomerUser
                          ?.excoCustomerUserDetail?.firstName,
                      lastName:
                        params.row.clientMentorMapping?.excoCustomerUser
                          ?.excoCustomerUserDetail?.lastName,
                      accountName:
                        params.row.clientMentorMapping?.excoCustomerUser
                          ?.excoAccount?.accountName,
                      engagementStartDate: params.row.engagementStartDate,
                    })
                  );

                  navigate("/exco/hr/engagement-details");
                }}
              >
                {
                  params.row.clientMentorMapping?.excoCustomerUser
                    ?.excoCustomerUserDetail?.firstName
                }{" "}
                {
                  params.row.clientMentorMapping?.excoCustomerUser
                    ?.excoCustomerUserDetail?.lastName
                }
              </span>
            </>
          );
        },
      },
      { 
        field: "excoMentorDetail", 
        headerName: "Mentor/Coach", 
        width: 200,
        valueGetter: getMentorCoach,
      },
      { 
        field: "engTierName", 
        headerName: "Offering Type", 
        width: 200,
        valueGetter: getOfferingType,
      },

      { field: "engagementStatus",
        headerName: "Status",
        width: 200 
      },
      {
        field: "engagementStartDate",
        headerName: "Start Date",
        width: 200,
        valueGetter: getEngagementDate,
      },

      {
        field: "engagementEndDate",
        headerName: "Renewal Date",
        width: 200,
        valueGetter: getEngagementEndDate,
      },

      {
        field: "nextUpdateDue",
        headerName: "Next Update Due",
        width: 200,
        valueGetter: getEngagementUpdatedDue,
      },
    ],
  };

  const data = {
    rows: mentorAccountData,
    columns: [
      {
        field: "accountName",
        headerName: "Account Name",
        width: 200,
        valueGetter: getCustomerAccount,
      },
    ],
  };

     const {
       getAllRolesData,
       loggedIn,
       rolesData,
       rolesApiData,
       localStoreRoleData,
     } = useSelector((state) => ({
       ...state.auth,
     }));

  // useEffect(() => {
  //   dispatch(getEngagementAccount());
  //   dispatch(getMentorMappedAccount());
  //   dispatch(getAllKeyClientEngagementStages());

  // }, []);

  // useEffect(() => {
  //   clientId && setShowDetails(true);

  // }, []);

  const classes = useStyles();

  useEffect(() => {
    dispatch(getClientForHr());
  }, []);

 

  return (
    <Layout show={true}>
      <Stack sx={{ marginTop: "6rem" }}>
        <PageHeader variant="pageHeader">All Engagements</PageHeader>
        <span style={{fontWeight:"bold",fontSize:"1.625rem"}}>Hi {rolesApiData?.getExcoCustomerUserDetail?.firstName},</span>
        <span style={{marginTop:"1.25rem",marginBottom:"1.875rem",fontSize:"1.125rem"}}>Welcome to your ExCo engagements overview! Click on a leader's name to view details about their engagement.</span>
        <MuiDataGrid
          select={(data) => {
            dispatch(rowSelect(data));
          }}
          viewAdd={false}
          data={dataTwo}
        />
      </Stack>
    </Layout>
  );
}
