import React, { useEffect, useState } from "react";
// components
import Layout from "../../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import { MuiDataGrid, PopUpModel } from "../../../components";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import Slider from "@mui/material/Slider";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FormSubmitButton } from "../../../components";
import InputAdornment from "@mui/material/InputAdornment";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
import ModalCloseBtn from "../../../components/common/ModalCloseBtn";
import { MuiAutocomplete } from "../../../components";
import {
  getRatingByclientId,
  getLatestOutcomesForHr,
  getPreviousOutcomeForHr,
  emptyingArray,
  resetErrMessage,
  resetMessage,
  submitPreviousOutcomesForHr,
  rowSelect,
} from "../../../features/hr/ratings/ratingSlice";
import { getClientFocus } from "../../../features/hr/coaching_focus/hrFocusSlice";
import { useSelector, useDispatch } from "react-redux";

import { format } from "date-fns";

const Assesment = () => {
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const [showModelGenerate, setShowModelGenerate] = useState("");
  const [generateDateValue, setGenerateDateValue] = useState("");
  const [generateRowId, setGenerateRowId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const {
    loading,
    message,
    errMessage,
    clientRatingData,
    previousOutcomeDataForHr,
    selectedData,
  } = useSelector((state) => ({ ...state.HrRating }));

  const { clientFocusData } = useSelector((state) => ({
    ...state.HrCoachingFocus,
  }));

  const dispatch = useDispatch();

  const handleChange = (e) => {
    let clientId = e.target.value;
    setClientName(clientId);
    dispatch(getRatingByclientId(clientId));
    dispatch(getLatestOutcomesForHr(clientId));
    setTimeout(() => {
      dispatch(resetErrMessage());
    }, 2000);
  };

  const marks = [
    {
      value: "0",
      label: "N/A",
    },
    {
      value: "20",
      label: "-2",
    },
    {
      value: "40",
      label: "-1",
    },
    {
      value: "60",
      label: "0",
    },
    {
      value: "80",
      label: "1",
    },
    {
      value: "100",
      label: "2",
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const useStyles = makeStyles((theme) => ({
    responsive_width: {
      [theme.breakpoints.down("md")]: {
        width: "19.875rem !important",
        overflowX: "scroll",
      },
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    dispatch(emptyingArray());
    dispatch(getClientFocus());
  }, []);

  useEffect(() => {
    setStartDate(
      clientRatingData.length > 0 &&
        clientRatingData[0].ratingData[0].ratingFromDate
        ? format(
            new Date(
              clientRatingData.length > 0 &&
                clientRatingData[0].ratingData[0].ratingFromDate
            ),
            "yyyy-MM-dd"
          )
        : ""
    );
    setEndDate(
      clientRatingData.length > 0 &&
        clientRatingData[0].ratingData[0].ratingFromDate
        ? format(
            new Date(
              clientRatingData.length > 0 &&
                clientRatingData[0].ratingData[0].ratingToDate
            ),
            "yyyy-MM-dd"
          )
        : ""
    );
  }, [clientRatingData]);

  const closeShowModelGenerate = () => {
    setShowModelGenerate(false);
  };

  const handleDateGenerateChange = (e) => {
    setGenerateDateValue(e.target.value);
    setGenerateRowId(e.target.value);
  };

  const handleShowPreviousOutcomes = () => {
    setShowModelGenerate(!showModelGenerate);
   
    dispatch(getPreviousOutcomeForHr(clientName?.id));
  };

  const handleGenerateDateSubmit = (e) => {
    e.preventDefault();
    let body = {
      clientProgressReviewId: generateRowId,
    };
    dispatch(submitPreviousOutcomesForHr(body)).then((res) => {
      closeShowModelGenerate();

      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          // dispatch(getAssesmentImprovement());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  useEffect(() => {
    dispatch(emptyingArray());
    clientName &&
      dispatch(getLatestOutcomesForHr(clientName?.id));
    setTimeout(() => {
      dispatch(resetErrMessage());
    }, 2000);
  }, [clientName]);
  return (
    <Layout>
      <Stack>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: "1rem",
            fontFamily: "arial",
          }}
        >
          <Typography
            sx={{ fontSize: "1.25rem", fontWeight: "700" }}
            gutterBottom
            variant="h9"
            component="div"
          >
            Engagement Rating for
          </Typography>

     
          {clientName && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {" "}
              <TextField
                sx={{ marginLeft: "0.5rem", width: "9rem" }}
                type="date"
                name="startDate"
                id="demo-simple-select"
                required={true}
                value={startDate}
                disabled={true}
                defaultValue=""
              />
              <Typography
                sx={{ fontSize: "1rem", marginLeft: "0.5rem" }}
                gutterBottom
                variant="h9"
                component="div"
              >
                To
              </Typography>
              <TextField
                sx={{ marginLeft: "0.5rem", width: "9rem" }}
                type="date"
                name="startDate"
                value={endDate}
                id="demo-simple-select"
                disabled={true}
              />{" "}
            </Stack>
          )}

          {clientName &&
            clientRatingData.length > 0 &&
            clientRatingData[0].ratings.length > 0 && (
              <Stack sx={{ marginLeft: "0.5rem" }}>
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={handleShowPreviousOutcomes}
                >
                  Previous Outcomes
                </FormSubmitButton>
              </Stack>
            )}

          <PopUpModel
            open={showModelGenerate}
            handleClose={closeShowModelGenerate}
            closeModel={closeShowModelGenerate}
          >
            <Box
              sx={{ ...style, width: 700 }}
              component="form"
              className={classes.responsive_width}
              noValidate
            >
              <ModalCloseBtn close={closeShowModelGenerate} />

              <div style={{ width: "50%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Date</InputLabel>
                  <Select
                    type="select"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="generateDateValue"
                    value={generateDateValue}
                    label="Geography"
                    onChange={handleDateGenerateChange}
                  >
                    {previousOutcomeDataForHr.length > 0 &&
                      previousOutcomeDataForHr.map((itemValue) => {
                      
                        return (
                          <MenuItem key={itemValue.id} value={itemValue.id}>
                            {format(
                          new Date(itemValue.ratingFromDate),
                          "MM-dd-yyyy"
                        )}{" "}
                        to{" "}
                        {format(new Date(itemValue.ratingToDate), "MM-dd-yyyy")}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>

              <Stack sx={{ marginTop: "1rem" }}>
                <FormSubmitButton
                  type="submit"
                  variant="contained"
                  onClick={handleGenerateDateSubmit}
                >
                  Submit
                </FormSubmitButton>
              </Stack>
            </Box>
          </PopUpModel>
        </Stack>
      </Stack>
      {clientRatingData.length > 0 &&
        clientRatingData[0].ratings.length > 0 && (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "arial",
              marginTop: "3rem",
            }}
          >
            <Typography
              sx={{ fontSize: "1rem", fontWeight: "bold" }}
              gutterBottom
              variant="h9"
              component="div"
            >
              Impact Scale Rating
            </Typography>
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Stack>
                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    N/A
                  </span>{" "}
                  Priorities Shifted,and this is no longer applicable
                </Typography>
                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    -2
                  </span>{" "}
                  Significant deterioration in outcome
                </Typography>

                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    -1
                  </span>{" "}
                  Slight deterioration in outcome
                </Typography>
              </Stack>
              <Stack sx={{ marginLeft: "1rem" }}>
                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    0
                  </span>{" "}
                  No change in outcome
                </Typography>
                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    1
                  </span>{" "}
                  Achieved expected outcome
                </Typography>
                <Typography
                  sx={{ fontSize: "0.75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    2
                  </span>{" "}
                  Greater than expected outcome
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      {clientRatingData.length > 0 &&
        clientRatingData[0].ratings.length > 0 &&
        clientRatingData[0].ratings.map((data) => {
          return (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                fontFamily: "arial",
                marginTop: "1rem",
              }}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: "700", width: "30%" }}
                variant="h6"
                gutterBottom
                component="div"
              >
                { data?.developmentAreasCoachingFocus
                      ?.developmentAreaCoachingFocusName}
              </Typography>

              <Stack
                sx={{
                  marginLeft: "2rem",
                  display: "flex",
                  width: "60%",

                  flexDirection: "column",
                  fontFamily: "arial",
                }}
              >
                <TextField
                  sx={{ width: "100%", height: "5rem" }}
                  hiddenLabel
                  variant="filled"
                  // disabled
                  value={data.comment}
                  // onChange={(e)=>setRatingComments(e.target.value)}
                />

                <div
                  style={{
                    width: "100%",
                    "& .super-app-theme": {
                      "&:nth-of-type(odd)": {
                        bgcolor: (theme) => theme.palette.primary.light,
                      },
                    },
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      paddingBottom: "1rem",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "1rem", color: "blue" }}
                      gutterBottom
                      component="div"
                    >
                      Impact
                    </Typography>
                    <Slider
                      disabled
                      sx={{
                        marginLeft: "1rem",
                      }}
                      aria-label="Custom marks"
                      value={data?.rating}
                      step={20}
                      marks={marks}
                    />
                  </Stack>
                </div>
              </Stack>
            </Stack>
          );
        })}

      {errMessage && <ErrorNotifier message={errMessage} />}
    </Layout>
  );
};

export default Assesment;
