import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./fonts/din/DIN Bold.ttf";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from "./app/store";
import { Provider } from "react-redux";
import { theme } from "./theme/Theme";
import { ThemeProvider } from "@mui/material";
import axios from "axios"
import { IS_LOGGED_IN } from './utils/routes';

// material ui

axios.interceptors.response.use(function(response) {
  return response;
}, function(error) {
  if (error.response.status === 403) {
    // const checkStatusUrl = 'https://dev.piedap.com/piedapiam/v1/api/authentication/is-piedap-user-logged-in';

    axios.get(IS_LOGGED_IN, {withCredentials:true}).then(response => {

      console.log("response " , response)
      if (response.data === false) {
        window.location.href = '/';
      }
    }).catch(error => {
      console.error('Error checking user login status:', error);
    });
  }
  return Promise.reject(error);
});


ReactDOM.render(
  <Provider store={store}>
   <ThemeProvider theme={theme}>
  <App/>
  </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

