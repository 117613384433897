import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  
  GET_REPORTS_AREA_FOCUS,
  GET_MANAGER_FOCUS_BY_ID ,
  GET_ALL_CLIENTS_MANAGER,
  GET_ALL_CLIENTS_MANAGER_PURPOSE,
  GET_REPORTS_ENGAGEMENT_STATUS,
  GET_ENGAGEMENT_DETAIL

} from "../../../utils/routes";

// ---------------------------------------------------------------------


export const getClientForManager = createAsyncThunk(
  "coachingfocus/getManagerClient",
  async () => {
    return axios.get(GET_ALL_CLIENTS_MANAGER, { withCredentials: true });
  }
);

export const getManagerPurpose = createAsyncThunk(
  "coachingfocus/getManagerPurpose",
  async (data) => {
    return axios.get(`${GET_ALL_CLIENTS_MANAGER_PURPOSE}/${data}`, { withCredentials: true });
  }
);

export const getManagerCoachingFocusById = createAsyncThunk(
  "coachingfocus/getFocusById",
  async (data) => {
    return axios.get( `${GET_MANAGER_FOCUS_BY_ID}/${data}`, { withCredentials: true });
  }
);


export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const  engagementSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    getAllClientData:[],
    getManagerPurposeData:[],
    areaFocusData: [],
    engagementFocusData:[],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
   
    [getClientForManager.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientForManager.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [getClientForManager.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getManagerPurpose.pending]: (state, action) => {
      state.loading = true;
    },
    [getManagerPurpose.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.getManagerPurposeData = action?.payload?.data;
    },
    [getManagerPurpose.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },



    [getManagerCoachingFocusById.pending]: (state, action) => {
      state.loading = true;
    },
    [getManagerCoachingFocusById.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.areaFocusData = action?.payload?.data;
    },
    [getManagerCoachingFocusById.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage, rowSelect } = engagementSlice.actions;
export default  engagementSlice.reducer;


