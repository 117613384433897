import React, { useState, useEffect } from "react";
// components
import Layout from "../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import {
  MuiDataGrid,
  PopUpModel,
  MuiDataGridForSummary,
  EyeInfo,
} from "../../components";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ModalCloseBtn from "../../components/common/ModalCloseBtn";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Chart from "react-apexcharts";
import Notifier from "../../components/common/Notifier";
import ErrorNotifier from "../../components/common/ErrorNotifier";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
// import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormSubmitButton } from "../../components";
import InputAdornment from "@mui/material/InputAdornment";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";

import { GridCellExpand } from "../../components";
import { format } from "date-fns";

import { DateTime } from "luxon";
import Attachments from "../../components/common/upload_documents/Attachments";

import HowToUse from "../../components/common/HowToUse";

//redux-toolkit
import { useDispatch, useSelector } from "react-redux";
import {
  getFullEnagegementsReports,
  resetErrMessage,
  resetMessage,
  getLatestClientSummary,
  shareWithHrSummary,
  shareWithManagerSummary,
  getPreviousSummaryData,
  submitPreviousSummaryForClients,
  getGenerateClientSummary,
  shareByClientSummary,
  dateErrorMessage,
  getLatestSummaryDocuments,
  emptyingArry,
} from "../../features/reports/reportsSlice";
import {
  getEngagementDocuments,
  createEngagementDocuments,
} from "../../features/dashboard/engagementDocumentSlice";
import { getToogleData } from "../../features/ratings/ratingSlice";
import { Grid, useMediaQuery } from "@mui/material";

const DonutMemo = React.memo(({ dataReports }) => {
  return <Doughnut data={dataReports} options={dataReports.options} />;
});

const Reports = () => {
  const [checkInRating, setCheckingRating] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [engagementDate, setEngagementDate] = useState(
    localStorage.getItem("engagementStartDate")
  );
  const [checkinValue, setCheckinValue] = useState("");
  const [showModelGenerate, setShowModelGenerate] = useState("");
  const [generateDateValue, setGenerateDateValue] = useState("");
  const [generateRowId, setGenerateRowId] = useState(null);
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const [commentId, setCommentId] = useState(null);
  const [generateRowCheckId, setGenerateRowCheckId] = useState(null);

  const dispatch = useDispatch();
  const {
    loading,
    message,
    errMessage,
    areaFocusData,
    engagementFullReportsDataRating,
    selectedData,
    getSummaryDateReports,
    generateSummaryData,
    summaryDocumentsData,
    dateErrorMessageData,
    previousSummaryData,
  } = useSelector((state) => ({ ...state.ClientReports }));

  const { clientToggleData } = useSelector((state) => ({
    ...state.clientRating,
  }));

  const HowToUseContent = `
Select a date range above and click the "Generate" button to display a history of all the leadership
 coaching impact updates that you and your Mentor/Coach have completed within the selected time period.`;

  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 500 },
    { name: "Group C", value: 600 },
  ];

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const getCreatedOnDate = (params) => {
    return params.row.createdOn
      ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
      : "";
  };

  const getUpdatedOnDate = (params) => {
    return params.row.updatedOn
      ? format(new Date(params.row.updatedOn), "MM-dd-yyyy")
      : "";
  };

  const dataTwo = {
    rows:
      Object.keys(generateSummaryData).length > 0
        ? generateSummaryData.checkin
        : [],
    columns: [
      {
        field: "Rating By Mentor/Coach ",
        headerName: "Rating By Mentor/Coach",
        width: 400,
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  backgroundColor:
                    (params?.row?.engagementCheckInRating === 1 && "red") ||
                    (params?.row?.engagementCheckInRating === 2 && "yellow") ||
                    (params?.row?.engagementCheckInRating === 3 && "green"),
                  width: "20px",
                  height: "20px",
                  borderRadius: "100%",

                  display: "inline-block",
                }}
              ></div>

              <div style={{ display: "inline-block", marginLeft: "1rem" }}>
                {(params?.row?.engagementCheckInRating === 1 && "Red") ||
                  (params?.row?.engagementCheckInRating === 2 && "Yellow") ||
                  (params?.row?.engagementCheckInRating === 3 && "Green")}
              </div>
            </div>
          );
        },

        valueGetter: (params) => "red",
        cellClassName: (params) => {
          return classes.cell;
        },
      },
      {
        field: "dateAdded",
        headerName: "Date Added",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getUpdatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
    ],
  };

  const dataOne = {
    rows:
      Object.keys(generateSummaryData).length > 0
        ? generateSummaryData.cfa
        : [],
    columns: [
      {
        field: "progressDescription",
        headerName: "Description",
        renderCell: renderCellExpand,
        width: 980,
      },
      {
        field: "dateAdded",
        headerName: "Date Added",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getUpdatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
    ],
  };

  const dataThree = {
    rows:
      Object.keys(generateSummaryData).length > 0
        ? generateSummaryData.notes
        : [],
    columns: [
      {
        field: "engagementNote",
        headerName: "Notes from Mentor/Coach",
        renderCell: renderCellExpand,
        width: 400,
      },
      {
        field: "dateAdded",
        headerName: "Date Added",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getCreatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 200,
        renderCell: renderCellExpand,
        valueGetter: getUpdatedOnDate,
        cellClassName: (params) => {
          return classes.cell;
        },
      },
    ],
  };

  useEffect(() => {
    let result =
      Object.keys(generateSummaryData).length > 0 &&
      generateSummaryData?.checkin[0]?.engagementCheckInRating &&
      generateSummaryData?.checkin[0]?.engagementCheckInRating;

    if (result === 1) {
      setCheckinValue("Red");
      setCheckingRating(150);
    } else if (result === 2) {
      setCheckinValue("Yellow");
      setCheckingRating(450);
    } else if (result === 3) {
      setCheckinValue("Green");
      setCheckingRating(750);
    } else {
      setCheckingRating(null);
    }
  }, [generateSummaryData]);

  ChartJS.register(ArcElement, Legend);

  const dataReports = {
    datasets: [
      {
        data: [300, 300, 300],
        needleValue: Number(checkInRating),
        // Change This for Meter
        backgroundColor: ["red", "#FFCE56", "#71B278"],
        hoverBackgroundColor: ["red", "#FFCE56", "#71B278"],
      },
    ],
    options: {
      layout: {},
      rotation: -95,
      circumference: 60 * Math.PI,
      legend: {
        display: false,
      },
      cutoutPercentage: 70,
    },
  };

  ChartJS.register({
    id: "doughnut",
    afterDraw: (chart, args, opts) => {
      var needleValue = chart.config.data.datasets[0].needleValue;
      var dataTotal = chart.config.data.datasets[0].data.reduce(
        (a, b) => a + b,
        0
      );
      var angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
      var ctx = chart.ctx;
      var cw = chart.canvas.offsetWidth;
      var ch = chart.canvas.offsetHeight - 200;
      var cx = cw / 2;
      var cy = ch + 95;
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -3);
      ctx.lineTo(ch - 5, 0);
      ctx.lineTo(0, 3);
      ctx.fillStyle = "rgb(0, 0, 0)";
      ctx.fill();
      ctx.rotate(-angle);
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, Math.PI * 2);
      ctx.fill();
    },
  });

  const COLORS = ["red", "yellow", "green"];

  const useStyles = makeStyles((theme) => ({
    cell: {
      whiteSpace: "none !important",
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    responsive_btn: {
      [theme.breakpoints.down("md")]: {
        marginRight: "18rem",
        marginTop: "2rem",
      },
    },
    responsive_width: {
      [theme.breakpoints.down("md")]: {
        width: "19.875rem !important",
        overflowX: "scroll",
      },
    },
    datediv: {
      [theme.breakpoints.down("md")]: {
        marginTop: "1rem !important",
      },
    },

    previousSummaryClass: {
      [theme.breakpoints.down("md")]: {
        width: "15rem !important",
        marginTop: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        width: "50% !important",
        marginTop: "1rem",
      },
    },

    responsive_img: {
      [theme.breakpoints.down("md")]: {
        marginTop: "1px !important",
      },
    },
    desk_width: {
      [theme.breakpoints.up("md")]: {
        width: "12.25rem",
      },
    },
    desk_textbox: {
      [theme.breakpoints.up("md")]: {
        marginLeft: "2.625rem !important",
      },
    },
    previousDiv: {
      [theme.breakpoints.down("md")]: {
        marginTop: "2rem !important",
        marginLeft: "7rem !important",
        whiteSpace: "nowrap !important",
      },
    },
    dateParentDiv: {
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        height: "3.5rem",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column",
        height: "3.5rem",
        marginLeft: "0.5rem",
      },
    },
  }));
  const classes = useStyles();
  const matches2 = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMappingGrpah = () => {
    if (Object.keys(generateSummaryData).length > 0) {
      let data = [];

      const items = [];
      for (const res of generateSummaryData?.ratings) {
        let coachingFocusName;
        for (const res1 of res.mentorToClientRating) {
          coachingFocusName = res?.otherDevelopmentAreaCoachingFocus;
          const date = new Date(res1?.updatedOn);
          const transformDate =
            date.getMonth() +
            1 +
            "-" +
            date.getDate() +
            "-" +
            date.getFullYear() +
            " UTC";

          let rate;
          if (res1.rating === 150) rate = 3;
          if (res1.rating === 100) rate = 2;
          if (res1.rating === 50) rate = 1;
          if (res1.rating === 1) rate = 0;

          data.push({
            x: transformDate,
            y: rate,
          });
        }

        const options = {
          colors: ["#000"],
          tooltip: {
            enabled: false,
          },
          chart: {
            height: 380,
            width: "100%",
            type: "line",
            animations: {
              initialAnimation: {
                enabled: true,
              },
            },
          },
          series: [
            {
              data,
            },
          ],
          markers: {
            size: 5,
            colors: "#000000",
            strokeColors: "#111111",
            strokeWidth: 15,
            strokeOpacity: 0.2,
            fillOpacity: 0.7,
          },
          xaxis: {
            type: "datetime",
          },

          yaxis: {
            labels: {
              formatter: function (val, index) {
                if (val === -3) return "N/A";
                return val;
              },
            },

            min: 0,
            max: 3,

            tickAmount: 3,
          },
        };
        data = [];
        let finalWidth = matches2 ? "250" : "600";
        items.push({
          name: coachingFocusName,
          val: (
            <Chart
              options={options}
              series={options.series}
              type="line"
              width={finalWidth}
              height="200"
            />
          ),
        });
      }

      return (
        <>
          {items.map((data, index) => {
            return (
              <Stack>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    marginTop: index !== 0 && "1.9rem",
                  }}
                >
                  {data.name}
                </Typography>
                <Paper>{data.val}</Paper>
              </Stack>
            );
          })}
        </>
      );
    }
  };

  const handlViewMoreClick = (id) => {
    setCommentId(id);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const closeShowModelGenerate = () => {
    setShowModelGenerate(false);
  };

  const handleDateGenerateChange = (e) => {
    setGenerateDateValue(e.target.value);
    setGenerateRowId(e.target.value);
  };

  const handleShowPreviousOutcomes = () => {
    setShowModelGenerate(!showModelGenerate);
    dispatch(getPreviousSummaryData());
  };

  const handleGenerateDateSubmit = (e) => {
    e.preventDefault();
    let body = {
      clientEngagementCheckinId: generateRowId,
    };
    dispatch(submitPreviousSummaryForClients(body)).then((res) => {
      closeShowModelGenerate();
      setGenerateRowCheckId(generateRowId);
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          // dispatch(getAssesmentImprovement());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 2000);
      }
    });
  };

  const handleShareHrClick = () => {
    let body = {
      clientEngagementCheckinId: generateSummaryData?.checkin[0]?.id,
    };

    dispatch(shareWithHrSummary(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          generateRowCheckId
            ? dispatch(
                submitPreviousSummaryForClients({
                  clientEngagementCheckinId: generateRowCheckId,
                })
              )
            : dispatch(getLatestClientSummary());
          // dispatch(getAssesmentManagerButton());
          // dispatch(getAssesmentHrButton());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };

  const handleShareManagerClick = () => {
    let body = {
      clientEngagementCheckinId: generateSummaryData?.checkin[0]?.id,
    };
    dispatch(shareWithManagerSummary(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          generateRowCheckId
            ? dispatch(
                submitPreviousSummaryForClients({
                  clientEngagementCheckinId: generateRowCheckId,
                })
              )
            : dispatch(getLatestClientSummary());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };

  const handleAcceptClientSummary = () => {
    let body = {
      clientEngagementCheckinId: generateSummaryData?.checkin[0]?.id,
    };
    dispatch(shareByClientSummary(body)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          dispatch(getLatestClientSummary());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        });
      }
    });
  };

  const AcceptByClient = () => {
    return (
      <Button
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleAcceptClientSummary}
        disabled={
          generateSummaryData?.ratings?.length > 0 &&
          generateSummaryData?.checkin[0].isClientApproved
            ? "disabled"
            : null
        }
      >
        {generateSummaryData?.ratings?.length > 0 &&
        generateSummaryData?.checkin[0].isClientApproved
          ? "Accepted Summary"
          : "Accept Summary"}
      </Button>
    );
  };

  const SubmitByHr = () => {
    return (
      <Button
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleShareHrClick}
        disabled={
          generateSummaryData?.checkin[0].isClientApproved &&
          !generateSummaryData?.checkin[0]?.isSharedWithHR
            ? null
            : "disabled"
        }
      >
        {generateSummaryData?.checkin[0].isClientApproved &&
        !generateSummaryData?.checkin[0]?.isSharedWithHR
          ? "Share With HR"
          : "Shared With HR"}
      </Button>
    );
  };

  const SubmitByManager = () => {
    return (
      <Button
        sx={{ marginLeft: "1rem" }}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleShareManagerClick}
        disabled={
          generateSummaryData?.checkin[0].isClientApproved &&
          !generateSummaryData?.checkin[0]?.isSharedWithManager
            ? null
            : "disabled"
        }
      >
        {generateSummaryData?.checkin[0].isClientApproved &&
        !generateSummaryData?.checkin[0]?.isSharedWithManager
          ? "Share With Manager"
          : "Shared With Manager"}
      </Button>
    );
  };

  const handleGenerateClientReports = (id) => {
    const dateStart = DateTime.fromISO(startDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const dateEnd = DateTime.fromISO(endDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const engageDate = DateTime.fromISO(clientName?.engagementStartDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const localIsoStringStart = dateStart.toISO();
    const localIsoStringEnd = dateEnd.toISO();
    const localEngagementDate = engageDate.toISO();

    const body = {
      startDate: localIsoStringStart,
      endDate: localIsoStringEnd,
    };

    if (startDate && endDate) {
      // if (Date.parse(dateStart) >= Date.parse(localEngagementDate)) {
      if (Date.parse(endDate) < Date.parse(startDate)) {
        dispatch(
          dateErrorMessage(
            "Start date should be less than or equal to End date"
          )
        );
        setTimeout(() => {
          dispatch(dateErrorMessage(""));
        }, 2000);
      } else {
        dispatch(dateErrorMessage(""));
        dispatch(getGenerateClientSummary(body)).then((res) => {
          if (res?.payload?.data?.ok) {
            setTimeout(() => {
              dispatch(resetMessage());

              // dispatch(getAssesmentImprovement());
            }, 1000);
          }
          if (res?.payload?.error) {
            setTimeout(() => {
              dispatch(resetErrMessage());
            }, 2000);
          }
        });
      }
      // }
      // else {
      //   dispatch(
      //     dateErrorMessage(
      //       "Start date should greater than or equal to  engagement start date"
      //     )
      //   );
      //   setTimeout(() => {
      //     dispatch(dateErrorMessage(""));
      //   }, 2000);
      // }
    } else {
      dispatch(dateErrorMessage("Please enter date"));
      setTimeout(() => {
        dispatch(dateErrorMessage(""));
      }, 2000);
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (
      !endDate &&
      Object.keys(generateSummaryData).length > 0 &&
      generateSummaryData?.checkin[0]?.ratingFromDate
    ) {
      setStartDate(
        generateSummaryData && generateSummaryData?.checkin[0]?.ratingFromDate
          ? format(
              new Date(
                generateSummaryData &&
                  generateSummaryData?.checkin[0]?.ratingFromDate
              ),
              "yyyy-MM-dd"
            )
          : ""
      );
    } else if (!endDate && clientName?.engagementStartDate) {
      setStartDate(
        clientName?.engagementStartDate
          ? format(new Date(clientName?.engagementStartDate), "yyyy-MM-dd")
          : ""
      );
    }

    if (!endDate && Object.keys(generateSummaryData).length > 0) {
      setEndDate(
        generateSummaryData && generateSummaryData?.checkin[0]?.ratingToDate
          ? format(
              new Date(
                generateSummaryData.checkin[0]?.ratingToDate &&
                  generateSummaryData?.checkin[0]?.ratingToDate
              ),
              "yyyy-MM-dd"
            )
          : ""
      );
    }
  }, [generateSummaryData]);

  const handleStartChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndChange = (e) => {
    setEndDate(e.target.value);
  };

  useEffect(() => {
    setStartDate("");
    setEndDate("");
    dispatch(emptyingArry());
    dispatch(getLatestClientSummary());
    dispatch(getFullEnagegementsReports());
    dispatch(getToogleData());
    dispatch(getLatestSummaryDocuments());
  }, []);

  return (
    //
    <Layout isClient={true}>
      <Grid mt={4} container>
        <Grid item xs={12} sm={12} md={12}>
          <Attachments
            hideFooter={true}
            disableAdd={true}
            engagementsDocumentsData={[{
              id: 1,
              documentName: "Past Impact Update for Leader",
              fileName: "Past Impact update for client.xlsx"
            }]}
            createDocument={createEngagementDocuments}
            getDocuments={getEngagementDocuments}
          />
        </Grid>
        <Grid mt={2} item xs={12} sm={12} md={4} style={{marginTop:"30px"}}>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
              fontFamily: "dinBold,sans-serif",
              marginTop: "0.7rem",
            }}
            gutterBottom
            variant="h9"
            component="div"
            // className={classes.desk_width}
          >
            Impact Summary
          </Typography>
        </Grid>

      <Grid item xs={12} sm={12} md={8} style={{marginTop:"30px"}}>
        <Grid container >
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              sx={{ width: "100%" }}
              inputProps={{
                min:
                  clientName?.engagementStartDate &&
                  format(
                    new Date(clientName?.engagementStartDate),
                    "yyyy-MM-dd"
                  ),
              }}
              type="date"
              name="startDate"
              id="summary-start-date"
              required={true}
              value={startDate}
              onChange={handleStartChange}
              // sx={{width:"9rem"}}
            />
         </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",

              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={12}
            sm={12}
            md={1}
          >
            <Typography
              sx={{ fontSize: "1rem", paddingLeft: "0.2rem" }}
              gutterBottom
              variant="h9"
              component="div"
            >
              To
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextField
              sx={{ paddingLeft: "0.2rem", width: "100%" }}
              type="date"
              name="endDate"
              id="summary-end-date"
              required={true}
              value={endDate}
              onChange={handleEndChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} sx={{ mt: "8px",marginLeft:"2rem" }}>
            <FormSubmitButton
              sx={{
                textTransform: "none",
                marginLeft: "0.5rem",
                justifyContent: "center",
              }}
              type="submit"
              variant="contained"
              size="large"
              onClick={handleGenerateClientReports}
            >
              Generate
            </FormSubmitButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

      <Grid mt={4} container>
        <Grid item xs={12} sm={12} md={4}>
          <Typography
            sx={{
              fontSize: "20px",
              // fontWeight: "700",
              fontFamily: "sans-serif",
              // marginTop: "0.7rem",
            }}
            gutterBottom
            variant="h9"
            component="div"
            // className={classes.desk_width}
          >
            Engagement Start Date : 
          </Typography>
        </Grid>
     </Grid>

      <HowToUse
          HowToUseContent={HowToUseContent}
        />

      {
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              {
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <PageHeader variant="pageSecHeader">
                    {" "}
                    Level Of Engagement
                  </PageHeader>
                </Stack>
              }
              <Stack sx={{}}>{<MuiDataGridForSummary data={dataTwo} />}</Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              {
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <PageHeader variant="pageSecHeader">
                    Engagement Notes
                  </PageHeader>
                </Stack>
              }
              <Stack sx={{}}>
                {
                  <MuiDataGridForSummary
                    // submitCheck={submitCheck}
                    // setShowEdit={showUpdateAddModel}
                    // select={(dataThree) => dispatch(rowSelect(dataThree))}
                    // generateTwoRowId={generateTwoRowId}
                    data={dataThree}
                  />
                }
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      }

      <Grid sx={{ mt: "1rem" }} container spacing={2}>
        {clientToggleData?.length > 0 &&
          clientToggleData[0]?.dateOfLastMeeting && (
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                gutterBottom
                variant="h9"
                component="div"
              >
                Date of Last Meeting:
                <span style={{ fontWeight: 400, paddingLeft: "0.6rem" }}>
                  {Object.keys(generateSummaryData).length > 0 &&
                  generateSummaryData.checkin[0]?.dateOfLastMeeting
                    ? format(
                        new Date(
                          generateSummaryData.checkin[0]?.dateOfLastMeeting
                        ),
                        "MM-dd-yyyy"
                      )
                    : ""}
                </span>
              </Typography>
            </Grid>
          )}

        {clientToggleData?.length > 0 &&
          clientToggleData[0]?.dateOfLastHrTouchpoint && (
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                gutterBottom
                variant="h9"
                component="div"
              >
                Date of Last HR Touch Point:
                <span style={{ fontWeight: 400, paddingLeft: "0.8rem" }}>
                  {Object.keys(generateSummaryData).length > 0 &&
                  generateSummaryData.checkin[0]?.dateOfLastHrTouchPoint
                    ? format(
                        new Date(
                          generateSummaryData.checkin[0]?.dateOfLastHrTouchPoint
                        ),
                        "MM-dd-yyyy"
                      )
                    : ""}
                </span>
              </Typography>
            </Grid>
          )}

        {clientToggleData?.length > 0 &&
          clientToggleData[0]?.dateOfLastManagerTouchpoint && (
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                gutterBottom
                variant="h9"
                component="div"
              >
                Date of Last Manager Touch Point:
                <span style={{ fontWeight: 400, paddingLeft: "0.6rem" }}>
                  {Object.keys(generateSummaryData).length > 0 &&
                  generateSummaryData.checkin[0]?.dateOfLastManagerTouchPoint
                    ? format(
                        new Date(
                          generateSummaryData.checkin[0]?.dateOfLastManagerTouchPoint
                        ),
                        "MM-dd-yyyy"
                      )
                    : ""}
                </span>
              </Typography>
            </Grid>
          )}
      </Grid>

      {
        <Stack
          sx={{
            // display: "flex",
            // flexDirection: "row",
            // justifyContent: "space-between",
            mt: "2rem",
          }}
        >
          <Grid container>
            <Grid md={6}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontFamily: "arial",
                }}
              >
                <Typography
                  sx={{ fontSize: "1.125rem", fontWeight: "bold" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  Engagement Key
                </Typography>
                <Typography
                  sx={{ fontSize: "0.90rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Green
                  </span>{" "}
                  Highly engaged with coach/set meetings regulary/leaning in.
                </Typography>
                <Typography
                  sx={{ fontSize: "0.90rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Yellow
                  </span>{" "}
                  Engaged when meeting but challenging to schedule with.
                </Typography>
                <Typography
                  sx={{ fontSize: "0.90rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Red
                  </span>{" "}
                  Goes long period without meeting with coach/not engaged.
                </Typography>
              </Stack>
            </Grid>

            {clientToggleData?.length > 0 &&
              clientToggleData[0]?.progressRatingScale && (
                <Grid container item md={6}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "1.125rem",
                        fontWeight: "bold",
                        fontWeight: "bold",
                      }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      Progress Scale Rating
                    </Typography>
                  </Grid>

                  <Grid item md={4} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        0
                      </span>{" "}
                      Baseline - No progress
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        2
                      </span>{" "}
                      Significant Improvement
                    </Typography>
                  </Grid>

                  <Grid item md={4} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        1
                      </span>{" "}
                      Work in Progress
                    </Typography>
                  </Grid>

                  <Grid item md={8} sm={12} xs={12}>
                    <Typography
                      sx={{ fontSize: "0.90rem" }}
                      gutterBottom
                      variant="h9"
                      component="div"
                    >
                      <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        3
                      </span>{" "}
                      Completed or Maintaining Successful Execution
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Stack>
      }
      {generateSummaryData?.ratings?.length > 0 && (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Stack sx={{ marginTop: "1.5rem" }}>
            <PageHeader variant="pageHeader">Impact of Coaching</PageHeader>
          </Stack>
        </Stack>
      )}
      {generateSummaryData?.ratings?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            "& > :not(style)": {
              // width: 450,
              minHeight: 200,
            },
          }}
        >
          <Grid spacing={2} container>
            {clientToggleData?.length > 0 &&
              clientToggleData[0]?.progressRatingScale && (
                <Grid item xs={12} sm={12} md={6}>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {isMappingGrpah()}
                  </Stack>
                </Grid>
              )}

            <Grid item xs={12} sm={12} md={6}>
              {generateSummaryData &&
                generateSummaryData?.ratings?.map((data, index) => {
                  return (
                    <Stack>
                      {clientToggleData?.length > 0 &&
                        !clientToggleData[0]?.progressRatingScale && (
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              marginTop: "1rem",
                              fontWeight: "bold",
                              marginLeft: "1rem",
                            }}
                          >
                            {data.otherDevelopmentAreaCoachingFocus}
                          </Typography>
                        )}

                      <Paper
                        key={data.id}
                        sx={{
                          height: 215,
                          marginTop:
                            index === 0 ||
                            (clientToggleData?.length > 0 &&
                              !clientToggleData[0]?.progressRatingScale)
                              ? "1.5rem"
                              : "3.4rem",
                          overflow: "auto",
                        }}
                        elevation={3}
                      >
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                          gutterBottom
                          variant="h9"
                          component="div"
                        >
                          Comments
                        </Typography>

                        <Stack
                          sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                        >
                          {commentId != data.id && (
                            <Stack sx={{ paddingBottom: "1rem" }}>
                              <Accordion sx={{ paddingTop: "1rem" }}>
                                <AccordionDetails>
                                  <Typography>
                                    {data.mentorToClientRating?.length > 0 &&
                                      data.mentorToClientRating?.at(0).comment}
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                              <Typography sx={{ mt: "0.5rem",fontWeight:"bold" }}>
                                {data.mentorToClientRating.length > 0 &&
                                  format(
                                    new Date(
                                      data.mentorToClientRating.at(0).updatedOn
                                    ),
                                    "MM-dd-yyyy"
                                  )}
                              </Typography>
                            </Stack>
                          )}
                          {commentId != data.id &&
                            data.mentorToClientRating.length > 1 && (
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-end",
                                  marginTop: "0.5rem",
                                  paddingBottom: "1rem",
                                }}
                              >
                                <Button
                                  onClick={() => handlViewMoreClick(data.id)}
                                  size="small"
                                  variant="contained"
                                >
                                  View More
                                </Button>
                              </Stack>
                            )}
                          {commentId === data.id &&
                            data.mentorToClientRating.length > 0 &&
                            data.mentorToClientRating.map((dataTwo) => {
                              return (
                                <Stack>
                                  <Accordion sx={{ paddingTop: "1rem",fontWeight:"bold" }}>
                                    <AccordionDetails>
                                      <Typography>
                                        {dataTwo?.comment}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>

                                  <Typography>
                                    {format(
                                      new Date(dataTwo?.updatedOn),
                                      "MM-dd-yyyy"
                                    )}
                                  </Typography>
                                </Stack>
                              );
                            })}
                        </Stack>
                      </Paper>
                    </Stack>
                  );
                })}
            </Grid>
          </Grid>
        </Box>
      )}
      {/* {clientName && (
    

      
    )} */}

      <Stack sx={{ marginTop: "2rem" }}>
        {
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <PageHeader variant="pageSecHeader">
              Coaching Summary – Additional Commentary
            </PageHeader>
          </Stack>
        }

        {<MuiDataGridForSummary data={dataOne} />}
      </Stack>

      {errMessage && <ErrorNotifier message={errMessage} />}
      {dateErrorMessageData && <ErrorNotifier message={dateErrorMessageData} />}

      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default Reports;
