import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import { MyTextInput, PageHeader } from "@excoleadershipui/pionweblib";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import {
  activateAccount,
  resetMessage,
  resetErrMessage,
} from "../features/auth/authSlice";

// material ui
import {
  Typography,
  Container,
  Box,
  Avatar,
  Grid,
  Link,
  Paper,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import logo from "../assets/images/exco-nav-logo.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// components
import { FormSubmitButton } from "../components";
import Notifier from "./common/Notifier";
import ErrorNotifier from "./common/ErrorNotifier";

// redux

// -----------------------------------------------------

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.excoleadership.com/">
        excoleadership
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

//   -----------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  btnSubmit: {
    marginTop: "1rem !important",
  },
}));

// ---------------------------------------------------------------------------------------------

const validationSchema = Yup.object({
  newPassword: Yup.string().required("New Password is required")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  secret: Yup.string().required("Secret is required"),
});

// ---------------------------------------------------------------------------------------------

const ActivateAccount = (props) => {
  let navigate = useNavigate();

  const classes = useStyles();

  const { data, loading, error,message, errMessage } = useSelector((state) => ({ ...state.auth }));
  console.log( errMessage)

  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const result1 = queryParams.get("necpea");
  const queryParamsTwo = new URLSearchParams(window.location.search);
  const result2 = queryParamsTwo.get("necpucae");

  console.log(props.ConfirmAccount);
  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 4 }}>
        <PageHeader sx={{ marginTop: 4 }}>Activate Account</PageHeader>
      </Box>

      <Paper
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          position: "relative",
        }}
        elevation={2}
      >
        <img alt="logo" src={logo} width="80rem" height="40rem" />

        {/* <Typography variant="pageHeader">Activate Account</Typography> */}
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            secret: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={(values) => {
            console.log(values);
            let body = {
              necpea: result1,
              necpucae: result2,
              secret: values.secret.toString(),
              newPassword: values.newPassword,
              confirmPassword: values.confirmPassword,
            };

            dispatch(activateAccount(body))
              .then((res) => {
                if (res.payload.data.ok) {
                 setTimeout(() => {
                    dispatch(resetMessage());
                    navigate("/", { replace: true })
                  }, 3000);
                }
              })
              .catch(() => {
                setTimeout(() => {
                  dispatch(resetErrMessage());
                }, 3000);
              });

            // dispatch(activateAccount(body));
            // same shape as initial values
          }}
        >
          {({ errors, touched, handleSubmit, handleChange }) => (
            <form onSubmit={handleSubmit} noValidate>
              <MyTextInput
                label="Secret"
                type="number"
                name="secret"
                required={true}
                fullWidth={true}
                margin="normal"
                id="newPassword"
                onChange={handleChange("secret")}
                error={touched.secret && Boolean(errors.secret)}
                helperText={touched.secret && errors.secret}
              />
              <MyTextInput
                label="New Password"
                type="password"
                name="newPassword"
                required={true}
                fullWidth={true}
                margin="normal"
                id="newPassword"
                onChange={handleChange("newPassword")}
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
              <MyTextInput
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                required={true}
                fullWidth={true}
                margin="normal"
                id="confirmPassword"
                onChange={handleChange("confirmPassword")}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
              />

              <FormSubmitButton
                type="submit"
                fullWidth={true}
                variant="contained"
                // onClick={handleSubmit}
                className={classes.btnSubmit}
              >
                Activate Account
              </FormSubmitButton>
            </form>
          )}
        </Formik>

        {/* </Box> */}
      </Paper>
      <Copyright sx={{ mt: 8, mb: 4 }} />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  ConfirmAccount: state.ConfirmAccount,
});

export default connect(mapStateToProps)(ActivateAccount);
