import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  GET_REPORTS_AREA_FOCUS, GET_REPORTS_ENGAGEMENT_STATUS,GET_ENGAGEMENT_DETAIL,GET_RATING_BY_CLIENT, CHR_RATING_BY_CLIENT,CHR_LATEST_OUTCOMES
  ,CHR_PREVIOUS_OUTCOMES_DATA,CHR_PREVIOUS_OUTCOMES_DATA_BY_ID 
} from "../../../utils/routes";

// ---------------------------------------------------------------------



// export const getRatingByclientId = createAsyncThunk(
//   "rating/getRatingForClientsCCHR",
//   async () => {
//     return axios.get(CHR_RATING_BY_CLIENT, { withCredentials: true });
//   }
// );

export const  getRatingByclientId = createAsyncThunk(
  "rating/getRatingForClientsCCHR",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId:data
    }
    try {
      const response = await axios.post(CHR_RATING_BY_CLIENT,body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const getEngagementNotes = createAsyncThunk(
  "reports/getEngagement",
  async () => {
    return axios.get( GET_REPORTS_ENGAGEMENT_STATUS, { withCredentials: true });
  }
);

// export const getLatestOutcomesForCCHR = createAsyncThunk(
//   "outcomes/getLatestOutcomes",
//   async (data) => {
//     let body = {
//       excoCustomerUserId:data
//     }
//     return axios.post(CHR_LATEST_OUTCOMES,body, { withCredentials: true });
//   }
// );

export const getPreviousOutcomeForCCHR = createAsyncThunk(
  "outcomes/getPreviousCCHR",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId:data
    }
    try {
      const response = await axios.post(CHR_PREVIOUS_OUTCOMES_DATA,body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



export const getLatestOutcomesForCCHR = createAsyncThunk(
  "outcomes/getLatestOutcomesForCCHR",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId:data
    }
    try {
      const response = await axios.post(CHR_LATEST_OUTCOMES,body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitPreviousOutcomesForCCHR = createAsyncThunk(
  "outcomes/hrSumbitPrevious",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CHR_PREVIOUS_OUTCOMES_DATA_BY_ID , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);




export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const  engagementSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    customErr:"",
    areaFocusData: [],
    engagementFocusData:[],
    previousOutcomeDataForCCHR:[],
    clientRatingData:[],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
 
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    emptyingArray:(state,action) =>{
      state.clientRatingData = []
      },
  },
  extraReducers: {
   
    [getRatingByclientId.pending] : (state, action) => {
      state.loading = true;
    },
    [getRatingByclientId.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.clientRatingData = action?.payload?.data;
    },
    [getRatingByclientId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    }, 

    [submitPreviousOutcomesForCCHR.pending] : (state, action) => {
      state.loading = true;
    },
    [submitPreviousOutcomesForCCHR.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.clientRatingData = action?.payload?.data;
    },
    [submitPreviousOutcomesForCCHR.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    }, 

    [getPreviousOutcomeForCCHR.pending] : (state, action) => {
      state.loading = true;
    },
    [getPreviousOutcomeForCCHR.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.previousOutcomeDataForCCHR = action?.payload?.data;
    },
    [getPreviousOutcomeForCCHR.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [getLatestOutcomesForCCHR.pending] : (state, action) => {
      state.loading = true;
    },
    [getLatestOutcomesForCCHR.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.clientRatingData = action?.payload?.data;
    },
    [getLatestOutcomesForCCHR.rejected]: (state, action) => {
      console.log(action?.payload,"comming")
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getEngagementNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementNotes.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.engagementFocusData = action?.payload?.data;
    },
    [getEngagementNotes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage, rowSelect, emptyingArray } = engagementSlice.actions;
export default  engagementSlice.reducer;


