import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  GET_ASSESMENT_IMPROVEMENT,
  GET_CLIENT_IMPROVEMENT ,
  UPDATE_PRIVATE_LEADERSHIP_COACHING_FOCUS,
  GET_ENGAGEMENT_DETAIL,
  APPROVE_CLIENT_IMPROVEMENT,
  APPROVE_ASSESSMENT_IMPROVEMENT,
  UPDATE_PRIVATE_LEADERSHIP_STRENGTH,
  COACHING_SHARE_WITH_HR,
COACHING_SHARE_WITH_MANAGER,
GET_APPROVE_COACHING__BUTTON_HR,
GET_APPROVE_COACHING_BUTTON_MANAGER,
GET_CLIENT_COACHING_PURPOSE,

UPDATE_PRIVATE_FOCUSAREA_MULTI_SELECT,
UPDATE_PUBLIC_FOCUSAREA_MULTI_SELECT,
GET_UPLOADED_DOCUMENTS_COACHING_FOCUS,
UPDATE_APPROVE_FOCUSAREA_MULTI_SELECT

} from "../../utils/routes";

// ---------------------------------------------------------------------



export const getClientFocus= createAsyncThunk(
  "clientFocus/getFocus",
  async () => {
    return axios.get(GET_CLIENT_IMPROVEMENT , { withCredentials: true });
  }
);

export const getClientFocusPurpose= createAsyncThunk(
  "clientFocus/getFocusPurpose",
  async () => {
    return axios.get(GET_CLIENT_COACHING_PURPOSE , { withCredentials: true });
  }
);


export const getCoachingHrButton = createAsyncThunk(
  "coaching/hrButton",
  async () => {
    return axios.get(GET_APPROVE_COACHING__BUTTON_HR, { withCredentials: true });
  }
);


export const getCoachingManagerButton = createAsyncThunk(
  "coaching/managerButton",
  async () => {
    return axios.get(GET_APPROVE_COACHING_BUTTON_MANAGER, { withCredentials: true });
  }
);

// export const getClientFocusApprove = createAsyncThunk(
//   "client/approvedClient",
//   async () => {
//     return axios.get( GET_ASSESMENT_IMPROVEMENT, { withCredentials: true });
//   }
// );


export const updateClientFocusApprove = createAsyncThunk(
  "clientFocus/approvedClient",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(APPROVE_CLIENT_IMPROVEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateApproveImprovement = createAsyncThunk(
  "assessment/approveImprovent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( APPROVE_ASSESSMENT_IMPROVEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePrivateCoachingFocus = createAsyncThunk(
  "leadership/updateCoachingFocus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( UPDATE_PRIVATE_LEADERSHIP_COACHING_FOCUS, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCoachingFocus = createAsyncThunk(
  "leadership/updateFocusArea",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_FOCUS_AREA, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const coachingShareWithHr = createAsyncThunk(
  "leadership/CoachingShareWithHr",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(COACHING_SHARE_WITH_HR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const coachingShareWithManager = createAsyncThunk(
  "leadership/coachingShareWithManager",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(COACHING_SHARE_WITH_MANAGER, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updateCoachingMultiSelectPublic = createAsyncThunk(
  "focus-area/updateCoachingMultiSelectPublic",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PUBLIC_FOCUSAREA_MULTI_SELECT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCoachingMultiSelectPrivate = createAsyncThunk(
  "focus-area/updateCoachingMultiSelectPrivate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PRIVATE_FOCUSAREA_MULTI_SELECT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCoachingMultiSelectApprove= createAsyncThunk(
  "focus-area/updateCoachingMultiSelectApprove",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_APPROVE_FOCUSAREA_MULTI_SELECT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getClientCoachingDocuments = createAsyncThunk(
  "coaching/getClientCoachingDocuments",
  async (data) => {
    return axios.get(GET_UPLOADED_DOCUMENTS_COACHING_FOCUS, { withCredentials: true });
  }
);

const  engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    privatePublicLoading : false,
    approvedLoading : false,
    message: "",
    errMessage: "",
    clientFocusData: [],
    clientFocusDocumentsData: [],
    clientApproveData:[],
    clientFocusPurposeData:[],
    coachingHrButtonData:{},
    coachingManagerButtonData:{},
    selectedData: {},
    focusAreaMultiData:[]

  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },

    coahcingRowFunc : (state,action) =>{
      state.focusAreaMultiData = action?.payload.length > 0 && action?.payload
    },
  },
  extraReducers: {
   
    [ getClientFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [ getClientFocus.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.clientFocusData = action?.payload?.data;
    },
    [ getClientFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [ getClientCoachingDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [ getClientCoachingDocuments.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.clientFocusDocumentsData = action?.payload?.data;
    },
    [ getClientCoachingDocuments.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [updateCoachingMultiSelectPublic.pending]: (state, action) => {
      state.privatePublicLoading = true;
    },
    [updateCoachingMultiSelectPublic.fulfilled]: (state, action) => {
      state.privatePublicLoading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateCoachingMultiSelectPublic.rejected]: (state, action) => {
      state.privatePublicLoading = false;
      state.errMessage = action?.payload?.message;
    },

    [updateCoachingMultiSelectPrivate.pending]: (state, action) => {
      state.privatePublicLoading = true;
    },
    [updateCoachingMultiSelectPrivate.fulfilled]: (state, action) => {
      state.privatePublicLoading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateCoachingMultiSelectPrivate.rejected]: (state, action) => {
      state.privatePublicLoading = false;
      state.errMessage = action?.payload?.message;
    },

    [updateCoachingMultiSelectApprove.pending]: (state, action) => {
      state.privatePublicLoading = true;
    },
    [updateCoachingMultiSelectApprove.fulfilled]: (state, action) => {
      state.privatePublicLoading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateCoachingMultiSelectApprove.rejected]: (state, action) => {
      state.privatePublicLoading = false;
      state.errMessage = action?.payload?.message;
    },



    [ getClientFocusPurpose.pending]: (state, action) => {
      state.loading = true;
    },
    [ getClientFocusPurpose.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.clientFocusPurposeData = action?.payload?.data;
    },
    [ getClientFocusPurpose.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [ getCoachingHrButton.pending]: (state, action) => {
      state.loading = true;
    },
    [getCoachingHrButton.fulfilled]: (state, action) => {
    
      state.loading = false;
      state.coachingHrButtonData = action?.payload?.data;
    },
    [getCoachingHrButton.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getCoachingManagerButton.pending]: (state, action) => {
      state.loading = true;
    },
    [getCoachingManagerButton.fulfilled]: (state, action) => {
      
      state.loading = false;
      state.coachingManagerButtonData = action?.payload?.data;
    },
    [getCoachingManagerButton.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [updateCoachingFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [updateCoachingFocus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateCoachingFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  
    [updateClientFocusApprove.pending]: (state, action) => {
      state.approvedLoading  = true;
    },
    [updateClientFocusApprove.fulfilled]: (state, action) => {
      state.approvedLoading  = false;
      state.message = action?.payload?.data?.message;
    },
    [updateClientFocusApprove.rejected]: (state, action) => {
      state.approvedLoading  = false;
      state.errMessage = action?.payload?.message;
    },
    [updateApproveImprovement.pending]: (state, action) => {
      state.loading = true;
    },
    [updateApproveImprovement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateApproveImprovement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updatePrivateCoachingFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePrivateCoachingFocus.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updatePrivateCoachingFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [coachingShareWithHr.pending]: (state, action) => {
      state.loading = true;
    },
    [coachingShareWithHr.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [coachingShareWithHr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [coachingShareWithManager.pending]: (state, action) => {
      state.loading = true;
    },
    [coachingShareWithManager.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [coachingShareWithManager.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage, rowSelect,coahcingRowFunc } = engagementSlice.actions;
export default  engagementSlice.reducer;


