import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  
  GET_REPORTS_AREA_FOCUS,
  GET_MANAGER_FOCUS_BY_ID ,
  GET_ALL_CLIENTS_MANAGER,
  GET_REPORTS_ENGAGEMENT_STATUS,
  GET_MANAGER_DASHBOARD_LATEST_ENGAGEMENT,
  GET_MANAGER_DASHBOARD_KEY_ENGAGEMENT,
  GET_ENGAGEMENT_DETAIL} from "../../../utils/routes";

// ---------------------------------------------------------------------


export const getClientForManager = createAsyncThunk(
  "dashboard/getManagerClient",
  async () => {
    return axios.get(GET_ALL_CLIENTS_MANAGER, { withCredentials: true });
  }
);

export const getManagerKeyEngagement = createAsyncThunk(
  "dashboard/keyEngagementDetailManager",
  async (data) => {
    let body = {
      excoCustomerUserId:data
    }
    return axios.post(GET_MANAGER_DASHBOARD_KEY_ENGAGEMENT,body, { withCredentials: true });
  }
);

export const getManagerPastActiveEngagement = createAsyncThunk(
  "dashboard/pastEngagement",
  async (data) => {

    return axios.get(GET_MANAGER_DASHBOARD_LATEST_ENGAGEMENT, { withCredentials: true });
  }
);


export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const  engagementSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    getAllClientData:[],
    areaFocusData: [],
    engagementFocusData:[],
    keyClientAllEngagementData:[],
    activeEngagementData:[],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
   
    [getClientForManager.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientForManager.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [getClientForManager.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getManagerKeyEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [getManagerKeyEngagement.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.keyClientAllEngagementData = action?.payload?.data;
    },
    [getManagerKeyEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getManagerPastActiveEngagement.pending]: (state, action) => {
      state.loading = true;
    },
    [getManagerPastActiveEngagement.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.activeEngagementData = action?.payload?.data;
    },
    [getManagerPastActiveEngagement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    // [getManagerPastActiveEngagement.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [getManagerCoachingFocusById.fulfilled]: (state, action) => {
    //   console.log(action?.payload?.data)
    //   state.loading = false;
    //   state.areaFocusData = action?.payload?.data;
    // },
    // [getManagerCoachingFocusById.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errMessage = action?.payload?.data?.message;
    // },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage, rowSelect } = engagementSlice.actions;
export default  engagementSlice.reducer;


