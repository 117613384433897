import React, { useState, useEffect } from "react";
// 
import PrivateFeedback from "../../private_feedback/PrivateFeedback"
import { useSelector, useDispatch } from "react-redux";
import { getAllFeedbackByChr, createChrPrivateFeedback, updateChrPrivateFeedback, deleteChrPrivateFeedback, rowSelect } from "../../../features/privateFeedbackSlice";

const ChrFeedback = () => {



    return (
        <>

            <PrivateFeedback
                getAllFeedback={getAllFeedbackByChr}
                createFeedback={createChrPrivateFeedback}
                updateFeedback={updateChrPrivateFeedback}
                deleteFeedback={deleteChrPrivateFeedback}
                rowSelect={rowSelect}
            />
        </>
    );
};

export default ChrFeedback;
