import React, { useState, useEffect } from "react";
// components
import Layout from "../../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import { MuiDataGrid } from "../../../components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { PageHeader } from "@excoleadershipui/pionweblib";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { MuiAutocomplete } from "../../../components";
import { GridCellExpand } from "../../../components";

import {
  getEnagagementScopeDetails,
  getEngagementStage,
} from "../../../features/hr/engagement/engagementSlice";

import { getClientForHr } from "../../../features/hr/assesment/hrAssesmentSlice";
import { Grid } from "@mui/material";

const steps = [
  "Scoping",
  "Matching",
  "Chemistry",
  "LA Prep",
  "LA",
  "Immediate Follow-Up",
  "LA Debrief",
  "Ongoing Coaching",
  "Limited Interviews",
  "Renewal",
  "Close Out",
];
const useStyles = makeStyles((theme) => ({
  responsive_header_width: {
    [theme.breakpoints.down("md")]: {
      width: "19.5rem !important",
    },
  },
}));
const Assesment = ({ theme }) => {
  const [accountName, setAccountName] = useState("");
  const [clientName, setClientName] = useState("");
  const [allValue, setAllValue] = useState("");
  const [holder, setHolder] = useState("");
  const [isViewShow, setIsViewShow] = useState(false);
  const dispatch = useDispatch();

  const { getAllClientData } = useSelector((state) => ({
    ...state.HrClientLeadership,
  }));

  const { engagementsScopeData, message, errMessage } = useSelector(
    (state) => ({ ...state.hrEngagement })
  );
  console.log(theme);

  const handleChange = (e) => {
    let clinetId = e.target.value.split(",")[0];
    let account = e.target.value.split(",")[1];
    let holderName = e.target.value.split(",")[2];
    setAllValue(e.target.value);
    console.log(clinetId);
    console.log(account);
    setClientName(clinetId);
    setHolder(holderName);
    setAccountName(account);
    dispatch(getEnagagementScopeDetails(clinetId));

    // dispatch(getHrLeadershipImprovement(clinetId));
  };

  const matchingDate = (params) => {
    return (
      params?.row?.engagementDate &&
      format(new Date(params?.row?.engagementDate), "MM-dd-yyyy")
    );
  };

  //  const chemistryValue = (params) =>{
  //   console.log(params.row)
  //   let result =  params?.row?.clientEngagement?.KeyClientEngagement?.length > 0 &&
  //  params?.row?.clientEngagement?.KeyClientEngagement?.map((data)=>{
  //   if(data?.engagementStageName === "Chemistry"){
  //    return data.engagementDate
  //     }
  //   })
  //   console.log(result)
  //  let finalResult = result.length > 0 && result.filter((data)=>data)
  //  return finalResult?.length > 0 ?  format(
  //     new Date(finalResult[0]),
  //     "dd-MM-yyyy"
  //   ):"_"
  // }

  // const accelaratorValue = (params) =>{
  //   let result =  params?.row?.clientEngagement?.KeyClientEngagement?.length > 0 &&
  //   params?.row?.clientEngagement?.KeyClientEngagement?.map((data)=>{
  //    if(data?.engagementStageName === "Accelerator"){
  //     return data.engagementDate
  //      }
  //    })
  //    console.log(result)
  //   let finalResult = result.length > 0 && result.filter((data)=>data)
  //   return finalResult?.length > 0 ?  format(
  //      new Date(finalResult[0]),
  //      "dd-MM-yyyy"
  //    ):"_"
  // }

  const data = {
    rows: [],
    columns: [
      { field: "col1", headerName: "Scope", width: 300 },
      { field: "col2", headerName: "Deliverable", width: 300 },
      { field: "col3", headerName: "Date", width: 300 },
    ],
  };

  console.log(engagementsScopeData);

  function renderCellExpand(params) {
    console.log(params);
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const dataTwo = {
    rows: engagementsScopeData,
    columns: [
      {
        field: "engagementStageName",
        headerName: "Engagement Name",
        width: 200,
      },
      {
        field: "engagementDate",
        headerName: "Engagement Date",
        width: 200,
        valueGetter: matchingDate,
      },
      { field: "status", headerName: "Status", width: 200 },
      {
        field: "comments",
        headerName: "Comments",
        width: 200,
        renderCell: renderCellExpand,
      },
    ],
  };

  const latestStage = [];
  engagementsScopeData.length > 0 &&
    engagementsScopeData.map((eng) => {
      latestStage.push(eng);
    });
  latestStage.reverse();

  // const dataThree={
  //   rows:leadershipImprovementData,
  //   columns: [
  //     { field: "core", headerName: "Area of Improvements", width: 300 },
  //     { field: "detailedDesc", headerName: "Description", width: 300 },
  // ],
  // }

  useEffect(() => {
    clientName &&
      dispatch(getEnagagementScopeDetails(clientName?.excoCustomerUser?.id));
    clientName &&
      dispatch(getEngagementStage(clientName?.excoCustomerUser?.id));
  }, [clientName]);

  useEffect(() => {
    dispatch(getClientForHr());
  }, []);
  const classes = useStyles();
  return (
    <Layout>
      <Stack sx={{ marginTop: "0.5rem" }}>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: "1rem",
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid mt="0.2rem" item xs={11} sm={11} md={4}>
              <PageHeader variant="pageHeader">
                {" "}
                Engagement Progress for{" "}
              </PageHeader>
            </Grid>
            <Grid item xs={11} sm={11} md={3}>
              <Stack sx={{ width: "13rem" }}>
                <MuiAutocomplete
                  name="allValue"
                  options={getAllClientData.length > 0 ? getAllClientData : []}
                  setValue={(name, newValue) => setClientName(newValue)}
                  value={clientName}
                  // optionValue="firstName"
                  innerLevel={true}
                  // label="Customer Account Name"
                />
              </Stack>
            </Grid>
            {clientName &&
              engagementsScopeData?.length > 0 &&
              engagementsScopeData[0]?.excoCustomerUser?.clientMentorMapping
                ?.excoMentor?.excoMentorDetail?.firstName && (
                <Grid item xs={11} sm={11} md={5}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        fontWeight: 700,
                        width: "7rem",
                        fontSize: "1.5rem",
                        fontFamily: "dinBold,sans-sarif",
                      }}
                    >
                      Mentor :
                    </span>{" "}
                    <div>
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          whiteSpace: "wrap",
                          marginTop: "0.5rem",
                        }}
                        gutterBottom
                        variant="h9"
                        component="div"
                      >
                        {engagementsScopeData?.length &&
                          engagementsScopeData[0]?.excoCustomerUser
                            ?.clientMentorMapping?.excoMentor?.excoMentorDetail
                            ?.firstName}{" "}
                        {engagementsScopeData?.length &&
                          engagementsScopeData[0]?.excoCustomerUser
                            ?.clientMentorMapping?.excoMentor?.excoMentorDetail
                            ?.lastName}
                        {""}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "1rem",
                          whiteSpace: "wrap",
                        }}
                        gutterBottom
                        variant="h9"
                        component="div"
                      >
                        {engagementsScopeData?.length &&
                          engagementsScopeData[0]?.excoCustomerUser
                            ?.clientMentorMapping?.excoMentor?.email}
                      </Typography>
                    </div>
                  </div>
                  {/* <Typography
                  sx={{ fontSize: ".75rem" }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  <span style={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Mentor Email :
                  </span>{" "}
                  {engagementsScopeData?.length &&
                    engagementsScopeData[0]?.excoCustomerUser
                      ?.clientMentorMapping?.excoMentor?.email}
                </Typography> */}
                </Grid>
              )}
          </Grid>
        </Stack>
      </Stack>
      {clientName && (
        <div
          style={{
            "& .super-app-theme": {
              "&:nth-of-type(odd)": {
                bgcolor: (theme) => theme.palette.primary.light,
              },
            },
          }}
        >
          {latestStage.length > 0 && (
            <Stepper
              className={classes.responsive_header_width}
              nonLinear
              sx={{
                display: "flex",
                whiteSpace: "nowrap",
                overflow: latestStage.length > 7 && "scroll",
                width:
                  (latestStage.length === 1 && "20rem") ||
                  (latestStage.length === 2 && "20rem") ||
                  (latestStage.length === 3 && "30rem") ||
                  (latestStage.length === 4 && "40rem") ||
                  (latestStage.length > 4 && "59rem"),
                scrollbarWidth: "none",
                height: "2rem",
                overflowY: "hidden",
              }}
            >
              {latestStage.length > 0 &&
                latestStage.map((label, index) => (
                  <Step
                    sx={{
                      "& .MuiStepLabel-root .Mui-active": {
                        color:
                          label.status === "Scheduled" || label.status === "TBD"
                            ? "#FFCE56"
                            : label.status === "N/A"
                            ? "grey"
                            : "#71B278", // circle color (ACTIVE)
                      },
                    }}
                    key={label.id}
                    completed={label.status === "Completed"}
                    active={
                      label.status === "Scheduled" ||
                      label.status === "TBD" ||
                      label.status === "In Progress"
                    }
                  >
                    <StepLabel>{label?.engagementStageName}</StepLabel>
                  </Step>
                ))}
            </Stepper>
          )}
        </div>
      )}

      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "arial",
          marginTop: "1rem",
        }}
      >
        {/* <Typography
            sx={{ fontSize: "1rem", fontWeight: "bold" }}
            gutterBottom
            variant="h9"
            component="div"
          >
          Impact Scale Rating
          </Typography> */}
        {clientName && latestStage.length > 0 && (
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Stack>
              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <span style={{ color: "#FFCE56" }}>
                  {" "}
                  <CircleIcon />
                </span>{" "}
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    marginLeft: "0.5rem",
                    marginTop: "0.2rem",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  Scheduled
                </Typography>
              </Stack>

              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <span style={{ fontSize: "1rem", color: "#FFCE56" }}>
                  {" "}
                  <CircleIcon />
                </span>{" "}
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    marginLeft: "0.5rem",
                    marginTop: "0.2rem",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  TBD(To Be Decided)
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ marginLeft: "1rem" }}>
              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <span style={{ fontSize: "1rem", color: "#71B278" }}>
                  {" "}
                  <CircleIcon color="#c20927" />
                </span>{" "}
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    marginLeft: "0.5rem",
                    marginTop: "0.2rem",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  In Progress
                </Typography>
              </Stack>

              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <span style={{ color: "grey" }}>
                  {" "}
                  <CheckCircleIcon />
                </span>{" "}
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    marginLeft: "0.5rem",
                    marginTop: "0.2rem",
                  }}
                  gutterBottom
                  variant="h9"
                  component="div"
                >
                  N/A
                </Typography>
              </Stack>
            </Stack>

            <Stack
              sx={{ display: "flex", flexDirection: "row", marginLeft: "1rem" }}
            >
              <span style={{ color: "#00527D" }}>
                {" "}
                <CheckCircleIcon />
              </span>{" "}
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  marginLeft: "0.5rem",
                  marginTop: "0.2rem",
                }}
                gutterBottom
                variant="h9"
                component="div"
              >
                Completed
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>

      {clientName && (
        <Stack sx={{ marginTop: "1rem" }}>
          <PageHeader variant="pageHeader">Key Engagement Details</PageHeader>
          <MuiDataGrid data={dataTwo} />
        </Stack>
      )}
      {/* <Typography sx={{ fontSize: "1.2rem" }}>{  holder && accountName &&`${holder} from ${accountName},`}
       Engagements Scope Updates
        </Typography>
    

      <MuiDataGrid data= {dataTwo}  /> */}
    </Layout>
  );
};

export default Assesment;
