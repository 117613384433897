import React, { useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { MyTextInput, PageHeader } from "@excoleadershipui/pionweblib";
// material ui
import {
  Typography,
  Container,
  Box,
  Grid,
  Link,
  Paper,
  IconButton,
  Stack,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// icons
import logo from "../assets/images/exco-nav-logo.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
// components
import FormSubmitButton from "./form_components/FormSubmitButton";
import Notifier from "../components/common/Notifier";
import ErrorNotifier from "../components/common/ErrorNotifier";
// redux
import {
  forgotPassVerifyEmail,
  resetMessage,
  resetErrMessage,
} from "../features/auth/forgotPasswordSlice";

import { BsTwitterX } from "react-icons/bs";

// -----------------------------------------------------

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.excoleadership.com/">
        excoleadership
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

//   -----------------------------------------------------------------------------------------

function SocialMedia(props) {
  return (
    <Box textAlign="center" sx={{ marginTop: 2 }}>
      <Typography variant="body2">Stay Social</Typography>
      <Stack
        direction="row"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="center"
      >
        <LinkedInIcon textAlign="center" />
        <h3
          // data-toggle="tooltip"
          // data-placement="top"
          // onClick={this.handleAssignedView}
          style={{
            marginTop: "-.5rem",
            cursor: "pointer",
            fontSize: "1.2rem",
          }}
        >
          {" "}
          <BsTwitterX />{" "}
        </h3>

      </Stack>
    </Box>
  );
}

// -------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  btnSubmit: {
    marginTop: "1rem !important",
  },
  errorDiv: {
    color: theme.palette.red.main,
  },
}));

// ---------------------------------------------------------------------------------------------

const EmailValidationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});
const PassValidationSchema = Yup.object({
  password: Yup.string("Enter your password")
    .required("Password is required"),
});

// ---------------------------------------------------------------------------------------------

const ForgotPassword = (props) => {
  const [showEmail, setShowEmail] = useState(true);
  const [showPass, setShowPass] = useState(false);

  let navigate = useNavigate();
  const classes = useStyles();

  const dispatch = useDispatch();

  const { loading, loginToken, rq, message, errMessage } = useSelector(
    (state) => ({
      ...state.forgetPass,
    })
  );

  const handlePass = () => {
    setShowPass(true);
    setShowEmail(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 4 }}>
        <PageHeader sx={{ marginTop: 4 }}>ExCo Forgot Password </PageHeader>
      </Box>

      <Paper
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          position: "relative",
          border: "1px solid",
        }}
        elevation={2}
      >
        {/* {showPass && (
          <IconButton
            sx={{ position: "absolute", left: "0.5rem", top: "0.5rem" }}
            onClick={() => {
              setShowEmail(true);
              setShowPass(false);
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        )} */}
        <img alt="logo" src={logo} width="80rem" height="40rem" />
        <Typography variant="pageHeader">Forgot Password</Typography>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={
            showEmail ? EmailValidationSchema : PassValidationSchema
          }
          onSubmit={(values, { setSubmitting }) => {
            if (showEmail) {
              return dispatch(forgotPassVerifyEmail({ email: values.email })).then(
                (res) => {
                  if (res?.payload?.data?.ok) {
                    setTimeout(() => {
                      dispatch(resetMessage());
                      // handlePass();
                      navigate("/")
                    }, 1000);
                  }
                  if (res?.payload?.error) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 2000);
                  }
                  if (res?.payload?.warn) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 2000);
                  }
                }
              );
            }
            // if (showPass) {
            //   return dispatch(
            //     login({
            //       email: values.email,
            //       password: values.password,
            //       token: loginToken,
            //       remember: true,
            //       rq: rq,
            //     })
            //   ).then((res) => {
            //     if (res?.payload?.data?.ok) {
            //       setTimeout(() => {
            //         dispatch(resetMessage());
            //         navigate("exco");
            //       }, 2000);
            //     }
            //     if (res?.payload?.error) {
            //       setTimeout(() => {
            //         dispatch(resetErrMessage());
            //       }, 2000);
            //     }
            //     if (res?.payload?.warn) {
            //       setTimeout(() => {
            //         dispatch(resetErrMessage());
            //       }, 2000);
            //     }
            //   });
            // }
          }}
        >
          {({ errors, touched, handleChange, handleSubmit, values }) => (
            <Box
              component="form"
              noValidate
              sx={{ mt: 1 }}
              onSubmit={handleSubmit}
            >
              {showEmail && (
                <MyTextInput
                  label="Email Address"
                  type="email"
                  name="email"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="email"
                  onChange={handleChange("email")}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  autoFocus
                />
              )}
              {showPass && (
                <MyTextInput
                  label="Password"
                  type="password"
                  name="password"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="password"
                  onChange={handleChange("password")}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  autoFocus
                />
              )}
              {showEmail && (
                <FormSubmitButton
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  className={classes.btnSubmit}
                >
                  Submit
                </FormSubmitButton>
              )}
              {showPass && (
                <FormSubmitButton
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  className={classes.btnSubmit}
                >
                  Sign In
                </FormSubmitButton>
              )}
              {showPass && (
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </Formik>
      </Paper>
      <SocialMedia />
      <Copyright sx={{ mt: 8, mb: 4 }} />
      <Outlet />
      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Container>
  );
};

export default ForgotPassword;

