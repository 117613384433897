import { createTheme } from "@mui/material";

export const theme = createTheme({
  overrides: {
    MuiStepIcon: {
     root: {
       '&$completed': {
         color: 'pink',
       },
       '&$active': {
         color: 'red',
       },
     },
     active: {},
     completed: {},
   },
  },
  palette: {
    primary: {
      main: "#00527d",
      light: "#c4e1f1",
    },
    green: {
      main: "#37a348",
      light: "#71b278",
    },
    black: {
      main: "#000000",
    },
    white: {
      main: "#ffffff",
    },
    grey: {
      main: "#98999d",
      light: "#e7e6e6",
    },
    yellow: {
      main: "#f7cb19",
    },
    red: {
      main: "#da2128",
    },
    sidebarBg: {
      main: "#262626",
    },
  },
  typography: {
    pageHeader: {
      fontFamily: '"dinBold", sans-sarif',
      fontSize: "1.5rem !important",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "0em",
    },
    pageSecHeader: {
      fontFamily: '"dinBold", sans-sarif',
      fontSize: "1.2rem !important",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "0em",
    },
    h5: {
      fontFamily: '"Arial",sans-sarif',
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "normal",
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: '"Arial",sans-sarif',
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "normal",
      letterSpacing: "0em",
    },
  },
});
