import React, { useState, useEffect } from "react";
// components
import Layout from "../../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import { MuiDataGrid } from "../../../components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { PageHeader } from "@excoleadershipui/pionweblib";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { GridCellExpand } from "../../../components";

import { format } from "date-fns";
import {
  getClientForHr,
  resetMessage,
  getHrLeadershipStrength,
  getHrLeadershipImprovement,
  getHrLeadershipCoreValues,
} from "../../../features/hr/assesment/hrAssesmentSlice";

const steps = ["Na", "Matching", "Chemistry", "Preparation", "Mentoring"];

import { MuiAutocomplete } from "../../../components";
import { Grid } from "@mui/material";

const Assesment = ({ theme }) => {
  const [accountName, setAccountName] = useState("");
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );
  const [allValue, setAllValue] = useState("");
  const [holder, setHolder] = useState("");
  const [isViewShow, setIsViewShow] = useState(false);
  const dispatch = useDispatch();

  const {
    loading,
    message,
    errMessage,
    leadershipStrengthData,
    leadershipImprovementData,
    leadershipCoreValuesData,
    getAllClientData,
    selectedData,
  } = useSelector((state) => ({ ...state.HrClientLeadership }));


  const handleChange = (e) => {
    let clinetId = e.target.value.split(",")[0];
    let account = e.target.value.split(",")[1];
    let holderName = e.target.value.split(",")[2];
    setAllValue(e.target.value);
    setClientName(clinetId);
    setHolder(holderName);
    setAccountName(account);
    dispatch(getHrLeadershipCoreValues(clinetId));
    dispatch(getHrLeadershipStrength(clinetId));
    dispatch(getHrLeadershipImprovement(clinetId));
  };

  function renderCellExpand(params) {
  
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const getCreatedOnDate = (params) => {
    return params.row.createdOn
        ? format(new Date(params.row.createdOn), "MM-dd-yyyy")
        : "";
  };
  
  const getUpdatedOnDate = (params) => {
    return params.row.updatedOn
      ? format(new Date(params.row.updatedOn), "MM-dd-yyyy")
      : ""
  
  };
  const data = {
    rows: leadershipStrengthData,
    columns: [
      {
        field: "strength",
        headerName: "Strength Identified",
        width: 300,
        renderCell: renderCellExpand,
      },
      {
        field: "detailedDesc",
        headerName: "Description",
        width: 600,
        renderCell: renderCellExpand,
      },
      {
        field: "date",
        headerName: "Date Added",
        width: 150,

        valueGetter: getCreatedOnDate,
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,

        valueGetter: getUpdatedOnDate,
      },
    ],
  };

  const dataTwo = {
    rows: leadershipImprovementData,
    columns: [
      {
        field: "areaOfImprovement",
        headerName: "Dev Opp Identified",
        width: 300,
        renderCell: renderCellExpand,
      },
      {
        field: "detailedDesc",
        headerName: "Description",
        width: 600,
        renderCell: renderCellExpand,
      },
      {
        field: "date",
        headerName: "Date Added",
        width: 150,

        valueGetter: getCreatedOnDate,
      },
      {
        field: "dateUpdated",
        headerName: "Date Updated",
        width: 150,

        valueGetter: getUpdatedOnDate,
      },
    ],
  };

  const dataThree = {
    rows: leadershipCoreValuesData,
    columns: [
      {
        field: "coreValues",
        headerName: "Core Values",
        width: 300,
        renderCell: renderCellExpand,
      },
      {
        field: "detailedDesc",
        headerName: "Description",
        width: 600,
        renderCell: renderCellExpand,
      },
    ],
  };

  useEffect(() => {
    clientName &&
      dispatch(getHrLeadershipCoreValues(clientName?.excoCustomerUser?.id));
    clientName &&
      dispatch(getHrLeadershipStrength(clientName?.id));
    clientName &&
      dispatch(getHrLeadershipImprovement(clientName?.id));
  }, [clientName]);

  useEffect(() => {
    dispatch(getClientForHr());
  }, []);
  return (
    <Layout>
      {/* <Stack
          sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              fontFamily: "arial",
              width:"65%",
              paddingBottom:"1rem"
            }}
          > */}
      <Stack sx={{ marginTop: "1.875rem" }}>
        
            <PageHeader variant="pageHeader">
              {" "}
              Leadership Profile for {clientName?.firstName} {clientName?.lastName}
            </PageHeader>
           
      </Stack>
      {clientName && (
        <Stack>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              fontFamily: "arial",
              marginTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <Typography sx={{ fontSize: "1.4rem",fontWeight:"bold" }}>{clientName?.firstName} {clientName?.lastName}, Strengths Identified
            </Typography>
          </Stack>

          {/* </Stack> */}
          <MuiDataGrid data={data} sx={{ marginTop: "1rem" }} />
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              fontFamily: "arial",
              marginTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <Typography sx={{ fontSize: "1.4rem",fontWeight:"bold" }}>
            {clientName?.firstName} {clientName?.lastName}, Opportunities for Development
            </Typography>
          </Stack>

          <MuiDataGrid data={dataTwo} />

        </Stack>
      )}
    </Layout>
  );
};

export default Assesment;
