import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import React from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d2e7fc",
    fontWeight: "bold",
  },
}));

const useStyles = makeStyles({
  bottomBorder: {
    borderBottom: "2px solid gray",
  },
});

export const SummaryTableLevelOfEngagementPdf = ({ data }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.bottomBorder} aria-label="simple table">
        <TableHead>
          <TableRow>
            {data?.columns?.length > 0 &&
              data?.columns?.map((column) => (
                <StyledTableCell key={column?.headerName}>
                  {column?.headerName}
                </StyledTableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.rows?.length > 0 &&
            data?.rows?.map((row) => (
              <>
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            (row?.engagementCheckInRating === 1 && "red") ||
                            (row?.engagementCheckInRating === 2 && "yellow") ||
                            (row?.engagementCheckInRating === 3 && "green"),
                          width: "20px",
                          height: "20px",
                          borderRadius: "100%",

                          display: "inline-block",
                        }}
                      ></div>

                      <div
                        style={{ display: "inline-block", marginLeft: "1rem" }}
                      >
                        {(row?.engagementCheckInRating === 1 && "Red") ||
                          (row?.engagementCheckInRating === 2 && "Yellow") ||
                          (row?.engagementCheckInRating === 3 && "Green")}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    {row.createdOn
                      ? format(new Date(row.createdOn), "MM-dd-yyyy")
                      : ""}
                  </TableCell>
                  <TableCell>
                    {row.updatedOn
                      ? format(new Date(row.updatedOn), "MM-dd-yyyy")
                      : ""}
                  </TableCell>
                </TableRow>
              </>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SummaryTableEngagementNotesPdf = ({ data }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.bottomBorder} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* {data?.columns?.length > 0 &&
                data?.columns?.map((column) => (
                  <StyledTableCell key={column?.headerName}>
                    {column?.headerName}
                  </StyledTableCell>
                ))} */}
            <StyledTableCell style={{ width: "60%" }}>
              Notes from Mentor/Coach
            </StyledTableCell>
            <StyledTableCell style={{ width: "20%" }}>
              Date Added
            </StyledTableCell>
            <StyledTableCell style={{ width: "20%" }}>
              Date Updated
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.rows?.length > 0 &&
            data?.rows?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row?.engagementNote}
                </TableCell>
                <TableCell>
                  {row.createdOn
                    ? format(new Date(row.createdOn), "MM-dd-yyyy")
                    : ""}
                </TableCell>
                <TableCell>
                  {row.updatedOn
                    ? format(new Date(row.updatedOn), "MM-dd-yyyy")
                    : ""}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SummaryTableTouchPointDatesPdf = ({ data }) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.bottomBorder} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {data?.columns?.length > 0 &&
              data?.columns?.map((column) => (
                <StyledTableCell key={column?.headerName}>
                  {column?.headerName}
                </StyledTableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data?.rows?.length > 0 && (
              // data?.rows?.map((row) => (
              <TableRow
                // key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {data?.rows[0]?.dateOfLastMeeting
                    ? format(
                        new Date(data?.rows[0]?.dateOfLastMeeting),
                        "MM-dd-yyyy"
                      )
                    : ""}
                </TableCell>
                <TableCell>
                  {data?.rows[0]?.dateOfLastHrTouchPoint
                    ? format(
                        new Date(data?.rows[0]?.dateOfLastHrTouchPoint),
                        "MM-dd-yyyy"
                      )
                    : ""}
                </TableCell>
                <TableCell>
                  {data?.rows[0]?.dateOfLastManagerTouchPoint
                    ? format(
                        new Date(data?.rows[0]?.dateOfLastManagerTouchPoint),
                        "MM-dd-yyyy"
                      )
                    : ""}
                </TableCell>
              </TableRow>
            )
            // ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const SummaryTableAdditionalCommentaryPdf = ({ data }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.bottomBorder} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* {data?.columns?.length > 0 &&
              data?.columns?.map((column) => (
                <StyledTableCell key={column?.headerName}>
                  {column?.headerName}
                </StyledTableCell>
              ))} */}
            <StyledTableCell style={{ width: "60%" }}>
              Comments from Mentor/Coach
            </StyledTableCell>
            <StyledTableCell style={{ width: "20%" }}>
              Date Added
            </StyledTableCell>
            <StyledTableCell style={{ width: "20%" }}>
              Date Updated
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.rows?.length > 0 &&
            data?.rows?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row?.progressDescription}
                </TableCell>
                <TableCell>
                  {row.createdOn
                    ? format(new Date(row.createdOn), "MM-dd-yyyy")
                    : ""}
                </TableCell>
                <TableCell>
                  {row.updatedOn
                    ? format(new Date(row.updatedOn), "MM-dd-yyyy")
                    : ""}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
