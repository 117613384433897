import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  GET_REPORTS_AREA_FOCUS,
  GET_REPORTS_ENGAGEMENT_STATUS,
  GET_ENGAGEMENT_DETAIL,
  GET_ALL_CLIENTS_HR,
  HR_ENGAGEMENT_REPORTS,
  HR_LATEST_SUMMARY,
  HR_PREVIOUS_SUMMARY_DATA,
  HR_PREVIOUS_SUMMARY_DATA_BY_ID,
  HR_GENERATE_SUMMARY,
} from "../../../utils/routes";

// ---------------------------------------------------------------------

export const getAreaOfFocus = createAsyncThunk(
  "reports/getAreaOfFocus",
  async () => {
    return axios.get(GET_REPORTS_AREA_FOCUS, { withCredentials: true });
  }
);

export const getAllClientForHr = createAsyncThunk(
  "client/getAllClient",
  async () => {
    return axios.get(GET_ALL_CLIENTS_HR, { withCredentials: true });
  }
);

export const getGenerateHrSummary = createAsyncThunk(
  "hr/getHrSummaryGenerate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(HR_GENERATE_SUMMARY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllReportsSummary = createAsyncThunk(
  "hr/getLatestHrSummaryRecords",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };

    const response = await axios.post(HR_LATEST_SUMMARY, body, {
      withCredentials: true,
    });
    return response;
  }
);

// export const getFullEnagegementsReports = createAsyncThunk(
//   "reports/getFullReportsHr",
//   async () => {
//     return axios.get(HR_ENGAGEMENT_REPORTS, { withCredentials: true });
//   }
// );

export const getFullEnagegementsReports = createAsyncThunk(
  "reports/getFullReportsHr",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };
    try {
      const response = await axios.post(HR_GENERATE_SUMMARY, body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagementNotes = createAsyncThunk(
  "reports/getEngagement",
  async () => {
    return axios.get(GET_REPORTS_ENGAGEMENT_STATUS, { withCredentials: true });
  }
);

export const getPreviousSummaryForHr = createAsyncThunk(
  "summary/previousForHr",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };
    try {
      const response = await axios.post(HR_PREVIOUS_SUMMARY_DATA, body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitPreviousSummaryForHr = createAsyncThunk(
  "rating/clientOutcomes",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(HR_PREVIOUS_SUMMARY_DATA_BY_ID, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    areaFocusData: [],
    engagementFocusData: [],
    getAllClientData: [],
    previousSummaryDataForHr: [],
    generateHrReportsData: {},
    dateErrorMessageData: "",
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    emptyingArry: (state, action) => {
      state.generateHrReportsData = {};
    },
    dateErrorMessage: (state, action) => {
      console.log(action.payload);
      state.dateErrorMessageData = action.payload;
    },
  },
  extraReducers: {
    [getGenerateHrSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [getGenerateHrSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.generateHrReportsData = action?.payload?.data;
    },
    [getGenerateHrSummary.rejected]: (state, action) => {
     
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.generateHrReportsData = {};
    },

    [getAllReportsSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllReportsSummary.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.generateHrReportsData = action?.payload?.data;
    },
    [getAllReportsSummary.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.generateHrReportsData = {};
    },

    [getAreaOfFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [getAreaOfFocus.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.areaFocusData = action?.payload?.data;
    },
    [getAreaOfFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [submitPreviousSummaryForHr.pending]: (state, action) => {
      state.loading = true;
    },
    [submitPreviousSummaryForHr.fulfilled]: (state, action) => {
    
      state.loading = false;
      state.engagementFullReportsData = action?.payload?.data;
    },
    [submitPreviousSummaryForHr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getPreviousSummaryForHr.pending]: (state, action) => {
      state.loading = true;
    },
    [getPreviousSummaryForHr.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.previousSummaryDataForHr = action?.payload?.data;
    },
    [getPreviousSummaryForHr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    // [getAllReportsSummary.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [getAllReportsSummary.fulfilled]: (state, action) => {
    //   console.log(action?.payload?.data);
    //   state.loading = false;
    //   state.engagementFullReportsData = action?.payload?.data;
    // },
    // [getAllReportsSummary.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.errMessage = action?.payload?.message;
    // },
    [getFullEnagegementsReports.pending]: (state, action) => {
      state.loading = true;
    },
    [getFullEnagegementsReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.engagementFullReportsData = action?.payload?.data;
    },
    [getFullEnagegementsReports.rejected]: (state, action) => {
     
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.engagementFullReportsData = [];
    },
    [getAllClientForHr.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllClientForHr.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [getAllClientForHr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getEngagementNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementNotes.fulfilled]: (state, action) => {
      
      state.loading = false;
      state.engagementFocusData = action?.payload?.data;
    },
    [getEngagementNotes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const {
  resetMessage,
  resetErrMessage,
  dateErrorMessage,
  rowSelect,
  emptyingArry,
} = engagementSlice.actions;
export default engagementSlice.reducer;
