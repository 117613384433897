import React from "react";
// material ui
import { Container, Box, Grid } from "@mui/material";
// image
import BgImage from "../assets/images/X-light-gray.png";
import { TopBar } from "./topBar";

import { useLocation } from "react-router-dom";
// -------------------------------------------------------------------------

const Layout = (props) => {
  const location = useLocation();
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
       
      }}
    >
      <Grid container justifyContent="center">
        <Grid item md={10} sm={10} xs={10}>
          <div
            // maxWidth="lg"
            // component="div"
            style={{
              marginTop: "6rem",
              marginBottom: "4rem",
              // paddingTop: props?.isClient ? "1rem" : 0,

              // pr: "3rem !important",
              height: "80vh",
              background: `url(${BgImage})`,
              backgroundSize: "contain",
              backgroundPosition: "right bottom",
              backgroundRepeat: "no-repeat",
            }}
          >
            {!props?.show && (
              <TopBar
                clientApproved={props?.clientApproved}
                mentorSubmitted={props?.mentorSubmitted}
                isClient={props?.isClient}

                handleBackClick={props?.handleBackClick}
                showDetails={props?.showDetails}
              />
            )}
            {props?.children}
          </div>
        </Grid>
      </Grid>

      {/* <Container
        maxWidth="lg"
        component="div"
        sx={{
          mt: 10,
          mb: 4,
          // pt: props?.isClient ? 6 : 0,
          // pr: "3rem !important",
          height: "80vh",
          background: `url(${BgImage})`,
          backgroundSize: "contain",
          backgroundPosition: "right bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        {!props?.show && (
          <TopBar
            isClient={props?.isClient}
            handleBackClick={props?.handleBackClick}
            showDetails={props?.showDetails}
          />
        )}

        {props?.children}
      </Container> */}
    </Box>
  );
};

export default Layout;
