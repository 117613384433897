import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// material ui
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import { makeStyles } from "@mui/styles";
// icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SupportIcon from "@mui/icons-material/Support";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AttractionsIcon from "@mui/icons-material/Attractions";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SchemaIcon from "@mui/icons-material/Schema";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssistantIcon from "@mui/icons-material/Assistant";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AssessmentIcon from "@mui/icons-material/Assessment";

import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// components
import { MuiTooltip } from "../../components";

// --------------------------------------------------------------------------------

import { useSelector, useDispatch } from "react-redux";
import { getAllRoles } from "../../features/auth/authSlice";

const useStyles = makeStyles((theme) => ({
  listButton: {
    "& .MuiListItemText-root": {
      color: "white",
    },
    "& .drawerIcon": {
      color: "white",
    },
    "&:hover .drawerIcon": {
      color: theme.palette.primary.light,
    },
    "&.Mui-selected": {
      borderLeft: "0.25rem solid #00527d",
      paddingLeft: "0.75rem",
      backgroundColor: "#ffffff !important",
    },
    "&.Mui-selected .MuiListItemText-root": {
      color: "#000000 !important",
    },
    "&.Mui-selected .drawerIcon": {
      color: theme.palette.primary.main,
    },
    "&.Mui-selected .MuiSvgIcon-root": {
      color: theme.palette.black.main,
    },
  },
  collapseListButton: {
    paddingLeft: "2rem !important",
    "& .MuiListItemText-root": {
      color: "white",
    },
    "& .drawerIcon": {
      color: "white",
    },
    "&:hover .drawerIcon": {
      color: theme.palette.primary.light,
    },
    "&.Mui-selected": {
      borderLeft: "0.25rem solid #00527d",
      paddingLeft: "1.75rem !important",
      backgroundColor: "#ffffff !important",
    },
    "&.Mui-selected .MuiListItemText-root": {
      color: theme.palette.black.main,
    },
    "&.Mui-selected .drawerIcon": {
      color: theme.palette.primary.main,
    },
  },
  MuiLinks: {
    textDecoration: "none",
    color: "inherit",
  },
  listText: {
    "& span": {
      fontFamily: "Arial",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: "0em",
    },
  },
}));
// -------------------------------------------------------------------------------------

export const MainListItems = ({
  show,
  roleData,
  particularRole,
  rolesFinalData,
  clickSideMenu,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [custOpen, setCustOpen] = React.useState(false);
  const [isClient, setIsClient] = React.useState(false);
  const [isHr, setIsHr] = React.useState(true);
  const [role, setRole] = React.useState(particularRole);
  const [isManager, setIsManager] = React.useState(false);

  // const[roleArr,setRoleArr] = React.useState([])

  const classes = useStyles(custOpen);

  const dispatch = useDispatch();

  const { getAllRolesData, rolesData, localStoreRoleData } = useSelector(
    (state) => ({
      ...state.auth,
    })
  );

  // handleListItemClick function to set the selected value
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  // handleCustListClick function to expand the customer menu
  const handleCustListClick = () => {
    setCustOpen(!custOpen);
  };

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const roleArr = [
    {
      id: 1,
      role: "HR",
    },
    {
      id: 2,
      role: "MANAGER",
    },
    {
      id: 3,
      role: "CLIENT",
    },
  ];
  roleArr.length > 0 &&
    roleArr.forEach((data) => {
      roleArr.push(data.role);
    });

  let resultD =
    Object.keys(rolesFinalData).length > 0 &&
    rolesFinalData.getExcoCustomerUserRoles.length === 1
      ? rolesFinalData.getExcoCustomerUserRoles[0].role
      : localStorage.getItem("state");

      console.log(resultD)

  const location = useLocation();

  useEffect(() => {
    location.pathname === "/exco/dashboard" && setSelectedIndex(1);
    (location.pathname === "/exco/hr/dashboard" && setSelectedIndex(1)) ||
      (location.pathname === "/exco/manager/dashboard" && setSelectedIndex(1));
  }, []);

  useEffect(() => {
    (location.pathname === "/exco/assesment" && setSelectedIndex(4)) ||
      (location.pathname === "/exco/coaching_focus" && setSelectedIndex(8)) ||
      (location.pathname === "/exco/client/rating" && setSelectedIndex(9)) ||
      (location.pathname === "/exco/client/reports" && setSelectedIndex(11)) ||
      (location.pathname === "/exco/hr/assesment" && setSelectedIndex(10)) ||
      (location.pathname === "/exco/hr/coaching_focus" &&
        setSelectedIndex(11)) ||
      (location.pathname === "/exco/hr/reports" && setSelectedIndex(15)) ||
      (location.pathname === "/exco/hr/engagements" && setSelectedIndex(4)) ||
      (location.pathname === "/exco/manager/assesment" &&
        setSelectedIndex(4)) ||
      (location.pathname === "/exco/manager/coaching_focus" &&
        setSelectedIndex(8)) ||
      (location.pathname === "/exco/manager/reports" && setSelectedIndex(11));
  }, [location]);

  useEffect(() => {
    location.pathname === "/exco/hr/executive" && setSelectedIndex(20);
  }, [location]);

  return (
    <React.Fragment>
      {resultD === "CLIENT" && roleArr.includes(resultD) && (
        <div>
          <MuiTooltip title={show ? "" : "Dashboard"} placement="right">
            <Link to="dashboard" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 1}
                onClick={(event) => {
                  handleListItemClick(event, 1);
                  clickSideMenu();
                }}
              >
                <ListItemIcon>
                  <DashboardIcon className="drawerIcon" />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Dashboard"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>

          <MuiTooltip
            title={show ? "" : "Leadership Profile"}
            placement="right"
          >
            <Link to="assesment" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 4}
                onClick={(event) => {
                  handleListItemClick(event, 4);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <AssessmentIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Leadership Profile"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>

          <MuiTooltip title={show ? "" : "Coaching Focus"} placement="right">
            <Link to="coaching_focus" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 8}
                onClick={(event) => {
                  handleListItemClick(event, 8);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <FlagOutlinedIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Coaching Focus"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
          <MuiTooltip title={show ? "" : "Outcomes"} placement="right">
            <Link to="client/rating" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 9}
                onClick={(event) => {
                  handleListItemClick(event, 9);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <StarOutlineIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Outcomes"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>

          <MuiTooltip title={show ? "" : "Summary"} placement="right">
            <Link to="client/reports" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 11}
                onClick={(event) => {
                  handleListItemClick(event, 11);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <AssessmentIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Summary"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
        </div>
      )}
      {resultD === "HR" && roleArr.includes(resultD) && (
        <div>
          <MuiTooltip title={show ? "" : "Dashboard"} placement="right">
            <Link to="hr/dashboard" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 1}
                onClick={(event) => {
                  handleListItemClick(event, 1);
                  clickSideMenu();
                }}
              >
                <ListItemIcon>
                  <DashboardIcon className="drawerIcon" />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Dashboard"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>

          <MuiTooltip title={show ? "" : "Engagements"} placement="right">
            <Link to="hr/engagements" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 4}
                onClick={(event) => {
                  handleListItemClick(event, 4);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <GroupsRoundedIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Engagements"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>

          <MuiTooltip title={show ? "" : "Executives"} placement="right">
            <Link to="hr/executive" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 20}
                onClick={(event) => {
                  handleListItemClick(event, 20);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <PersonRoundedIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Executives"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
          <MuiTooltip
            title={show ? "" : "Leadership Profile"}
            placement="right"
          >
            <Link to="hr/assesment" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 10}
                onClick={(event) => {
                  handleListItemClick(event, 10);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <AssistantIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Leadership Profile"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>

          <MuiTooltip title={show ? "" : "Coaching Focus"} placement="right">
            <Link to="hr/coaching_focus" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 11}
                onClick={(event) => {
                  handleListItemClick(event, 11);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <FlagOutlinedIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Coaching Focus"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
          {/* <MuiTooltip title={show ? "" : "Outcomes"} placement="right">
        <Link to="hr/rating" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 13}
            onClick={(event) => handleListItemClick(event, 13)}
          >
            <ListItemIcon className="drawerIcon">
              <StarOutlineIcon />
            </ListItemIcon>
            {show && (
              <ListItemText
                primary="Outcomes"
                className={classes.listText}
              />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip> */}
          <MuiTooltip title={show ? "" : "Summary"} placement="right">
            <Link to="hr/reports" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 15}
                onClick={(event) => {
                  handleListItemClick(event, 15);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <AssessmentIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Summary"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
        </div>
      )}
      {resultD === "MANAGER" && roleArr.includes(resultD) && (
        <div>
          <MuiTooltip title={show ? "" : "Dashboard"} placement="right">
            <Link to="manager/dashboard" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 1}
                onClick={(event) => {
                  handleListItemClick(event, 1);
                  clickSideMenu();
                }}
              >
                <ListItemIcon>
                  <DashboardIcon className="drawerIcon" />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Dashboard"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>

          <MuiTooltip
            title={show ? "" : "Leadership Profile"}
            placement="right"
          >
            <Link to="manager/assesment" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 4}
                onClick={(event) => {
                  handleListItemClick(event, 4);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <AssessmentIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Leadership Profile"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>

          <MuiTooltip title={show ? "" : "Coaching Focus"} placement="right">
            <Link to="manager/coaching_focus" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 8}
                onClick={(event) => {
                  handleListItemClick(event, 8);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <FlagOutlinedIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Coaching Focus"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
          {/* <MuiTooltip title={show ? "" : "Outcomes"} placement="right">
        <Link to="manager/ratings" className={classes.MuiLinks}>
          <ListItemButton
            className={classes.listButton}
            selected={selectedIndex === 9}
            onClick={(event) => handleListItemClick(event, 9)}
          >
            <ListItemIcon className="drawerIcon">
              <StarOutlineIcon />
            </ListItemIcon>
            {show && (
              <ListItemText
                primary="Outcomes"
                className={classes.listText}
              />
            )}
          </ListItemButton>
        </Link>
      </MuiTooltip> */}

          <MuiTooltip title={show ? "" : "Summary"} placement="right">
            <Link to="manager/reports" className={classes.MuiLinks}>
              <ListItemButton
                className={classes.listButton}
                selected={selectedIndex === 11}
                onClick={(event) => {
                  handleListItemClick(event, 11);
                  clickSideMenu();
                }}
              >
                <ListItemIcon className="drawerIcon">
                  <AssessmentIcon />
                </ListItemIcon>
                {show && (
                  <ListItemText
                    primary="Summary"
                    className={classes.listText}
                  />
                )}
              </ListItemButton>
            </Link>
          </MuiTooltip>
        </div>
      )}
    </React.Fragment>
  );
};
