import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  GET_ENGAGEMENT_DETAIL,
  GET_CLIENT_ENGAGEMENT_STAGE,
  GET_CURRENT_MENTOR_NAME,

  GET_ALL_HR_PRIVATE_FEEDBACK_API,
  CREATE_PRIVATE_FEEDBACK_API_HR,
  UPDATE_PRIVATE_FEEDBACK_API_HR,
  DELETE_PRIVATE_FEEDBACK_API_HR,

  GET_ALL_MANAGER_PRIVATE_FEEDBACK_API,
  CREATE_PRIVATE_FEEDBACK_API_MANAGER,
  UPDATE_PRIVATE_FEEDBACK_API_MANAGER,
  DELETE_PRIVATE_FEEDBACK_API_MANAGER,

 

  GET_ALL_CHR_PRIVATE_FEEDBACK_API,
  CREATE_PRIVATE_FEEDBACK_API_CHR,
  UPDATE_PRIVATE_FEEDBACK_API_CHR,
  DELETE_PRIVATE_FEEDBACK_API_CHR,

} from "../utils/routes";

// ---------------------------------------------------------------------

export const getAllFeedbackByChr = createAsyncThunk(
  "privateFeedback/getPrivateFeedbackByChr",
  async (data) => {
    return axios.post(GET_ALL_CHR_PRIVATE_FEEDBACK_API, { id: data }, {
      withCredentials: true,
    });
  }
);

export const createChrPrivateFeedback = createAsyncThunk(
  "privateFeedback/createPrivatefeedbackChr",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_PRIVATE_FEEDBACK_API_CHR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateChrPrivateFeedback = createAsyncThunk(
  "privateFeedback/updatePrivateFeedbackChr",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PRIVATE_FEEDBACK_API_CHR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteChrPrivateFeedback = createAsyncThunk(
  "customer/deleteChrPrivateFeedback",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(DELETE_PRIVATE_FEEDBACK_API_CHR, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const getAllFeedbackByHr = createAsyncThunk(
  "privateFeedback/getPrivateFeedbackByHr",
  async (data) => {
    return axios.post(
      GET_ALL_HR_PRIVATE_FEEDBACK_API,
      { id: data },
      {
        withCredentials: true,
      }
    );
  }
);

export const createHrPrivateFeedback = createAsyncThunk(
  "privateFeedback/createHrFeedback",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_PRIVATE_FEEDBACK_API_HR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateHrPrivateFeedback = createAsyncThunk(
  "privateFeedback/updatePrivateFeedbackHr",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PRIVATE_FEEDBACK_API_HR, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteHrPrivateFeedback = createAsyncThunk(
  "customer/deleteHrPrivateFeedback",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(DELETE_PRIVATE_FEEDBACK_API_HR, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllFeedbackByManager = createAsyncThunk(
  "privateFeedback/getPrivateFeedbackByManager",
  async (data) => {
    return axios.post(GET_ALL_MANAGER_PRIVATE_FEEDBACK_API,  { id: data }, {
      withCredentials: true,
    });
  }
);

export const createManagerPrivateFeedback = createAsyncThunk(
  "privateFeedback/createManagerFeedback",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        CREATE_PRIVATE_FEEDBACK_API_MANAGER,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateManagerPrivateFeedback = createAsyncThunk(
  "privateFeedback/updatePrivateFeedbackManager",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PRIVATE_FEEDBACK_API_MANAGER, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteManagerPrivateFeedback = createAsyncThunk(
  "customer/deleteManagerPrivateFeedback",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(DELETE_PRIVATE_FEEDBACK_API_MANAGER, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagementDetails = createAsyncThunk(
  "engagement/getEngagementdetail",
  async () => {
    return axios.get(`${GET_ENGAGEMENT_DETAIL}`, { withCredentials: true });
  }
);

export const getEngagementStage = createAsyncThunk(
  "engagement/getEngagementStage",
  async () => {
    return axios.get(GET_CLIENT_ENGAGEMENT_STAGE, { withCredentials: true });
  }
);

export const getCurrentMentorName = createAsyncThunk(
  "dashboard/getCurrentMentor",
  async () => {
    return axios.get(GET_CURRENT_MENTOR_NAME, { withCredentials: true });
  }
);

export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    engagementsData: [],
    engagementStageData: [],
    privateFeedbackDataByChr: [],
    privateFeedbackDataByHr: [],
    privateFeedbackDataByManager: [],
    mentorCurrentDetails: {},
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [getAllFeedbackByChr.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllFeedbackByChr.fulfilled]: (state, action) => {
      state.loading = false;
      state.privateFeedbackDataByChr = action?.payload?.data;
      state.privateFeedbackDataByHr=[];
      state.privateFeedbackDataByManager=[];
    },
    [getAllFeedbackByChr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [createChrPrivateFeedback.pending]: (state, action) => {
      state.loading = true;
    },
    [createChrPrivateFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createChrPrivateFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    
    [updateChrPrivateFeedback.pending]: (state, action) => {
      state.loading = true;
    },
    [updateChrPrivateFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateChrPrivateFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [deleteChrPrivateFeedback.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteChrPrivateFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteChrPrivateFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },


    [getAllFeedbackByHr.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllFeedbackByHr.fulfilled]: (state, action) => {
      state.loading = false;
      state.privateFeedbackDataByHr = action?.payload?.data;
      state.privateFeedbackDataByManager=[];
      state.privateFeedbackDataByChr=[];
    },
    [getAllFeedbackByHr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [createHrPrivateFeedback.pending]: (state, action) => {
      state.loading = true;
    },
    [createHrPrivateFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createHrPrivateFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [updateHrPrivateFeedback.pending]: (state, action) => {
      state.loading = true;
    },
    [updateHrPrivateFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateHrPrivateFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [deleteHrPrivateFeedback.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteHrPrivateFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteHrPrivateFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },



    [getAllFeedbackByManager.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllFeedbackByManager.fulfilled]: (state, action) => {
      state.loading = false;
      state.privateFeedbackDataByManager = action?.payload?.data;
      state.privateFeedbackDataByHr=[];
      state.privateFeedbackDataByChr=[];
    },
    [getAllFeedbackByManager.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [createManagerPrivateFeedback.pending]: (state, action) => {
      state.loading = true;
    },
    [createManagerPrivateFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [createManagerPrivateFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [updateManagerPrivateFeedback.pending]: (state, action) => {
      state.loading = true;
    },
    [updateManagerPrivateFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateManagerPrivateFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },

    [deleteManagerPrivateFeedback.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteManagerPrivateFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteManagerPrivateFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },



    [getEngagementDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementDetails.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.engagementsData = action?.payload?.data;
    },
    [getEngagementDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getCurrentMentorName.pending]: (state, action) => {
      state.loading = true;
    },
    [getCurrentMentorName.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.mentorCurrentDetails = action?.payload?.data;
    },
    [getCurrentMentorName.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getEngagementStage.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementStage.fulfilled]: (state, action) => {
      console.log(action?.payload?.data);
      state.loading = false;
      state.engagementStageData = action?.payload?.data;
    },
    [getEngagementStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  engagementSlice.actions;
export default engagementSlice.reducer;
