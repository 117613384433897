import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  GET_ASSESMENT_IMPROVEMENT,
  GET_ASSESMENT_STRENGTH,
  GET_ENGAGEMENT_DETAIL,
  APPROVE_ASSESSMENT_STRENGTH,
  APPROVE_ASSESSMENT_IMPROVEMENT,
  GET_APPROVE_BUTTON_HR ,
  GET_APPROVE_BUTTON_MANAGER,
  GET_ALL_CLIENTS_HR,
  GET_HR_CLIENT_STRENGTS_BY_ID,
  GET_HR_CLIENT_IMPROVEMENT_BY_ID ,
  GET_HR_CORE_VALUES_BY_ID
} from "../../../utils/routes";

// ---------------------------------------------------------------------



export const getHrLeadershipStrength = createAsyncThunk(
  "leadership/getHrLeadershipStrength",
  async (data) => {
    return axios.get(`${GET_HR_CLIENT_STRENGTS_BY_ID}/${data}`, { withCredentials: true });
  }
);

export const getHrLeadershipImprovement = createAsyncThunk(
  "assesment/getImprovement",
  async (data) => {
    return axios.get(`${GET_HR_CLIENT_IMPROVEMENT_BY_ID}/${data}` , { withCredentials: true });
  }
);

export const getHrLeadershipCoreValues = createAsyncThunk(
  "leadership/getCoreValues",
  async (data) => {
    console.log("chandra")
    return axios.get(`${GET_HR_CORE_VALUES_BY_ID}/${data}` , { withCredentials: true });
  }
);


export const getClientForHr = createAsyncThunk(
  "leadership/getHrClient",
  async () => {
    return axios.get(GET_ALL_CLIENTS_HR, { withCredentials: true });
  }
);


export const getHrAssesmentHrButton = createAsyncThunk(
  "assesment/hrButton",
  async () => {
    return axios.get( GET_APPROVE_BUTTON_HR, { withCredentials: true });
  }
);

export const getAssesmentManagerButton = createAsyncThunk(
  "assesment/managerButton",
  async () => {
    return axios.get(GET_APPROVE_BUTTON_MANAGER, { withCredentials: true });
  }
);

export const updateApproveStrength = createAsyncThunk(
  "assessment/approveStrength",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(APPROVE_ASSESSMENT_STRENGTH, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateApproveImprovement = createAsyncThunk(
  "assessment/approveImprovent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( APPROVE_ASSESSMENT_IMPROVEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const  engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    assesmentButtonHrToggle:null,
    assesmentButtonManagerToggle:null,
    leadershipStrengthData: [],
    leadershipImprovementData:[],
    leadershipCoreValuesData:[],
    getAllClientData:[],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      console.log(action,"hitting")
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
   
     [ getClientForHr.pending]: (state, action) => {
      state.loading = true;
    },
    [ getClientForHr.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [ getClientForHr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getHrAssesmentHrButton.pending]: (state, action) => {
      state.loading = true;
    },
    [getHrAssesmentHrButton.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.assesmentButtonHrToggle = action?.payload?.data;
    },
    [getHrAssesmentHrButton.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getHrLeadershipStrength.pending]: (state, action) => {
      state.loading = true;
    },
    [ getHrLeadershipStrength.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.leadershipStrengthData = action?.payload?.data;
    },
    [getHrLeadershipStrength.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getHrLeadershipImprovement.pending]: (state, action) => {
      state.loading = true;
    },
  [getHrLeadershipImprovement.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.leadershipImprovementData = action?.payload?.data;
    },
    [getHrLeadershipImprovement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getHrLeadershipCoreValues.pending]: (state, action) => {
      state.loading = true;
    },
    [getHrLeadershipCoreValues.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.leadershipCoreValuesData = action?.payload?.data;
    },
    [getHrLeadershipCoreValues.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [ getHrLeadershipImprovement.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssesmentManagerButton.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssesmentManagerButton.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.assesmentButtonManagerToggle = action?.payload?.data;
    },
    [getAssesmentManagerButton.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateApproveStrength.pending]: (state, action) => {
      state.loading = true;
    },
    [updateApproveStrength.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateApproveStrength.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateApproveImprovement.pending]: (state, action) => {
      state.loading = true;
    },
    [updateApproveImprovement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateApproveImprovement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage, rowSelect } = engagementSlice.actions;
export default  engagementSlice.reducer;


