import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@mui/material";
import { countries, continents } from "countries-list";
// components
import Layout from "../../../layout/Layout";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Paper from "@mui/material/Paper";
import MyTextInput from "../../../components/form_components/FormTextField";
import { makeStyles } from "@mui/styles";
import StepLabel from "@mui/material/StepLabel";
import { MuiDataGrid, MuiFixedHeightGrid } from "../../../components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { PageHeader } from "@excoleadershipui/pionweblib";
import { FormSubmitButton } from "../../../components";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbar, GridRow } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MuiAutoCompleteSecondry, MuiAutocomplete } from "../../../components";
import { GridCellExpand } from "../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import ModalCloseBtn from "../../../components/common/ModalCloseBtn";
import { PopUpModel } from "../../../components";
import Notifier from "../../../components/common/Notifier";
import ErrorNotifier from "../../../components/common/ErrorNotifier";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

// redux
import {
  createNominationDetail,
  getNominationHr,
  updateNominationDetail,
  rowSelect,
  resetMessage,
  deleteNominationDetail,
  emptyRowSelect,
  resetErrMessage,
} from "../../../features/hr/nomination/nominationSlice";
import { height } from "@mui/system";

const steps = ["Na", "Matching", "Chemistry", "Preparation", "Mentoring"];

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
const useStyles = makeStyles((theme) => ({
  responsive_header_width : {
    [theme.breakpoints.down('md')]: {
      width:'19.5rem !important',
      
    },
  },
 
  rootbox : {
    position: "absolute !important",
    top: "50% !important",
    left: "60% !important",
    transform: "translate(-50%, -50%) !important",
    width: '56.25rem !important',
    [theme.breakpoints.down('md')]: {
      width:'20rem !important',
      height:'60% !important' ,
      display:'block !important',
      overflow:'scroll !important',
    },
    [theme.breakpoints.between('sm','md')]: {
      width: '33.375rem !important',
      height: '49% !important',
      display: 'block !important',
      overflow: 'scroll !important',
      // margin: '-3.188rem',
      marginTop: '-3.75rem  !important',
      marginLeft:'-3.563rem',
      padding: '2.125rem !important',
    },
    background: "#fff !important",
    borderRadius: "0.313rem !important",
    boxShadow: '1.5rem !important',
    paddingTop: '1rem !important',
    paddingLeft: '2rem !important',
    paddingRight: '2rem !important',
    paddingBottom:'1.5rem',
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  responsiveText : {
    width:'40rem',
    [theme.breakpoints.down('md')]: {
      width:'100%'
      
    }

  },

  close_btn : {
    [theme.breakpoints.down('md')]: {
    marginLeft:'-2.563rem'
  
    },
  }
  
    }))
const HrExecutive = ({ theme }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [continentValue, setContinentValue] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [countryValArr, setCountryValArr] = useState([]);
  const [countryArr, setCountryArr] = useState([]);
  const [execComments, setExecComments] = useState([]);
  const [executiveDialing, setExecutiveDialing] = useState("");
  const [managerDialing, setManagerDialing] = useState("");
  const showAddModel = () => {
    setShowAdd(!showAdd);
  };
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const showUpdateModel = () => {
    console.log("updateWorking");
    console.log(updateFormik.values);
    setShowUpdate(!showUpdate);
    setExecutiveDialing(
      selectedData?.executiveDialingCode && selectedData?.executiveDialingCode
    );
    setManagerDialing(
      selectedData?.managerDialingCode && selectedData?.managerDialingCode
    );
  };

  const dispatch = useDispatch();
  const { loading, message, errMessage, nominationData, selectedData } =
    useSelector((state) => ({ ...state.HrNomination }));

  const validationSchema = Yup.object({
    execName: Yup.string().required("Executive Name is required"),
    execEmail: Yup.string("Enter your email")
      .required("Executive Email is required")
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "email is not valid"),
    execMobileNo: Yup.string()
      .required("Contact Number is required ")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    geography: Yup.object().required("Geo Name is required"),
    country: Yup.object().required("Country is required "),
    execDialingCode: Yup.string().required("Dialing Code is required"),

    managerName: Yup.string().required("Manager Name is required"),
    managerEmail: Yup.string("Enter your email")

      .required("Email is required")
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "email is not valid"),
    managerDialingCode: Yup.string().required("Dialing Code is required"),
    managerContactNo: Yup.string()
      .required("Contact Number is required ")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),

    department: Yup.string().required("Department is required"),
    designation: Yup.string().required("Designation is required"),
  });

  const updateValidationSchema = Yup.object({
    execName: Yup.string().required("Executive Name is required"),
    execEmail: Yup.string("Enter your email")
      .required("Executive Email is required")
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "email is not valid"),
    execMobileNo: Yup.string()
      .required("Contact Number is required ")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    geoName: Yup.string().required("Geo Name is required"),
    country: Yup.string().required("Country is required "),
    execDialingCode: Yup.string().required("Dialing Code is required"),
    managerDialingCode: Yup.string().required("Dialing Code is required"),
    managerName: Yup.string().required("Manager Name is required"),
    managerEmail: Yup.string("Enter your email")
      .required("Email is required")
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "email is not valid"),
    managerContactNo: Yup.string()
      .required("Contact Number is required ")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),

    department: Yup.string().required("Department is required"),
    designation: Yup.string().required("Designation is required"),
  });

  const MangerDialingContact = (params) => {
    return `${params?.row?.managerDialingCode}- ${params?.row?.managerMobileNo}`;
  };

  const executiveDialingContact = (params) => {
    return `${params?.row?.executiveDialingCode} -${params?.row?.executiveMobileNo}`;
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  const data = {
    rows: nominationData,
    columns: [
      {
        field: "executiveName",
        headerName: "Exec Name ",
        width: 200,
        renderCell: renderCellExpand,
      },
      {
        field: "executiveMobileNo",
        headerName: "Exec Mobile No.",
        width: 200,
        valueGetter: executiveDialingContact,
      },
      {
        field: "executiveEmail",
        headerName: "Exec Email",
        width: 280,
        renderCell: renderCellExpand,
      },
      { field: "department", headerName: "Client Department ", width: 200 },
      { field: "designation", headerName: "Designation", width: 200 },
      {
        field: "managerName",
        headerName: "Manager Name",
        width: 200,
        renderCell: renderCellExpand,
      },
      {
        field: "managerMobileNo",
        headerName: "Manager Contact",
        width: 200,
        valueGetter: MangerDialingContact,
      },
      {
        field: "managerEmail",
        headerName: "Manager Email",
        width: 280,
        renderCell: renderCellExpand,
      },
      { field: "geoName", headerName: "Geography", width: 100 },
      { field: "country", headerName: "Country", width: 200 },
      { field: "status", headerName: "Status", width: 200 },
      {
        field: "comment",
        headerName: "Comments",
        width: 300,
        renderCell: renderCellExpand,
      },
    ],
  };

  const closeModel = () => {
    setShowAdd(false);
    setCountryValue("");
    setContinentValue("");
    formik.resetForm({
      execName: "",
      execEmail: "",
      execDialingCode: "",
      execMobileNo: "",
      managerName: "",
      managerDialingCode: "",
      managerContactNo: "",
      managerEmail: "",
      department: "",
    });
  };

  const handleDeleteNomination = () => {
    let data = {
      id: selectedData?.id,
    };
    dispatch(deleteNominationDetail(data)).then((res) => {
      if (res?.payload?.data?.ok) {
        setTimeout(() => {
          dispatch(resetMessage());
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          dispatch(getNominationHr());
        }, 2000);
      }
      if (res?.payload?.error) {
        setTimeout(() => {
          dispatch(resetErrMessage());
        }, 3000);
      }
    });
  };

  const handleSetConfirmDialog = (obj) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: obj.isOpen,
    });
  };

  const closeUpdateModel = () => {
    setShowUpdate(false);
    formik.resetForm({
      execName: "",
      execEmail: "",
      execDialingCode: "",
      execMobileNo: "",
      managerName: "",
      managerDialingCode: "",
      managerContactNo: "",
      geography: "",
      country: "",
      managerEmail: "",
      department: "",
      comments: "",
    });
  };

  const formik = useFormik({
    reset: true,
    initialValues: {
      execName: "",
      execEmail: "",
      execDialingCode: "",
      execMobileNo: "",
      geography: "",
      country: "",
      managerName: "",
      managerDialingCode: "",
      managerContactNo: "",
      managerEmail: "",
      department: "",
      designation: "",
      comment: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values, onSubmitProps) => {
      console.log(values);
      console.log(values.execMobileNo, values.managerMobileNo);
      dispatch(
        createNominationDetail({
          executiveName: values.execName,
          executiveDialingCode: values?.execDialingCode,
          executiveMobileNo: values.execMobileNo.toString(),
          executiveEmail: values.execEmail,
          department: values.department,
          managerName: values.managerName,
          managerDialingCode: values?.managerDialingCode,
          managerMobileNo: values.managerContactNo.toString(),
          managerEmail: values.managerEmail ? values.managerEmail : null,
          geoName: values?.geography?.code,
          country: values?.country?.name,
          comment: values.comment,
          designation: values?.designation,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          dispatch(emptyRowSelect());
          setTimeout(() => {
            dispatch(resetMessage());
            closeModel();
            dispatch(getNominationHr());

            onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  const updateFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      execName: selectedData?.executiveName,
      execEmail: selectedData?.executiveEmail,
      execDialingCode: selectedData?.executiveDialingCode,
      execMobileNo: selectedData?.executiveMobileNo,
      managerName: selectedData?.managerName,
      managerDialingCode: selectedData?.managerDialingCode,
      managerContactNo: selectedData?.managerMobileNo,
      managerEmail: selectedData?.managerEmail,
      department: selectedData?.department,
      geoName: selectedData?.geoName,
      country: selectedData?.country,
      comment: selectedData?.comment,
      designation: selectedData?.designation,
    },

    validationSchema: updateValidationSchema,

    onSubmit: (values, onSubmitProps) => {
      console.log(values.execMobileNo, values.execDialingCode);
      dispatch(
        updateNominationDetail({
          id: selectedData?.id,
          executiveName: values.execName,
          executiveDialingCode: values.execDialingCode,
          executiveMobileNo: values.execMobileNo.toString(),
          executiveEmail: values.execEmail,
          department: values.department,
          managerName: values.managerName,
          managerDialingCode: values.managerDialingCode,
          managerMobileNo: values.managerContactNo.toString(),
          managerEmail: values.managerEmail ? values.managerEmail : null,
          geoName: values.geoName,
          country: values.country,
          comment: values.comment,
          designation: values.designation,
        })
      ).then((res) => {
        if (res?.payload?.data?.ok) {
          setTimeout(() => {
            dispatch(resetMessage());
            closeUpdateModel();
            // window.location.reload()
            dispatch(getNominationHr());
            // onSubmitProps.resetForm();
          }, 2000);
        }
        if (res?.payload?.error) {
          setTimeout(() => {
            dispatch(resetErrMessage());
          }, 3000);
        }
      });
    },
  });

  let options = Object.values(countries);
  let conti = Object.keys(continents);

  // const geographyArr = [
  //   { key: "AF", value: "AF", name: "Africa" },
  //   { key: "AN", value: "AN", name: "Antarctica" },
  //   { key: "AS", value: "AS", name: "Asia" },
  //   { key: "EU", value: "EU", name: "Europe" },
  //   { key: "NA", value: "NA", name: "North America" },
  //   { key: "OC", value: "OC", name: "Oceania" },
  //   { key: "SA", value: "SA", name: "South America" },
  // ];

  const geographyArr = [
    { code: "AF", name: "Africa" },
    { code: "AN", name: "Antarctica" },
    { code: "AS", name: "Asia" },
    { code: "EU", name: "Europe" },
    { code: "NA", name: "North America" },
    { code: "OC", name: "Oceania" },
    { code: "SA", name: "South America" },
  ];

  console.log(continentValue);
  useEffect(() => {
    dispatch(getNominationHr());
  }, []);

  console.log(selectedData);

  useEffect(() => {
    return options.map((item) => {
      if (item.continent === continentValue?.value) {
        return countryValArr.push(item);
      }
      setCountryValArr(countryValArr);
      console.log(item);
    });
  }, [continentValue]);

  useEffect(() => {
    let countries = [];
    options.map((option) => {
      if (option.continent === formik?.values?.geography?.code) {
        return countries.push(option);
      }
    });
    setCountryArr(countries);
  }, [formik?.values?.geography]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return formik?.setFieldValue("execDialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === formik?.values?.country?.name) {
        return formik?.setFieldValue("managerDialingCode", option?.phone);
      }
    });
  }, [formik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === updateFormik?.values?.country) {
        return updateFormik?.setFieldValue("execDialingCode", option?.phone);
      }
    });
  }, [updateFormik?.values?.country]);

  useEffect(() => {
    options.map((option) => {
      if (option.name === updateFormik?.values?.country) {
        return updateFormik?.setFieldValue("managerDialingCode", option?.phone);
      }
    });
  }, [updateFormik?.values?.country]);

  // useEffect(() => {

  // }, [updateFormik.]);
  const classes = useStyles();
  console.log(updateFormik.errors);
  return (
    <Layout>
      <Stack
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          fontFamily: "arial",
          paddingTop: "1rem",
        }}
      >
        <PageHeader variant="pageHeader">Executive Nominations</PageHeader>
      </Stack>

      <MuiFixedHeightGrid
    
        viewAdd={true}
        setShowAdd={showAddModel}
        setShowEdit={showUpdateModel}
        data={data}
        select={(data) => dispatch(rowSelect(data))}
        setShowDelete={() =>
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to delete this record?",
            subTitle: "You can't undo this operation",
            onConfirm: () => handleDeleteNomination(),
          })
        }
      />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={(obj) => handleSetConfirmDialog(obj)}
      />

      <PopUpModel
        open={showUpdate}
        handleClose={closeUpdateModel}
        closeModel={closeUpdateModel}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
         
          component="form"
          noValidate
          onSubmit={updateFormik.handleSubmit}
        >
          <ModalCloseBtn close={closeUpdateModel} />
          <div   className={classes.responsiveText}>
            <Grid container spacing={1} className={classes.responsive_textfield}>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Executive Name"
                  type="text"
                  name="execName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="execName"
                  value={updateFormik.values.execName}
                  onChange={updateFormik.handleChange}
                  error={
                    updateFormik.touched.execName &&
                    Boolean(updateFormik.errors.execName)
                  }
                  helperText={
                    updateFormik.touched.execName &&
                    updateFormik.errors.execName
                  }
                />

                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Geography *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="geoName"
                    defaultValue={updateFormik?.values?.geoName}
                    value={updateFormik?.values.geoName}
                    error={
                      updateFormik.touched.geoName &&
                      Boolean(updateFormik.errors.geoName)
                    }
                    label="Geography *"
                    onChange={updateFormik.handleChange}
                  >
                    {Object.keys(continents).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}-{continents[item]}
                      </MenuItem>
                    ))}
                  </Select>
                  {updateFormik?.errors?.geoName && (
                    <FormHelperText error={true} sx={{ color: "red" }}>
                      {updateFormik.touched.geoName &&
                        updateFormik.errors.geoName}
                    </FormHelperText>
                  )}
                </FormControl>
                {/* < MuiAutocomplete
                   name="geography"
                   options={geographyArr}
                   setValue={(name, newValue) =>
                     formik.setFieldValue(name, newValue)
                   }
                    value={updateFormik.values.geography}
                   optionValue="code"
                   optionSecValue="name"
                   label="Geography"
                   required={true}
                   error={
                    updateFormik.touched.geography && Boolean(updateFormik.errors.geography)
                   }
                   helperText={updateFormik.touched.geography && updateFormik.errors.geography}
                /> */}

                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label="Dialing Code"
                      type="number"
                      name="execDialingCode"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="execDialingCode"
                      value={updateFormik.values.execDialingCode}
                      disabled
                      error={
                        updateFormik.touched.execDialingCode &&
                        Boolean(updateFormik.errors.execDialingCode)
                      }
                      helperText={
                        updateFormik.touched.execDialingCode &&
                        updateFormik.errors.execDialingCode
                      }
                    />
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      label="Executive Mobile No."
                      type="number"
                      name="execMobileNo"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="execMobileNo"
                      value={updateFormik.values.execMobileNo}
                      onChange={updateFormik.handleChange}
                      error={
                        updateFormik.touched.execMobileNo &&
                        Boolean(updateFormik.errors.execMobileNo)
                      }
                      helperText={
                        updateFormik.touched.execMobileNo &&
                        updateFormik.errors.execMobileNo
                      }
                    />
                  </div>
                </div>

                <MyTextInput
                  label="Designation"
                  type="text"
                  name="designation"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="designation"
                  value={updateFormik.values.designation}
                  onChange={updateFormik.handleChange}
                  tabIndex={1}
                  error={
                    updateFormik.touched.designation &&
                    Boolean(updateFormik.errors.designation)
                  }
                  helperText={
                    updateFormik.touched.designation &&
                    updateFormik.errors.designation
                  }
                />

                <MyTextInput
                  label="Manager Name"
                  type="text"
                  name="managerName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerName"
                  value={updateFormik.values.managerName}
                  onChange={updateFormik.handleChange}
                  error={
                    updateFormik.touched.managerName &&
                    Boolean(updateFormik.errors.managerName)
                  }
                  helperText={
                    updateFormik.touched.managerName &&
                    updateFormik.errors.managerName
                  }
                />

                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label="Dialing Code"
                      type="number"
                      name="managerDialingCode"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="managerDialingCode"
                      value={updateFormik.values.managerDialingCode}
                      disabled
                      error={
                        updateFormik.touched.managerDialingCode &&
                        Boolean(updateFormik.errors.managerDialingCode)
                      }
                      helperText={
                        updateFormik.touched.managerDialingCode &&
                        updateFormik.errors.managerDialingCode
                      }
                    />
                  </div>

                  <div>
                    <MyTextInput
                      label="Manager Mobile No."
                      type="number"
                      name="managerContactNo"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="managerContactNo"
                      value={updateFormik.values.managerContactNo}
                      onChange={updateFormik.handleChange}
                      error={
                        updateFormik.touched.managerContactNo &&
                        Boolean(updateFormik.errors.managerContactNo)
                      }
                      helperText={
                        updateFormik.touched.managerContactNo &&
                        updateFormik.errors.managerContactNo
                      }
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Executive Email Id"
                  type="text"
                  name="execEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="execEmail"
                  value={updateFormik.values.execEmail}
                  onChange={updateFormik.handleChange}
                  tabIndex={1}
                  error={
                    updateFormik.touched.execEmail &&
                    Boolean(updateFormik.errors.execEmail)
                  }
                  helperText={
                    updateFormik.touched.execEmail &&
                    updateFormik.errors.execEmail
                  }
                />
                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Country *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="country"
                    defaultValue={updateFormik.values.country}
                    value={updateFormik.values.country}
                    label="Country *"
                    onChange={updateFormik.handleChange}
                    error={
                      updateFormik.touched.country &&
                      Boolean(updateFormik.errors.country)
                    }
                  >
                    {options.map((option) => {
                      if (updateFormik.values.geoName === option.continent) {
                        return (
                          <MenuItem key={option.name} value={option.name}>
                            {option.name}
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                  {updateFormik?.errors?.country && (
                    <FormHelperText error={true} sx={{ color: "red" }}>
                      {updateFormik.touched.country &&
                        updateFormik.errors.country}
                    </FormHelperText>
                  )}
                </FormControl>
                {/* < MuiAutocomplete
                 name="country"
                 options={countryArr}
                 setValue={(name, newValue) =>
                   formik.setFieldValue(name, newValue)
                 }
                 value={updateFormik.values.country}
                 optionValue="name"
                 label="Country"
                 required={true}
                 error={
                  updateFormik.touched.country && Boolean(updateFormik.errors.country)
                 }
                 helperText={updateFormik.touched.country && updateFormik.errors.country}
                /> */}
                <MyTextInput
                  label="Department"
                  type="text"
                  name="department"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="department"
                  value={updateFormik.values.department}
                  onChange={updateFormik.handleChange}
                  tabIndex={1}
                  error={
                    updateFormik.touched.department &&
                    Boolean(updateFormik.errors.department)
                  }
                  helperText={
                    updateFormik.touched.department &&
                    updateFormik.errors.department
                  }
                />

                <MyTextInput
                  label="Manager Email"
                  type="text"
                  name="managerEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerEmail"
                  value={updateFormik.values.managerEmail}
                  onChange={updateFormik.handleChange}
                  error={
                    formik.touched.managerEmail &&
                    Boolean(formik.errors.managerEmail)
                  }
                  helperText={
                    formik.touched.managerEmail && formik.errors.managerEmail
                  }
                />

                <MyTextInput
                  label="Comments"
                  type="text"
                  name="comment"
                  fullWidth={true}
                  margin="normal"
                  id="comment"
                  value={updateFormik.values.comment}
                  onChange={updateFormik.handleChange}
                />
              </Grid>
            </Grid>
          </div>
          <Stack sx={{ marginTop: "0.5rem" }}>
            <FormSubmitButton type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Stack>
        </Box>
      </PopUpModel>

      <PopUpModel
        open={showAdd}
        handleClose={closeModel}
        closeModel={closeModel}
      >
        <Box
          // sx={{ ...style, width: 700 }}
          // sx={{ ...style, width: 700 }}
          className={classes.rootbox}
          component="form"
          style={{marginTop:'3.5rem'}}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div  className={classes.responsiveText}>
            <Grid container spacing={1} className={classes.responsive_textfield} >
             
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  tabIndex={1}
                  label="Executive Name"
                  type="text"
                  name="execName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="execName"
                  value={formik.values.execName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.execName && Boolean(formik.errors.execName)
                  }
                  helperText={formik.touched.execName && formik.errors.execName}
                />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Executive Email Id"
                  type="text"
                  name="execEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="execEmail"
                  value={formik.values.execEmail}
                  onChange={formik.handleChange}
                  tabIndex={2}
                  error={
                    formik.touched.execEmail && Boolean(formik.errors.execEmail)
                  }
                  helperText={
                    formik.touched.execEmail && formik.errors.execEmail
                  }
                />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                <MuiAutocomplete
                  tabIndex={3}
                  name="geography"
                  options={geographyArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.geography}
                  optionValue="code"
                  isNominate={true}
                  optionSecValue="name"
                  label="Geography"
                  required={true}
                  error={
                    formik.touched.geography && Boolean(formik.errors.geography)
                  }
                  helperText={
                    formik.touched.geography && formik.errors.geography
                  }
                />
                  </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                         <MuiAutocomplete
                  name="country"
                  options={countryArr}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.country}
                  isNominate={true}
                  optionValue="name"
                  label="Country"
                  required={true}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />
                </Grid>
              
                <Grid item xs={12} sm={12} md={2}>
                <MyTextInput
                      label="Dialing Code"
                      type="number"
                      name="execDialingCode"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="execDialingCode"
                      value={formik.values.execDialingCode}
                      disabled
                      error={
                        formik.touched.execDialingCode &&
                        Boolean(formik.errors.execDialingCode)
                      }
                      helperText={
                        formik.touched.execDialingCode &&
                        formik.errors.execDialingCode
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                  <MyTextInput
                      label="Executive Mobile No."
                      type="number"
                      name="execMobileNo"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="execMobileNo"
                      value={formik.values.execMobileNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.execMobileNo &&
                        Boolean(formik.errors.execMobileNo)
                      }
                      helperText={
                        formik.touched.execMobileNo &&
                        formik.errors.execMobileNo
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>

                  <MyTextInput
                  label="Department"
                  type="text"
                  name="department"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="department"
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.department &&
                    Boolean(formik.errors.department)
                  }
                  helperText={
                    formik.touched.department && formik.errors.department
                  }
                />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Designation"
                  type="text"
                  name="designation"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="department"
                  value={formik.values.designation}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.designation &&
                    Boolean(formik.errors.designation)
                  }
                  helperText={
                    formik.touched.designation && formik.errors.designation
                  }
                />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                  label="Manager Email"
                  type="text"
                  name="managerEmail"
                  required={true}
                  //  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerEmail"
                  value={formik.values.managerEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerEmail &&
                    Boolean(formik.errors.managerEmail)
                  }
                  helperText={
                    formik.touched.managerEmail && formik.errors.managerEmail
                  }
                />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Manager Name"
                  type="text"
                  name="managerName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerName"
                  value={formik.values.managerName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerName &&
                    Boolean(formik.errors.managerName)
                  }
                  helperText={
                    formik.touched.managerName && formik.errors.managerName
                  }
                />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                  <MyTextInput
                  label="Comments"
                  type="text"
                  name="comment"
                  fullWidth={true}
                  margin="normal"
                  id="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                />

                    </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
              
                  </div>
                  <div style={{ width: "65%" }}>
             
                  </div>
                </div>
            
                {/* <MyTextInput
                 
                 label="Department"
                 type="text"
                 name="department"
                 required={true}
                 fullWidth={true}
                 margin="normal"
                 id="department"
                 value={formik.values.department}
                 onChange={formik.handleChange}
                 tabIndex={1}
                 error={
                   formik.touched.department &&
                   Boolean(formik.errors.department)
                 }
                 helperText={
                   formik.touched.department && formik.errors.department
                 }
               /> */}

                

                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <MyTextInput
                      label="Dialing Code"
                      type="number"
                      name="managerDialingCode"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="managerDialingCode"
                      value={formik.values.managerDialingCode}
                      disabled
                      error={
                        formik.touched.managerDialingCode &&
                        Boolean(formik.errors.managerDialingCode)
                      }
                      helperText={
                        formik.touched.managerDialingCode &&
                        formik.errors.managerDialingCode
                      }
                    />
                  </div>

                  <div>
                    <MyTextInput
                      label="Manager Mobile No."
                      type="number"
                      name="managerContactNo"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="managerContactNo"
                      value={formik.values.managerContactNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.managerContactNo &&
                        Boolean(formik.errors.managerContactNo)
                      }
                      helperText={
                        formik.touched.managerContactNo &&
                        formik.errors.managerContactNo
                      }
                    />
                  </div>
                </div>
              
              <Grid item xs={12} sm={12} md={6}>
    
              

               

            

                {/* {formik?.values?.roles?.role === "CLIENT" && (
                 
                  <MuiAutocomplete
                  name="manager"
                  options={custManagerData}
                  setValue={(name, newValue) =>
                    formik.setFieldValue(name, newValue)
                  }
                  value={formik.values.manager}
                  optionValue="firstName"
                  optionSecValue="lastName"
                  label="Manager Name"
                  required={true}
                  error={
                    formik.touched.manager && Boolean(formik.errors.manager)
                  }
                  helperText={formik.touched.manager && formik.errors.manager}
                />
                )} */}
              </Grid>
            </Grid>
            </Grid>
          </div>
          <Stack sx={{ marginTop: "0.5rem" }}>
            <FormSubmitButton type="submit" variant="contained">
              Submit
            </FormSubmitButton>
          </Stack>
        </Box>
        {/* <Box
          sx={{ ...style, width: 700 }}
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <ModalCloseBtn close={closeModel} />
          <div style={{ width: "40rem" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Executive Name"
                  type="text"
                  name="execName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="execName"
                  value={formik.values.execName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.execName && Boolean(formik.errors.execName)
                  }
                  helperText={formik.touched.execName && formik.errors.execName}
                />
                <FormControl  fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Geography
                  </InputLabel>
                  <MuiAutoComplete
                    name="continentValue"
                    options={geographyArr}
                    setValue={(name, newValue) => setContinentValue(newValue)}
                    value={continentValue}
                    optionValue="name"

                  
                  />
                

                  <MuiAutoComplete
                    name="countryValue"
                    options={countryValArr}
                    setValue={(name, newValue) => setCountryValue(newValue)}
                    value={countryValue}
                    optionValue="name"

                   
                  />
                 
                </FormControl>
                <Stack  sx={{marginTop:"0.5rem"}}>
                <MyTextInput
                 
                  label="Executive Email Id"
                  type="text"
                  name="execEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="execEmail"
                  value={formik.values.execEmail}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.execEmail && Boolean(formik.errors.execEmail)
                  }
                  helperText={
                    formik.touched.execEmail && formik.errors.execEmail
                  }
                />
                </Stack>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem" }}>
                    <FormControl fullWidth>
                      <MyTextInput
                         InputLabelProps={{ shrink: true }}
                        label="Dialing Code"
                        type="text"
                        name="execDialing"
                        required={true}
                        fullWidth={true}
                        margin="normal"
                        id="execDialing"
                        value={countryValue?.phone}
                      
                        tabIndex={1}
                      />
                     
                    </FormControl>
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      label="Executive Mobile No."
                      type="number"
                      name="execMobileNo"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="execMobileNo"
                      value={formik.values.execMobileNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.execMobileNo &&
                        Boolean(formik.errors.execMobileNo)
                      }
                      helperText={
                        formik.touched.execMobileNo &&
                        formik.errors.execMobileNo
                      }
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <MyTextInput
                  label="Manager Name"
                  type="text"
                  name="managerName"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerName"
                  value={formik.values.managerName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerName &&
                    Boolean(formik.errors.managerName)
                  }
                  helperText={
                    formik.touched.managerName && formik.errors.managerName
                  }
                />
                <MyTextInput
                  label="Manager Email"
                  type="text"
                  name="managerEmail"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="managerEmail"
                  value={formik.values.managerEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.managerEmail && Boolean(formik.errors.managerEmail)
                  }
                  helperText={
                    formik.touched.managerEmail && formik.errors.managerEmail
                  }
                />

                <div style={{ display: "flex",marginTop:"0.5rem" }}>
                  <div style={{ width: "35%", paddingRight: "0.5rem", }}>
                    <FormControl fullWidth>
                      <MyTextInput
                         InputLabelProps={{ shrink: true }}
                        label="Dialing Code"
                        type="text"
                        name="managerDialingCode"
                        required={true}
                        fullWidth={true}
                        margin="normal"
                        id="managerDialingCode"
                        value={countryValue?.phone}
                      
                      />
                     
                    </FormControl>
                  </div>
                  <div style={{ width: "65%" }}>
                    <MyTextInput
                      label="Manager Mobile No."
                      type="number"
                      name="managerContactNo"
                      required={true}
                      fullWidth={true}
                      margin="normal"
                      id="managerContactNo"
                      value={formik.values.managerContactNo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.managerContactNo &&
                        Boolean(formik.errors.managerContactNo)
                      }
                      helperText={
                        formik.touched.managerContactNo &&
                        formik.errors.managerContactNo
                      }
                    />
                  </div>
                </div>

                <MyTextInput
                 
                  label="Department"
                  type="text"
                  name="department"
                  required={true}
                  fullWidth={true}
                  margin="normal"
                  id="department"
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  tabIndex={1}
                  error={
                    formik.touched.department &&
                    Boolean(formik.errors.department)
                  }
                  helperText={
                    formik.touched.department && formik.errors.department
                  }
                />
                 <MyTextInput
                  label="Comments"
                  type="text"
                  name="comment"
                 
                  fullWidth={true}
                  margin="normal"
                  id="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                 
                />
              </Grid>
            </Grid>
          </div>

          <FormSubmitButton
            type="submit"
            variant="contained"
            // className={classes.btnSubmit}
          >
            Submit
          </FormSubmitButton>
        </Box> */}
      </PopUpModel>

      {errMessage && <ErrorNotifier message={errMessage} />}
      {message && <Notifier message={message} />}
    </Layout>
  );
};

export default HrExecutive;
