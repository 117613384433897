import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  GET_ENGAGEMENT_DETAIL,GET_CLIENT_ENGAGEMENT_STAGE,GET_CURRENT_MENTOR_NAME,
  GET_Latest_CLIENT_HR, GET_ACTIVE_PAST_ENGAGEMENT 
} from "../../../utils/routes";

// ---------------------------------------------------------------------



export const getEngagementDetails = createAsyncThunk(
  "engagement/getEngagementdetail",
  async () => {
    return axios.get(`${GET_ENGAGEMENT_DETAIL}`, { withCredentials: true });
  }
);

export const getEngagementStage = createAsyncThunk(
  "engagement/getEngagementStage",
  async () => {
    return axios.get(GET_CLIENT_ENGAGEMENT_STAGE, { withCredentials: true });
  }
);

export const getLatestClientsInHr = createAsyncThunk(
  "dashboard/getLatestClientHr",
  async () => {
    return axios.get(GET_Latest_CLIENT_HR, { withCredentials: true });
  }
);

export const getActivePastEngagements = createAsyncThunk(
  "dashboard/getActivePastEngagement",
  async () => {
    return axios.get(GET_ACTIVE_PAST_ENGAGEMENT , { withCredentials: true });
  }
);




export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const  engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    engagementsData: [],
    engagementStageData:[],
    latestClientDataHr:[],
    activePastEngagementData:{},
    mentorCurrentDetails:{},
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
   
    [getEngagementDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementDetails.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.engagementsData = action?.payload?.data;
    },
    [getEngagementDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getActivePastEngagements.pending]: (state, action) => {
      state.loading = true;
    },
    [getActivePastEngagements.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.activePastEngagementData = action?.payload?.data;
    },
    [getActivePastEngagements.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getLatestClientsInHr.pending]: (state, action) => {
      state.loading = true;
    },
    [getLatestClientsInHr.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.latestClientDataHr = action?.payload?.data;
    },
    [getLatestClientsInHr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
  
    [getEngagementStage.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementStage.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.engagementStageData = action?.payload?.data;
    },
    [getEngagementStage.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage, rowSelect } = engagementSlice.actions;
export default  engagementSlice.reducer;


