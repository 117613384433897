import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {
  GET_ASSESMENT_IMPROVEMENT,
  GET_CLIENT_IMPROVEMENT,
  GET_ENGAGEMENT_DETAIL,
  APPROVE_CLIENT_IMPROVEMENT,
  APPROVE_ASSESSMENT_IMPROVEMENT,
  GET_CHR_COACHING_FOCUS,
  GET_ALL_CLIENTS_CHR,
  GET_ALL_CLIENTS_CHR_PURPOSE,
} from "../../../utils/routes";

// ---------------------------------------------------------------------

export const getClientFocus = createAsyncThunk(
  "clientFocus/getFocus",
  async () => {
    return axios.get(GET_ALL_CLIENTS_CHR, { withCredentials: true });
  }
);

export const getClientFocusPurpose = createAsyncThunk(
  "clientFocus/getFocusCCHRPurpose",
  async (data) => {
    return axios.get(`${GET_ALL_CLIENTS_CHR_PURPOSE}/${data} `, {
      withCredentials: true,
    });
  }
);

export const getClientForCCHR = createAsyncThunk(
  "hr/gerAllClients",
  async (data) => {
    return axios.get(`${GET_CHR_COACHING_FOCUS}/${data}`, {
      withCredentials: true,
    });
  }
);

export const updateClientFocusApprove = createAsyncThunk(
  "clientFocus/approvedClient",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(APPROVE_CLIENT_IMPROVEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateApproveImprovement = createAsyncThunk(
  "assessment/approveImprovent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(APPROVE_ASSESSMENT_IMPROVEMENT, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "engagement",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    clientFocusPurposeCHRData: [],
    clientCHRIdData: [],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
  },
  extraReducers: {
    [getClientFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientFocus.fulfilled]: (state, action) => {
      
      state.loading = false;
      state.clientFocusData = action?.payload?.data;
    },
    [getClientFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getClientFocusPurpose.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientFocusPurpose.fulfilled]: (state, action) => {
      
      state.loading = false;
      state.clientFocusPurposeCHRData = action?.payload?.data;
    },
    [getClientFocusPurpose.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [getClientForCCHR.pending]: (state, action) => {
      state.loading = true;
    },
    [getClientForCCHR.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.clientCHRIdData = action?.payload?.data;
    },
    [getClientForCCHR.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },

    [updateClientFocusApprove.pending]: (state, action) => {
      state.loading = true;
    },
    [updateClientFocusApprove.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateClientFocusApprove.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [updateApproveImprovement.pending]: (state, action) => {
      state.loading = true;
    },
    [updateApproveImprovement.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateApproveImprovement.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const { resetMessage, resetErrMessage, rowSelect } =
  engagementSlice.actions;
export default engagementSlice.reducer;
