import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch } from "react-redux";

// routes
import {
  GET_REPORTS_AREA_FOCUS,
  GET_REPORTS_ENGAGEMENT_STATUS,
  GET_ENGAGEMENT_DETAIL,
  GET_ALL_CLIENTS_HR,
  HR_ENGAGEMENT_REPORTS,
  HR_LATEST_SUMMARY,
  HR_PREVIOUS_SUMMARY_DATA,
  HR_PREVIOUS_SUMMARY_DATA_BY_ID,
  MANAGER_PREVIOUS_SUMMARY_DATA,
  MANAGER_LATEST_SUMMARY,
  MANAGER_PREVIOUS_SUMMARY_DATA_BY_ID,
  MANAGER_GENERATE_SUMMARY,
} from "../../../utils/routes";

// ---------------------------------------------------------------------

export const getAreaOfFocus = createAsyncThunk(
  "reports/getAreaOfFocus",
  async () => {
    return axios.get(GET_REPORTS_AREA_FOCUS, { withCredentials: true });
  }
);

export const getAllClientForHr = createAsyncThunk(
  "client/getAllClient",
  async () => {
    return axios.get(GET_ALL_CLIENTS_HR, { withCredentials: true });
  }
);

export const getGenerateManagerSummary = createAsyncThunk(
  "reports/geGenerateManagerSummary",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(MANAGER_GENERATE_SUMMARY, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllLatestReportsSummary = createAsyncThunk(
  "hr/getSummaryRecords",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };

    const response = await axios.post(MANAGER_LATEST_SUMMARY, body, {
      withCredentials: true,
    });
    return response;
  }
);

export const getPreviousSummaryForManager = createAsyncThunk(
  "summary/previousForHr",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };
    try {
      const response = await axios.post(MANAGER_PREVIOUS_SUMMARY_DATA, body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitPreviousSummaryForManager = createAsyncThunk(
  "summary/submitPreviousSummary",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        MANAGER_PREVIOUS_SUMMARY_DATA_BY_ID,
        data,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// export const getFullEnagegementsReports = createAsyncThunk(
//   "reports/getFullReportsHr",
//   async () => {
//     return axios.get(HR_ENGAGEMENT_REPORTS, { withCredentials: true });
//   }
// );

export const getFullEnagegementsReports = createAsyncThunk(
  "reports/getFullReportsHr",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId: data,
    };
    try {
      const response = await axios.post(HR_ENGAGEMENT_REPORTS, body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEngagementNotes = createAsyncThunk(
  "reports/getEngagement",
  async () => {
    return axios.get(GET_REPORTS_ENGAGEMENT_STATUS, { withCredentials: true });
  }
);

export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const engagementSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    areaFocusData: [],
    engagementFocusData: [],
    getAllClientData: [],
    previousSummaryDataForHr: [],
    generateManagerSummaryData: {},
    selectedData: {},
    dateErrorMessageData: "",
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    emptyObject: (state, action) => {
      
      state.generateManagerSummaryData = {};
    },
    dateErrorMessage: (state, action) => {
    
      state.dateErrorMessageData = action.payload;
    },
  },
  extraReducers: {
    [getGenerateManagerSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [getGenerateManagerSummary.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.generateManagerSummaryData = action?.payload?.data;
    },
    [getGenerateManagerSummary.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.generateManagerSummaryData = {};
    },

    [getAreaOfFocus.pending]: (state, action) => {
      state.loading = true;
    },
    [getAreaOfFocus.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.areaFocusData = action?.payload?.data;
    },
    [getAreaOfFocus.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [submitPreviousSummaryForManager.pending]: (state, action) => {
      state.loading = true;
    },
    [submitPreviousSummaryForManager.fulfilled]: (state, action) => {
    
      state.loading = false;
      state.generateManagerSummaryData = action?.payload?.data;
    },
    [submitPreviousSummaryForManager.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getPreviousSummaryForManager.pending]: (state, action) => {
      state.loading = true;
    },
    [getPreviousSummaryForManager.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.previousSummaryDataForHr = action?.payload?.data;
    },
    [getPreviousSummaryForManager.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getAllLatestReportsSummary.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllLatestReportsSummary.fulfilled]: (state, action) => {
      state.loading = false;

      state.generateManagerSummaryData = action?.payload?.data;
    },
    [getAllLatestReportsSummary.rejected]: (state, action) => {
      state.generateManagerSummaryData = [];
     
      state.loading = false;

      state.errMessage = action?.payload?.message;
    },
    [getFullEnagegementsReports.pending]: (state, action) => {
      state.loading = true;
    },
    [getFullEnagegementsReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.generateManagerSummaryData = action?.payload?.data;
    },
    [getFullEnagegementsReports.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
      state.generateManagerSummaryData = [];
    },
    [getAllClientForHr.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllClientForHr.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.getAllClientData = action?.payload?.data;
    },
    [getAllClientForHr.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [getEngagementNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementNotes.fulfilled]: (state, action) => {
    
      state.loading = false;
      state.engagementFocusData = action?.payload?.data;
    },
    [getEngagementNotes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});

export const {
  resetMessage,
  resetErrMessage,
  rowSelect,
  dateErrorMessage,
  emptyObject,
} = engagementSlice.actions;
export default engagementSlice.reducer;
