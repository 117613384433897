import React, { useState, useEffect } from "react";
// 
import PrivateFeedback from "../../private_feedback/PrivateFeedback"
import { getAllFeedbackByManager, createManagerPrivateFeedback,updateManagerPrivateFeedback,deleteManagerPrivateFeedback,rowSelect } from "../../../features/privateFeedbackSlice";
const ManagerFeedback = () => {

    return (
        <>
            <PrivateFeedback
                getAllFeedback={getAllFeedbackByManager}
                createFeedback={createManagerPrivateFeedback}
                updateFeedback={updateManagerPrivateFeedback}
                deleteFeedback={deleteManagerPrivateFeedback}
                rowSelect={rowSelect}
            />
        </>
    );
};

export default ManagerFeedback;
