
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

import ListItemIcon from "@mui/material/ListItemIcon";
import { IconButton } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
 
 const EyeInfo = (props) =>{
    const useStylesTwo = makeStyles(() => ({
        customTooltip: {
          backgroundColor: "white",
          color: "black",
          fontSize:"0.7rem"
        },
        customArrow: {
         
        }
      }));
      const classesTwo = useStylesTwo();
      
    return(
        < Tooltip
           
        classes={{
         tooltip: classesTwo.customTooltip,
         arrow: classesTwo.customArrow
       }}
       
        title={props.eyeText}>
           <IconButton sx={{marginBottom: !props.isShow && "1rem"}}>
             <InfoIcon
               // onClick={isOpenLeadershipView}
               sx={{ cursor: "pointer" }}
               fontSize="large"
               color="primary"
             />
           </IconButton>
         </ Tooltip>
    )
 }


 export default EyeInfo
 
