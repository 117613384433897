import { ThemeProvider } from "@mui/material";
import { theme } from "../theme/Theme";
export { default as MyProfile } from "./my_profile/MyProfile";

export { default as Assesment } from "./Assesment/Assesment";
export { default as Coaching } from "./coaching/coachingFocus";

import SideBar from "./sidebar/Sidebar";
import Dashboard from "./dashboard/Dashboard";
// import Assesment from "./Assesment/Assesment"
import Coaching from "./coaching/coachingFocus";
import ClientRating from "./Ratings/ratings";
import Reports from "./Reports/reports";

import HrDashBoard from "./hr/dashbord/Dashboard";
import HrEngagementDetails from "./hr/dashbord/keyEngagementDetails"
import Engagements from "./hr/hrengagements/engagements";
import HRExecutive from "./hr/Executives/hrExecutives";
import HrAssesment from "./hr/Assesment/assesment";
import HrRating from "./hr/Ratings/ratings";
import HrRepo from "./hr/Reports/reports";
import HrCochingFocus from "./hr/coaching/coachingFocus";

import ChrDashboard from "./chr/dashbord/Dashboard";
import ChrEngagementDetails from "./chr/dashbord/keyEngagementDetails"
import ChrLeadership from "./chr/Assesment/assesment";
import ChrFocusArea from "./chr/coaching/coachingFocus";
import ChrReports from "./chr/Reports/reports";
import ChrNomination from "./chr/Executives/hrExecutives"

import ManagerDash from "./Manager/dashbord/managerDashboard";
import ManagerEngagementDetails from "./Manager/dashbord/keyEngagementDetails"
import ManagerAsses from "./Manager/Assesment/Assesment";
import ManagerCoaching from "./Manager/coaching/coachingFocus";
import ManagerRatin from "./Manager/Ratings/ratings";
import ManagerRepo from "./Manager/Reports/reports";

import PrivateHrfeedback from "./hr/feedback/Feedback";
import PrivateManagerfeedback from "./Manager/feedback/Feedback";
import PrivateChrfeedback from "./chr/feedback/Feedback";

export function PrivatefeedbackHr() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <PrivateHrfeedback />
      </ThemeProvider>
    </div>
  );
}
export function PrivatefeedbackManager() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <PrivateManagerfeedback/>
      </ThemeProvider>
    </div>
  );
}

export function PrivatefeedbackChr() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <PrivateChrfeedback />
      </ThemeProvider>
    </div>
  );
}



export function SideBarMenu() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <SideBar />
      </ThemeProvider>
    </div>
  );
}

export function DashboardExco() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <Dashboard />
      </ThemeProvider>
    </div>
  );
}


//chr



// export function AssesmentExco() {
//     return (
//         <div style={{width:"100%"}}>
//          <ThemeProvider theme={theme}>
//           <Assesment  theme={theme}/>
//          </ThemeProvider>
//         </div>
//      );
//   }

export function CoachingFocus() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <Coaching />
      </ThemeProvider>
    </div>
  );
}

export function Rating() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ClientRating />
      </ThemeProvider>
    </div>
  );
}

export function ClientReports() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <Reports />
      </ThemeProvider>
    </div>
  );
}

//hr components

export function HrDash() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <HrDashBoard />
      </ThemeProvider>
    </div>
  );
}

export function HrEngagements() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <HrEngagementDetails />
      </ThemeProvider>
    </div>
  );
}

export function HrExec() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <HRExecutive />
      </ThemeProvider>
    </div>
  );
}

export function HrAsses() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <HrAssesment />
      </ThemeProvider>
    </div>
  );
}

export function HrRatin() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <HrRating />
      </ThemeProvider>
    </div>
  );
}

export function HrReports() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <HrRepo />
      </ThemeProvider>
    </div>
  );
}

export function HrCoaching() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <HrCochingFocus />
      </ThemeProvider>
    </div>
  );
}

//chr components

export function AdminhrDashboard() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ChrDashboard />
      </ThemeProvider>
    </div>
  );
}

export function AdminhrEngagementDetails() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ChrEngagementDetails />
      </ThemeProvider>
    </div>
  );
}


export function AdminhrLeadership() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ChrLeadership />
      </ThemeProvider>
    </div>
  );
}

export function AdminhrFocusArea() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ChrFocusArea />
      </ThemeProvider>
    </div>
  );
}

export function AdminhrNomination() {
   return (
     <div style={{ width: "100%" }}>
       <ThemeProvider theme={theme}>
         <ChrNomination/>
       </ThemeProvider>
     </div>
   );
 }

export function AdminhrReports() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ChrReports />
      </ThemeProvider>
    </div>
  );
}

//manager components

export function ManagerDashboard() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ManagerDash />
      </ThemeProvider>
    </div>
  );
}

export function ManagerEnagagement() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ManagerEngagementDetails />
      </ThemeProvider>
    </div>
  );
}

export function ManagerAssesment() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ManagerAsses />
      </ThemeProvider>
    </div>
  );
}

export function ManagerCoachingStatus() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ManagerCoaching />
      </ThemeProvider>
    </div>
  );
}

export function ManagerRatings() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ManagerRatin />
      </ThemeProvider>
    </div>
  );
}

export function ManagerReports() {
  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <ManagerRepo />
      </ThemeProvider>
    </div>
  );
}
