import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// routes
import {  GET_REPORTS_AREA_FOCUS, GET_REPORTS_ENGAGEMENT_STATUS,GET_ENGAGEMENT_DETAIL,GET_RATING_BY_CLIENT, HR_RATING_BY_CLIENT,HR_LATEST_OUTCOMES
  ,HR_PREVIOUS_OUTCOMES_DATA,HR_PREVIOUS_OUTCOMES_DATA_BY_ID, 


  MANAGER_PREVIOUS_OUTCOMES_DATA,MANAGER_LATEST_OUTCOMES,MANAGER_PREVIOUS_OUTCOMES_DATA_BY_ID
} from "../../../utils/routes";

// ---------------------------------------------------------------------



// export const getRatingByclientId = createAsyncThunk(
//   "rating/getRatingForClientsHr",
//   async () => {
//     return axios.get(HR_RATING_BY_CLIENT, { withCredentials: true });
//   }
// );

export const  getRatingByclientId = createAsyncThunk(
  "rating/getRatingForClientsHr",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId:data
    }
    try {
      const response = await axios.post(HR_RATING_BY_CLIENT,body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const getEngagementNotes = createAsyncThunk(
  "reports/getEngagement",
  async () => {
    return axios.get( GET_REPORTS_ENGAGEMENT_STATUS, { withCredentials: true });
  }
);

// export const getLatestOutcomesForHr = createAsyncThunk(
//   "outcomes/getLatestOutcomes",
//   async (data) => {
//     let body = {
//       excoCustomerUserId:data
//     }
//     return axios.post(HR_LATEST_OUTCOMES,body, { withCredentials: true });
//   }
// );

export const getPreviousOutcomeForManager = createAsyncThunk(
  "outcomes/getPreviousHr",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId:data
    }
    try {
      const response = await axios.post( MANAGER_PREVIOUS_OUTCOMES_DATA,body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



export const getLatestOutcomesForManager = createAsyncThunk(
  "outcomesManager/getLatestOutcomesForManager",
  async (data, { rejectWithValue }) => {
    let body = {
      excoCustomerUserId:data
    }
    try {
      const response = await axios.post(MANAGER_LATEST_OUTCOMES,body, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const submitPreviousOutcomesForManager = createAsyncThunk(
  "outcomes/managerSumbitPrevious",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(MANAGER_PREVIOUS_OUTCOMES_DATA_BY_ID , data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);




export const updateEnagagementDetail = createAsyncThunk(
  "engagement/updateEngagement",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put( GET_ENGAGEMENT_DETAIL, data, {
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEngagementDetail = createAsyncThunk(
  "customer/deleteCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(GET_ENGAGEMENT_DETAIL, {
        data,
        withCredentials: true,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const  engagementSlice = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    message: "",
    errMessage: "",
    customErr:"",
    areaFocusData: [],
    engagementFocusData:[],
    previousOutcomeDataForManager:[],
    managerRatingData:[],
    selectedData: {},
  },
  reducers: {
    resetMessage: (state) => {
      state.message = "";
    },
    resetErrMessage: (state) => {
      state.errMessage = "";
    },
 
    rowSelect: (state, action) => {
      state.selectedData = action?.payload.length > 0 && action?.payload[0];
    },
    emptyingArray:(state,action) =>{
      state.managerRatingData = []
      },
  },
  extraReducers: {
   
    [getRatingByclientId.pending] : (state, action) => {
      state.loading = true;
    },
    [getRatingByclientId.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.managerRatingData = action?.payload?.data;
    },
    [getRatingByclientId.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    }, 

    [submitPreviousOutcomesForManager.pending] : (state, action) => {
      state.loading = true;
    },
    [submitPreviousOutcomesForManager.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.managerRatingData = action?.payload?.data;
    },
    [submitPreviousOutcomesForManager.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    }, 

    [getPreviousOutcomeForManager.pending] : (state, action) => {
      state.loading = true;
    },
    [ getPreviousOutcomeForManager.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.previousOutcomeDataForManager = action?.payload?.data;
    },
    [ getPreviousOutcomeForManager.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },


    [getLatestOutcomesForManager.pending] : (state, action) => {
      state.loading = true;
    },
    [getLatestOutcomesForManager.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.managerRatingData = action?.payload?.data;
    },
    [getLatestOutcomesForManager.rejected]: (state, action) => {
     console.log(action)
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [getEngagementNotes.pending]: (state, action) => {
      state.loading = true;
    },
    [getEngagementNotes.fulfilled]: (state, action) => {
      console.log(action?.payload?.data)
      state.loading = false;
      state.engagementFocusData = action?.payload?.data;
    },
    [getEngagementNotes.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEnagagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [updateEnagagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
    [deleteEngagementDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteEngagementDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.data?.message;
    },
    [deleteEngagementDetail.rejected]: (state, action) => {
      state.loading = false;
      state.errMessage = action?.payload?.message;
    },
  },
});




export const { resetMessage, resetErrMessage, rowSelect, emptyingArray } = engagementSlice.actions;
export default  engagementSlice.reducer;


